import React, {useCallback} from 'react';
import './Play.scss';
import {useStoreState} from "easy-peasy";
import Question from "./Question/Question";
import PlayFooter from "./Play-Footer/PlayFooter";
import Choose from "./Choose/Choose";
import Element from "./Element/Element";
import ChakraColor from "./ChakraColor/ChakraColor";
import Duality from "./Duality/Duality";
import Shadow from "./Shadow/Shadow";
import Positivity from "./Positivity/Positivity";
import Journal from "./Journal/Journal";
import Conclusion from "./Conclusion/Conclusion";
import ElementEnergy from "./Energies/ElementEnergy/ElementEnergy";
import ChakraColorEnergy from "./Energies/ChakraColor/ChakraColorEnergy";
import PositivityEnergy from "./Energies/PositiveEnergy/PositiveEnergy";
import ElementTimeLine from "./TimeLine/ElementTimeLine";
import ChakraColorTimeline from "./TimeLine/ChakraColorTimeline";
import DualityTimeline from "./TimeLine/DualityTimeline";

// import Shuffle from "../Animation/Shuffle/Shuffle";


const Play = (props) => {
	const {
		play: {
			modeSelected, questionAdded, elementProcessOver,
			chakraProcessOver, dualityProcessOver, shadowProcessOver,
			positivityProcessOver, conclusionOver, subModeSelected, subMode,
			mode, elementTimelineActive, chakraTimelineActive, dualityTimelineActive, chakraTimeActive
		}
	} = useStoreState(state => state);
	// const {play: {updateQuestion}} = useStoreActions((actions => actions));

	const Content = useCallback(() => {

		if (!modeSelected && !subModeSelected) {
			return <Choose/>
		} else if (mode !== 1) {
			if (modeSelected && !questionAdded) {
				return <Question/>
			} else if (questionAdded && !elementProcessOver) {
				return <Element/>
			} else if (elementProcessOver && !chakraProcessOver) {
				return <ChakraColor/>
			} else if (!dualityProcessOver && chakraProcessOver) {
				return <Duality/>
			} else if (dualityProcessOver && !shadowProcessOver) {
				return <Shadow/>
			} else if (shadowProcessOver && !positivityProcessOver) {
				return <Positivity/>
			} else if (positivityProcessOver && !conclusionOver) {
				return <Conclusion/>
			} else if (conclusionOver) {
				return <Journal/>
			}
		} else if (mode === 1) {
			if (subModeSelected && subMode === 1) {
				return <ElementEnergy/>
			} else if (subModeSelected && subMode === 2) {
				return <ChakraColorEnergy/>
			} else if (subModeSelected && subMode === 3) {
				return <PositivityEnergy/>
			}
		}


	}, [modeSelected, questionAdded, elementProcessOver, chakraProcessOver,
		dualityProcessOver, shadowProcessOver, positivityProcessOver, conclusionOver,
		subModeSelected, mode, elementTimelineActive, chakraTimelineActive, dualityTimelineActive])

	return (
		<div className="play">
			{/*/!*{props.children}*!/*/}

			<div className={`${conclusionOver ? "play__journal" : "w-100 h-100"}`}>
				{!elementTimelineActive && !chakraTimelineActive && !dualityTimelineActive ?
					<Content/> :
					elementTimelineActive ?  <ElementTimeLine/> :chakraTimelineActive ?  <ChakraColorTimeline/> :<DualityTimeline/>
				}
			</div>
			{(!conclusionOver && (modeSelected || subModeSelected)) && <PlayFooter/>}
		</div>
	)


}

export default React.memo(Play);
//
//
// import Question from "./Question/Question";
// import PlayFooter from "./Play-Footer/PlayFooter";
// import Choose from "./Choose/Choose";
// // import Shuffle from "../Animation/Shuffle/Shuffle";
//
//
// const Play = (props) => {
// 	const {play: {mode, modeSelected, question, questionAdded}} = useStoreState(state => state);
// 	const {play: {updateQuestion}} = useStoreActions((actions => actions));
//
//
// 	return (
// 		<div className="play">
// 			{props.children}
//
// 			{modeSelected && <PlayFooter percentage={0} label={"I have entered my question"}
// 										 disabled={!(question && question.length > 5)}/>}
// 			{/*<Content/>*/}
// 		</div>
// 		// !modeSelected ? <Choose/> : <Question/>
// 	)
//
//
// }
//
// export default React.memo(Play);
