import React, {useEffect, useState} from 'react';
import "./Shadow.scss";
import CardDeck from "../../UI/CardDeck/CardDeck";
import {animated, useSpring, useTransition} from "react-spring";
import {useStoreActions, useStoreState} from "easy-peasy";
import PlayHeader from "../PlayHeader/PlayHeader";
import Shuffle from "../../Animation/Shuffle/Shuffle";
import getShuffledArray from "../../../Helpers/RandomizeArray";
import DualityCard from "../../../Helpers/DualityCard";
import ShowShadowCard from "./ShowShadowCard/ShowShadowCard";

let OddCards = [...getShuffledArray(DualityCard)];
OddCards = OddCards.slice(0, 6);


const Shadow = () => {

	const [moreInfo, setMoreInfo] = useState(false);
	const {play: {updateHideButton, updateShadowCardSelected, updateHidePlayFooter}} = useStoreActions((actions => actions));
	const {play: {shadowExplore, shadowShuffleStart}} = useStoreState((state => state));

	const fadeInContainer = useTransition(false, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 1000}
	});


	const fadeIn2 = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: shadowExplore ? 1 : 0
		},
		config: {duration: 1000}
	});


	const onClickButton = () => {
		if (!moreInfo) {
			setMoreInfo(true);
			updateHideButton(false);
		} else {
			setMoreInfo(false);
			updateHideButton(true);
		}
	}
	const [shuffleComplete, setShuffleComplete] = useState(false);

	const [shuffleArray, setShuffleArray] = useState([]);
	const [shuffleOver, setShuffleOver] = useState(false);
	const [shuffleAnimation, setShuffleAnimation] = useState(false);

	useEffect(() => {
		if (shuffleComplete) {
			const timeout = setTimeout(() => {
				setShuffleOver(true)
			}, 1000)
			const timeout2 = setTimeout(() => {
				setShuffleAnimation(true)
				updateShadowCardSelected(true);
				updateHidePlayFooter(false);
			}, 2000)
			return () => {
				clearTimeout(timeout2);
				clearTimeout(timeout);

			}
		}
	}, [shuffleComplete])


	return (
		<div className="shadow__container">
			<PlayHeader/>
			{!shadowShuffleStart ? fadeInContainer.map(({item, props: animation, key}) =>
				!item &&
				<animated.div className="shadow" style={animation} key={key}>
					<div className="shadow__deck">
						<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5} disableAnimation={!moreInfo}/>
					</div>
					<animated.div className="shadow__details">
						{!shadowExplore ? <>
								<p className="main__title">Working with The Shadow Ball Card </p>
								<ShadowDetails moreInfo={moreInfo}/>
								<div className="w-20">
									<button className="btn btn-outline" onClick={onClickButton}>{moreInfo ? "BACK" : "NEXT"}</button>
								</div>
							</> :
							<animated.div className="shadow__explore" style={fadeIn2}>
								<p className="main__title">Shuffle the <strong>Shadow Ball Card</strong> along with your
									chosen <strong>Duality</strong> and
									<strong> Wholeness Cards </strong>. </p>
								<p className="shadow__description">Shuffle (The cards will automatically shuffle 21 times when you click the shuffle
									button)</p>
							</animated.div>
						}
					</animated.div>
				</animated.div>)
				: !shuffleAnimation ? <Shuffle items={OddCards} cardLimit={1} shuffleCount={2}
											   title={""}
											   subtitle={""}
											   alignment={"100px"}
											   skipSelect={true}
											   setShuffleComplete={setShuffleComplete}
											   pushCard={setShuffleArray}
											   animationRewind={shuffleOver}/>
					: <ShowShadowCard/>
			}
		</div>
	);
}

export default Shadow;


const ShadowDetails = ({moreInfo, ...props}) => {

	const fadeIn = useTransition(moreInfo, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});


	return (
		<div className="shadow__text-main">

			{
				fadeIn.map(({item, props: animation, key}) =>
					!item ?
						<animated.div className="shadow__text-container" key={key} style={animation}>
							<p className="shadow__description">
								<strong>The Shadow Ball Card represents the secret side of yourself </strong>, your
								untapped energy and your potential as well as those parts of you that you have pushed away from your
								consciousness. But, once the shadow is accepted, it exposes possible and hitherto unimagined outcomes
								available to you. It helps you find the answers to the queries you present by revealing what your
								psyche wishes to discard, and what you no longer want to be identified with
							</p>

							<p className="shadow__description">
								The <strong> Shadow Ball Card </strong> reveals what your psyche wants to keep and hold onto. But, if
								you do not make
								these secrets in you conscious, they will eventually reveal themselves—perhaps in destructive or
								uncomfortable ways. You cannot avoid your shadow. It will follow you and pounce on you when you least
								want it to. If you do not find it and face it, it will find you. .</p>
						</animated.div> :
						<animated.div className="shadow__text-container" key={key} style={animation}>
							<p className="shadow__description">
								Playing Divine Play, helps you bring your darkness into the light, so that you are no longer a victim
								held in shadow. Once in the light—the secret you that emerged, integrates with the public you and, as
								a result, it can make future life choices with renewed creativity and freedom

							</p>

							<p className="shadow__description">
								At the moment you play with the <strong> Shadow Ball Card </strong> you consciously link the past, present, and the
								future in one synchronistic moment, creating a significant relationship between events that previously
								had no discernible connection.
							</p>
							<p className="shadow__description">
								For example, if the secret side of you always longed to be more safe and trusting, the <strong>Shadow Ball
								Card</strong> will reveal this by emphasising (touching) a
								particular <strong>Duality</strong> or<strong> Wholeness</strong> card. Say, the
								<strong> Shadow Ball Card</strong> touches the Protection side of your card. This will help trigger a conscious
								understanding in you of the deeper meaning of this card or any other card or <strong>Wholeness Cards</strong> you have
								chosen. Perhaps you need to be more aware of the idea of Protection or what this really means for you
								in terms of trust?
							</p>

						</animated.div>
				)
			}
		</div>

	)

}
//

