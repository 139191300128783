import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {Route, Switch, useLocation} from "react-router-dom";
import {animated, useTransition} from "react-spring";

import './App.scss';
import Mobile from "./Components/Mobile/Mobile";

import Header from "./Components/Header/Header";
import Home from "./Components/Home/Home";

import About from "./Components/About/About";
import Reading from "./Components/Reading/Reading";
import Play from "./Components/Play/Play";
import Footer from "./Components/Footer/Footer";
import ShowChakra from "./Components/Play/ShowChakra/ShowChakra";
import Disclaimer from "./Components/Disclaimer/Disclaimer";


const App = () => {
	const [startAnimation, setStartAnimation] = useState(false);

	useEffect(() => {
		setStartAnimation(true);
		updateImagesLoaded(true)
		return () => {
		}
	}, []);

	const location = useLocation()
	const transitions = useTransition(location, location => location.pathname, {
		from: {opacity: 0},
		enter: {opacity: 1},
		leave: {opacity: 0},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500}
	});


	const {home: {updateImagesLoaded,}} = useStoreActions(actions => actions);
	const {home: {imagesLoaded}, play: {printPdf}} = useStoreState(state => state);
	const {play: {showChakraImage}} = useStoreState((state => state));

	useEffect(() => {
		console.log(printPdf);
	}, [printPdf])


	return (
		<>
			<Mobile/>
			<ShowChakra/>
			<div className="App">
				<div className="App__main">
					<Header/>
					{transitions.map(({item, props, key}) => (
						(imagesLoaded && startAnimation) &&

						<animated.div style={props} key={key} className="App-content">
							<Switch location={item}>
								<Route exact={true} path={'/'} component={Home}/>

								<Route exact={true} path={'/about'} component={About}/>

								<Route exact={true} path={'/reading'} component={Reading}/>
								<Route exact={true} path={'/play'} component={Play}/>
								<Route exact={true} path={'/disclaimer'} component={() => {
									return <Disclaimer selection={1}/>
								}}/>

								<Route exact={true} path={'/privacy'} component={() => {
									return <Disclaimer selection={2}/>
								}}/>
								<Route exact={true} path={'/copyrights'} component={() => {
									return <Disclaimer selection={3}/>
								}}/>

							</Switch>
						</animated.div>
					))
					}
					<Footer/>
				</div>
			</div>

		</>
	);
}

export default App;


{/*<Play>*/
}
{/*	<Route component={({match}) => {*/
}
{/*		return <div className="w-100 h-100">*/
}
{/*			<Switch>*/
}
{/*				<Route exact={true} path={`/play`} component={Choose}/>*/
}
{/*				<Route exact={true} path={'/play/question'} render={() => {*/
}
{/*					return <Question/>*/
}
{/*				}}/>*/
}
{/*				<Route exact={true} path={'/play/element'} render={() => {*/
}
{/*					return <p>Element Card</p>*/
}
{/*				}}/>*/
}
{/*			</Switch>*/
}
{/*		</div>*/
}
{/*	}*/
}
{/*	}/>*/
}
{/*</Play>*/
}

{/*<Content/>*/
}

{/*<Route exact={true} path={'/play?q=1'} render={() => {*/
}
{/*	return <div>*/
}
{/*		<p>dkddjdjjd</p>*/
}
{/*	</div>*/
}
{/*}}/>*/
}
