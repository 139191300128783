import ManAsHumanBeing from "../Assets/Duality/ManAsHumanBeing.png"
import WomanAsHumanBeing from "../Assets/Duality/WomanAsHumanBeing.png"
import MasuculinePsychicModeOfExpression from "../Assets/Duality/MasuclinePsychicModeOfExpression.png"
import FeminePsychicModeOfExpression from "../Assets/Duality/FeminePsychicModeOfExpression.png"
import TheAnimus from "../Assets/Duality/TheAnimus.png"
import TheAnima from "../Assets/Duality/TheAnima.png"
import TheMagician from "../Assets/Duality/TheMagician.png"
import TheHighPriestess from "../Assets/Duality/TheHighPriestess.png"
import TheFather from "../Assets/Duality/TheFather.png"
import TheMother from "../Assets/Duality/TheMother.png"
import TheMasculineSpiritualQuest from "../Assets/Duality/TheMasculineSpirtualQuest.png"
import TheFeminineSpiritualQuest from "../Assets/Duality/TheFeminineSpritualQuest.png"
import Wisdom from "../Assets/Duality/Wisdom.png"
import Ignorance from "../Assets/Duality/Ignorance.png"
import Generosity from "../Assets/Duality/Generosity.png"
import Selfishness from "../Assets/Duality/Selfishness.png";
import Courage from "../Assets/Duality/Courage.png"
import Fear from "../Assets/Duality/Fear.png"
import Freedom from "../Assets/Duality/Freedom.png"
import Imprisonment from "../Assets/Duality/Imprisonment.png"
import Love from "../Assets/Duality/Love.png"
import Hate from "../Assets/Duality/Hate.png"
import Protection from "../Assets/Duality/Protection.png";
import Invasion from "../Assets/Duality/Invasion.png";
import Birth from "../Assets/Duality/Birth.png";
import Death from "../Assets/Duality/Death.png";
import Clinging from "../Assets/Duality/Clinging.png";
import Surrendering from "../Assets/Duality/Surrendering.png";
import ThePath from "../Assets/Duality/ThePath.png";
import Gateway from "../Assets/Duality/TheGateway.png";
import Decay from "../Assets/Duality/Decay.png";
import Regeneration from "../Assets/Duality/Regeneration.png";
import Stasis from "../Assets/Duality/Stasis.png";
import Metamorphosis from "../Assets/Duality/Metamorphis.png";
import WellBeing from "../Assets/Duality/WellBeing.png";
import Disease from "../Assets/Duality/Disease.png";

import Om from "../Assets/Duality/Om.png";
import DivineChild from "../Assets/Duality/TheDivineChild.png";
import TheThreeOfLife from "../Assets/Duality/TheTreeOfLife.png";
import Urorobus from "../Assets/Duality/Urorobus.png";
import TheLotus from "../Assets/Duality/TheLotus.png";
import TheAlchemicalMarriage from "../Assets/Duality/TheAlchemicalMarriage.png";
import TheCosmicJoke from "../Assets/Duality/TheCosmicJoke.png";
import Enlightenment from "../Assets/Duality/Enlightment.png";
import TheCosmicDance from "../Assets/Duality/TheCosmicDance.png";
import TheVoid from "../Assets/Duality/TheVoid.png";


export default [
	{
		id: 1,
		name: "Man As a Human Being",
		subTitle: "The Masculine and Feminine Dualities",
		image: ManAsHumanBeing,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"The image is of a blue Mandala showing the cyclic ages of growth. The male seed is in the middle, with the images of baby, boy, adolescent, young man, mature man and an old man depicted around the edge.",
			"What is it like to be born a boy child and be in a given age—to have been a young boy and grow with the excitement, confusion and pains of development? What is it like to become a husband with the dawning realisation that it is you who holds the seeds?  What is it like to then find yourself a father, a responsible provider, through the event of a child being born, yet not of your form?  What is it like to be given the potential joy of virility and power, without constantly having to carry, to hold and to clear up the mess?  What is it like to be aware that you can walk away if you want to without even a backward glance? To find yourself at your peak, then watch your strength daily fall away? What is it like to recognise that one day you will be fragile, vulnerable and frail without the power to achieve anything except your death?",
			"I am the you in your being that makes your world so exciting and tremendous, so terrifying and potentially cruel as you live on the edge. You, who are pushed and pulled by your ambitions and drives and yet who has to come to terms with your life in its emotional disturbing fullness and madness? I am you who is the champion and the fighter and the one who knows also how to be gentle and to caress in that strength. I am this form of spirit that is full of the potential of all the promises of the masculine genes of your ancestors."
		],
		equaliser: [
			"How does your mother, father, family, society, religion, treat boys in comparison to girls?  Think on how this has coloured your emotions, mind and actions.",
			"Contemplate on how you are cyclically moving towards the ultimate loss of your bodily potency and strength.",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"Baby-girl, Daughter, Sister, Wench, Nubile, Coquette, Girlfriend, Woman, Wife, Mother, Housewife, Mistress, Concubine, Femme-Fatale, Suffragette, Career-Woman, Shrew, Matriarch, Grandmother, Crone, Old Hag",
			"<strong> How do the words you have chosen interrelate with you? How do they merge with you and balance you?After equalising who is the new being you are creating?</strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Man and Woman As Human Beings</strong>",
			"<em> I am the you who is invisible and yet, I hold the seeds of your form. You can see me so clearly in everything around you, every plant, animal and form.",
			"<em>I give you the gender clothing, the role, and I give you the script for you to so uniquely play out the needs of your form until it is time for you to exit the drama of this fundamental, physical play.</em>",
			"<em>But to be born a human being means more than existing merely in a man’s or a woman’s flesh. It is more than having your gender’s plan of action and potential life ahead. It is more than experiencing within the confining roles of a certain age and sex. And always there will be the attraction to what you cannot fully get. When you are engrossed in one part of me, I will invoke the other in a myriad of exciting, enchanting, ruthless and even terrifying ways— as you draw towards me, my hidden mate, your eternal life opposite.</em>",
			"<em>You will find me in all others, sometimes in the subtlest of ways, and you cannot deny too long the pulling need for my understanding and unity of form.</em>",
		]
	},
	{
		id: 2,
		name: "Woman As a Human Being",
		subTitle: "The Masculine and Feminine Dualities",
		image: WomanAsHumanBeing,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of a pale pink Mandala showing the cyclic ages of growth. The egg is in the centre, with the images of baby, girl, adolescent, young woman, mature woman and old woman depicted around the edge.</em>",
			"What does it mean to be born a girl child, rather than a boy—and for all the roles of your entire life to be coloured in this way? What does it mean to be born a daughter, a sister, a girl and watch your body increase its potential to conceive and give birth? What does it mean to be a spectator to this event, regardless of your most innermost wishes? What does it mean to be aware that in giving birth, one day you may have to watch your creation, your love, your child die and to fear the pain of it all?",
			"I am the you that swells into the ripeness of beauty and yet one day will wither and lose its bloom. I am the you that is the life-force of your womb, of the menopause and also hold the seeds of your old and wrinkled crone. I am the you that has the potential to be the mother, the beloved, the wife, the mistress or the maid. Watch me as I make your being conform, catching and confining your incredible force, within this physical feminine form."
		],
		equaliser: [
			"<em>Look at the Mandala and let one of the images draw you towards it. What is she saying to you? Discover also which one (s) repulse you. Find out why and make friends with her/them in whichever ways you can.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Baby-boy, Son, Boy, Youth, Gawky, Bully, Macho, Boyfriend, Softy, Man, Lover,  Man-about-Town, Free-Agent, Husband, Father, Provider, Manager, Redundant, Patriarch, Widower, Retired, Grandfather</em>",
			"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Man and Woman As Human Beings</strong>",
			"<em> I am the you who is invisible and yet, I hold the seeds of your form. You can see me so clearly in everything around you, every plant, animal and form.",
			"<em>I give you the gender clothing, the role, and I give you the script for you to so uniquely play out the needs of your form until it is time for you to exit the drama of this fundamental, physical play.</em>",
			"<em>But to be born a human being means more than existing merely in a man’s or a woman’s flesh. It is more than having your gender’s plan of action and potential life ahead. It is more than experiencing within the confining roles of a certain age and sex. And always there will be the attraction to what you cannot fully get. When you are engrossed in one part of me, I will invoke the other in a myriad of exciting, enchanting, ruthless and even terrifying ways— as you draw towards me, my hidden mate, your eternal life opposite.</em>",
			"<em>You will find me in all others, sometimes in the subtlest of ways, and you cannot deny too long the pulling need for my understanding and unity of form.</em>",
		]
	},
	{
		id: 3,
		name: "The Masculine Psychic Mode of Expression",
		subTitle: "The Masculine and Feminine Dualities",
		image: MasuculinePsychicModeOfExpression,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em> The image is of a man climbing a mountain. He carries a pickaxe to help him grip as he ascends. He is dressed in suitable clothing for his journey. The sun shines high in the sky. There is the sense that he knows how to complete his task in an adept and appropriate way. The mountain is indicative of “the climb”, and the climber of the aspiration to reach the summit. But, this is not a nebulous or transcendent attempt; it is the practical aspect of consciousness.</em>",
			"Sometimes I need to act, to achieve, to think, to plan, to bridge, to quest.  I need to know how to strive to the top, to achieve my hopes and dreams—to know where I am going, even if only to see how far there is to go.  I long to find the next step, whether it is firm or not and whether I can go higher to the summit of my ambitions and plans. I desire to stretch my capabilities, to reach edges, and to find, even in the midst of extremes and danger, my potential.",
			"Through testing times and adversity, and the sheer thrill of taking the risk, I am confronted with the need to have precise thoughts, to take appropriate measures and due care in every step I take. And as I continue my search for the clarity and light of the open sky of my mind, I fulfil my need for a vision and for the striving of the manifestation of my intentions."],
		equaliser: [
			"<em> How exhausting do you find it to be clear, concise and in control? </em>",
			"<em>What does it feel like to have something to aspire to, and how is this relevant in your life right now? </em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you. </strong>",
			"<em> Contemplation, Reflection, Resting, Inaction, Fluidity, Depth, Sitting, Being, Doing Nothing, The Void, Darkness, Going Within, Stillness, Meditation <em>",
			"<em><strong>  How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating? </strong> </em>"],

		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Masculine and the Feminine Psychic Modes of Expression </strong>",
			"<em>I am <strong>that</strong> in you which determines your expression—your responses and your inhibitions. I am your receptive seed and your ability to act, your need to determine and reflect myself. Sometimes I need to move with masculine power and sometimes in feminine rhythm, all the time, helping you to judge your pace, and when appropriate to let go and  <strong> just be. </strong></em>",
			"<em>In each mode, I teach you how to be. How to move in and out, of action and inaction, and how far and how slow, how deep and how high and how low you can go. With me, you test your capabilities and skills as well as your stillness. There is so much you can want to do and go ahead with, but sometimes </em>",
			"<em>you can also feel the call to withdraw and rest. There are so many times when you want to try, and yet you must also learn when to let go and reflect. I am your true ally and dearest friend in life.</em>"
		]
	},
	{
		id: 4,
		name: "The Feminine Psychic Mode of Expression",
		image: FeminePsychicModeOfExpression,
		subTitle: "The Masculine and Feminine Dualities",

		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"],
		description: [
			"<em> The image is of a young, dark-haired woman kneeling by a still lake, looking into the water. It is night. The moon is shining and reflecting onto the surface of the lake. The scene suggests contemplation, depth and reflection. The figure looks at the moon’s reflection in the water and wonders how real it is. Is it she or the moon that is the illusion?</em>",
			"Sometimes there is the need to stop, to rest awhile and to go within—to contemplate and have the time to wonder. Perhaps here you can find out what is real and what is not, what it is that is being reflected and what is it that is beyond the reflection. But, in the meantime, it is good to just relax and be and look at the imagery and the illusion.",
			"I am that within you which belongs to the darkness and the unknowing. Who knows the depths of my limits? And am I not calming, soothing, so peaceful in my deceptive stillness? I seduce you and draw you into a place where you need go—where there is nowhere other than these watery depths for an eternal moment in time.  Let go of the struggling, the hectic movement, the achieving, the need for that exhausting focus and climb.",
			"Now, for a little while, be with the soft darkness and its reflections and curves, the dusky twilight and its hint of glimmering stars. Here there is an entire world on which you can lean and where you can rest within for a while.",
		],
		equaliser: [
			"<em> Are you longing to stay at the water’s edge, to just be, and rest for a while? How can you  do this and what is it that you need to let go of to do it? </em>",
			"<em>Practise moving in and out of it, of this energy.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you. </strong>",
			"<em>Vision, Clarity, Conscious, Focus, Strategy, Incisive, Accurate, Sharp, Cut, Intention, Challenge, The-Need-to-Know, Achieve, Aspire, Climb, Manifest, Determination, Plan, The Sky-is-the-Limit, The Quest </em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating? </em> </strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Masculine and the Feminine Psychic Modes of Expression </strong>",
			"<em>I am <strong>that</strong> in you which determines your expression—your responses and your inhibitions. I am your receptive seed and your ability to act, your need to determine and reflect myself. Sometimes I need to move with masculine power and sometimes in feminine rhythm, all the time, helping you to judge your pace, and when appropriate to let go and  <strong> just be. </strong></em>",
			"<em>In each mode, I teach you how to be. How to move in and out, of action and inaction, and how far and how slow, how deep and how high and how low you can go. With me, you test your capabilities and skills as well as your stillness. There is so much you can want to do and go ahead with, but sometimes </em>",
			"<em>you can also feel the call to withdraw and rest. There are so many times when you want to try, and yet you must also learn when to let go and reflect. I am your true ally and dearest friend in life.</em>"
		]
	},
	{
		id: 5,
		name: "The Animus",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheAnimus,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"],
		description: [
			"<em>The image is split. One half is a handsome, chivalrous knight. The other half is an abuser. He is all that is gentle and courageous, protective, charming and fair, but he also contains within him the potential of the exploiter, the abuser and the controller. A woman’s relationship with her animus, her inner masculine self, will determine which qualities she will meet in her outer relationships with masculine energies.</em>",
			"How do you champion yourself and yours and look after what is of value in your life? Do you care with appropriate protection and tenderness, with honour, chivalry and decency—for the essence of you, that which glorifies your existence, which inspires you and helps you combat the ordeals and trials of life? Can you attend to, love and animate your very existence?  Can you colour and imbue it with life? Just how much self-respect do you have?",
			"Perhaps it is easier to be with the abuser, the one you have always secretly known, the one who potentially lets you down, who judges you, or who treats you with disrespect—who ravages what you call your own? Look at how you are defended or whether you are left hopeless and in tears. Consider that it is up to you who your partner is and will be. Ask yourself how much you can expect your partner to love and champion you and whether or not you even think that you are worth championing at all."],
		equaliser: [
			"<em>​If you are a woman, imagine your inner “he”—the masculine—depicted as the knight, standing beside you and offering to champion you. Imagine him as clearly as you can. It may help to draw him or paint him. Carry him with you, perhaps in your bag, until you know that his protection and care is there and can be relied upon. When you need to, request his help.</em>",
			"<em>If you are a man, consider how the knight is in you and honour this. Find the potential negative abuser in you and ask the positive knight to heal him. Meditate on this each day until you feel that you are ready to move on…</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>>",
			"<em>Madonna, Maiden, Virgin, Princess, Ingenue, Beauty, Soul-Connection, Bride, Tigress, Cat, Psyche, Siren, Nag, Temptress, Concubine, Goddess, Queen, Grace, Tender-Healer</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Animus and the Anima </strong>",
			"<em>I yearn to relate to my soul and spirit, to sense and connect with my opposite—to enter into the world of relationships—an other , to help me conjoin, to make sense of life and its sacredness. Oh, I yearn for my completeness.</em>",
			"<em>It is this seeking which is my inspiration, that which animates me and helps me to imagine and become more whole, and enables me to contact the sublime.</em>",
			"<em>Who are You, oh hidden Being, Who I deeply move towards and wish to merge with and dissolve in?</em>",
			"<em>Who takes me beyond the knowledge of myself, yet, Who is the reflection of my love—Who I yearn to call my beloved—are You the twin of my Self? The True Self is something I can only imagine, yet, its constant elusiveness possesses me; but when I see You, my love, the You in he and she, what a magnetic explosion. And I feel that I find a glimmer of the Truth.</em>",
			"<em>Before the disillusionment sets in and I find that You are not what I thought. But, I need to have You and to know Your Being…so, that I can begin to know what “Being” is. In this, I surrender to Your and My desire.</em>"]
	},
	{
		id: 6,
		name: "The Anima",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheAnima,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"],
		description: [
			"<em>The image is of a beautiful princess, fair, chaste and pure. The hem of her long dress reveals the hint of a mermaid’s tail. Perhaps she is not all that she seems…</em>",
			"<em>She is all that is fair, loving and tender. She is the healer of man’s soul. By loving and cherishing She, he can find within himself the qualities of tenderness and beauty, and be comfortable with his own Beast. Without this connection to She, life has no meaning for him. He cannot trust—he does not have the ability to hold that which is worth cherishing and fighting for.</em>",
			"He needs to trust that She will not lie to him. He needs to know that She will remain with him and heal him. He needs to believe that She will soothe him and not let loose the potential psychic power of her mermaid’s tail—turning into the siren that will ensnare him in fathomless depths, where he will drown in waves of overwhelming despair, moods and jealousies and ultimately lose his sanity.",
			"He needs to trust in Her, in his inner world. He needs to know that he can realise and win his image of the Feminine Divine within and without form"],
		equaliser: [
			"<em>If you are a man, can you identify the princesses and the mermaids in your life? Imagine these feminine aspects in front of you and say what you would like to say to them. Give them love from your heart and pray for their healing, internally and externally and in relation to you. Now, imagine your Ideal Feminine Being and think of Her whenever you feel in need of Her healing power.</em>",
			"<em>What can you do to help yourself so that you can enter emotional depths without drowning?</em>",
			"<em>If you are a woman, identify the mermaid and the tender healer in you. Get to know them both as much as you can. Make them conscious in you.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you. </strong>",
			"<em>Knight in Shining Armour, Protector, Galahad, Champion, Prince, Saviour, Loyal Friend, Good Husband, Mate, Groom, Don Juan, Violator, Abuser, Cheater, Seducer, Liar</em>",
			"<strong> <em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Animus and the Anima </strong>",
			"<em>I yearn to relate to my soul and spirit, to sense and connect with my opposite—to enter into the world of relationships—an other , to help me conjoin, to make sense of life and its sacredness. Oh, I yearn for my completeness.</em>",
			"<em>It is this seeking which is my inspiration, that which animates me and helps me to imagine and become more whole, and enables me to contact the sublime.</em>",
			"<em>Who are You, oh hidden Being, Who I deeply move towards and wish to merge with and dissolve in?</em>",
			"<em>Who takes me beyond the knowledge of myself, yet, Who is the reflection of my love—Who I yearn to call my beloved—are You the twin of my Self? The True Self is something I can only imagine, yet, its constant elusiveness possesses me; but when I see You, my love, the You in he and she, what a magnetic explosion. And I feel that I find a glimmer of the Truth.</em>",
			"<em>Before the disillusionment sets in and I find that You are not what I thought. But, I need to have You and to know Your Being…so, that I can begin to know what “Being” is. In this, I surrender to Your and My desire.</em>"]

	},
	{
		id: 7,
		name: "The Magician",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheMagician,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of a powerful-looking man carrying a crystal and staff. The man looks as though he could be anything: a traveller, perhaps, or a merchant—and yet there is an aura which suggests that he holds great powers</em>",
			"He is the controller of energies and the harnesser of power who can manipulate the elements and the forces of the mind.  He is that in you, who can travel within far-reaching realms and come back to tell his tales with wisdom and understanding. He is your inner magician, shaman or priest, your magical transformer and guide. He is your wise instructor, initiator, illuminator and respecter of boundaries—who knows and honours the sacred sites where he can and cannot pass.",
			"He is your potential charismatic presence and power, the recogniser of your throne, your ability to initiate great and powerful changes and transitions in your life. He knows right action and timing, and where to find the secret turnings. He can stand on his own.",
			"Why do you fear him so, this being who knows no real earthly limitations? Why do you fear your rightful claiming of the fullness of your energy, strength and power?"],
		equaliser: [
			"<em>Identify the teachers in your life. Do you question or test them? What qualities do they teach you and how can you now provide some of these things for yourself?</em>",
			"<em>Do you see the world as happening to you, or do you see yourself as an initiator in life? Think of ways to create more magic in your life.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Spellbinder, Lamia, Diviner, Hocus Pocus, Wicked Witch, Herbalist, Fairy Godmother, Oracle, Channeller, Familiar, Entrancer, Old Crone, Medium, Healer, Psychic, Shamaness, Sorceress, Priestess, Light Worker, Mystic</em>",
			"<em><strong> How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating? </strong></em>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> The Magician and the High Priestess </strong>",
			"<em>Imagine that you hold complete power, and that the world breathes and responds to your every thought, word and deed. You have the power to create and destroy, to punish and to forgive.</em>",
			"<em>Imagine You are the adept of magical and hidden secrets, with the advantage of being safe and protected from any hazardous event. Honour how it is like to be this divine. Perhaps you feel frightened already, and deny that this could be fun. You may say that power is bad, and that it is the last thing you could ever want to own. So, is it that you prefer powerlessness, because you think that it will give you goodness, humility and grace— do you think that having no power is, in fact, spiritually safe? You may think that everyone with power is a threat, but to what—your own power? People say that some powers are good and some are bad, but then, which ones do they say we should choose, and which should we discard?</em>",
			"<em>Can we really say that the feminine is a more dangerous power than its masculine mate? Or that the sorcerer’s power is mightier than its counterpart of the witch? Are not both naturally equal, but coloured by our motivations and hopes?</em>",
			"<em>If you are afraid of your own power, remember, that whatever you do not wish to own, whatever you fear and run away from, will haunt you and follow you until you claim it as your own.</em>",]
	},
	{
		id: 8,
		name: "The High Priestess",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheHighPriestess,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"The image is of a partially hidden woman in dark clothes holding a crystal in her hands. She looks ageless and timeless. Her covering and apparent quietness depict how she often works secretly and behind the accepted scenes.",
			"She is your seer, channeler, mystic, healer, diviner, oracle and witch. She knows how to connect with, harmonise and use the power of the natural world. She is admired, but frequently feared, envied and tried. She is often denied respectability, held under suspicion and martyred as a scapegoat for others' shadowy projections of fear of power.",
			"But when you connect with her in you, she will teach you how to wisely and silently use your own powers. There is a time to speak and reveal what you have seen and know, and a time to act as if ignorant or blinded. She teaches you how to be separate from the crowd, from the accepted and safe group, how to be comfortable within deep and mysterious worlds with no witnesser but your own truth.",
			"But whether she is respected or reviled, admired or shamed, she is the doorway that leads you into your own intuitive wisdom, insight and magical power."
		],
		equaliser: [
			"When did you last intuit knowledge or wisdom and keep it to yourself? How difficult is this for you to do—and why?",
			"What frightens and excites you about psychic powers?",
			"Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.",
			"Priest, Yogi, Therapist, Charismatic, Shaman, Controller, Teacher, Transformer, Guide, Wizard, Initiator, Merlin, Sorcerer, Powerhouse, Witch Doctor, Conjuror, Magus",
			"How do the words you have chosen interrelate with you? How do they merge with you and balance you?After equalising who is the new being you are creating?"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> The Magician and the High Priestess </strong>",
			"<em>Imagine that you hold complete power, and that the world breathes and responds to your every thought, word and deed. You have the power to create and destroy, to punish and to forgive.</em>",
			"<em>Imagine You are the adept of magical and hidden secrets, with the advantage of being safe and protected from any hazardous event. Honour how it is like to be this divine. Perhaps you feel frightened already, and deny that this could be fun. You may say that power is bad, and that it is the last thing you could ever want to own. So, is it that you prefer powerlessness, because you think that it will give you goodness, humility and grace— do you think that having no power is, in fact, spiritually safe? You may think that everyone with power is a threat, but to what—your own power? People say that some powers are good and some are bad, but then, which ones do they say we should choose, and which should we discard?</em>",
			"<em>Can we really say that the feminine is a more dangerous power than its masculine mate? Or that the sorcerer’s power is mightier than its counterpart of the witch? Are not both naturally equal, but coloured by our motivations and hopes?</em>",
			"<em>If you are afraid of your own power, remember, that whatever you do not wish to own, whatever you fear and run away from, will haunt you and follow you until you claim it as your own.</em>",]
	},
	{
		id: 9,
		name: "The Father",
		subTitle: "The Masculine and Feminine Dualities",
		image: TheFather,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of the sky with the face of a compassionate, wise and benevolent Being, looking out from it at you. He is the Universal and Archetypal Father.</em>",
			"Imagine yourself in a meadow, under a clear blue sky holding hands with this father. Imagine that he is in you. Know that he is always there and will always be present for you. Let yourself relax, trust and be at peace with this eternal father and protector.",
			"Imagine what he looks like, this being who is like the sky—clear, steadfast, honest and wise—who can protect you with his all-seeing sky eyes. Let your mind expand and visualise this father within you as your own inner and constant guardian. Connect with this inner being who will never betray you, will always protect you and wait patiently for you.",
			"Claim this he in you and let him free you and also free your biological father who gave his seed to you."],
		equaliser: [
			"Think about your biological father. Regardless of how you feel about him, speak to him now about what you would have liked from him, but which you feel you did not receive. As you do this, imagine him sitting opposite you as the very human and ordinary man he is/was.",
			"Think of ways and times you have experienced being fathered and have been a father to others. Celebrate and honour these times in a way you feel is appropriate.",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and be a mediator of healing for you.</strong>",
			"Divine Mother, Durga, Devi, Mother, Cherisher, Defender, Matriarch, Nourisher, Abandoner, Cradler, Nourisher, Smotherer, Surrogate Mother, Mother Earth",
			"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating? </strong>",
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Father and the Mother</strong>",
			"<em>I am the father and the mother in you and in your world. Look to me for your creating and holding.</em>",
			"<em>See me mirrored in your earthly parents, But know that here I cannot be perfect. For in flesh and blood I am fallible and vulnerable, and also a product of the generations that came before you.</em>",
			"<em>But see in them and in you the seeds of my perfection and wholeness. Rest in my arms, lean on my strength, and let yourself be fathered and mothered by the invisible Divine Perfection.</em>"]
	},
	{
		id: 10,
		name: "The Mother",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheMother,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of a rounded Earth Woman holding a small being in her arms. She is timeless and ageless.</em>",
			"<em>She looks as though she can love and hold, cherish and protect her own. Her Earth quality is the warmth, sustenance and nourishment present in the physical emanation of the Great Mother.</em>",
			"How many times did you long to be held, but felt hurt by your mother’s response? How many times have you felt as though the world has abandoned you? How many times have you cried for a mother’s love? How many times have you experienced hunger and felt starved? How many times have you longed to be fed, to be suckled, and yet no breast was there? How many times did you seem to demand the impossible from your mother?",
			"But deep within your heart, you were born with the knowledge of what the Mother truly is. You have within you these very seeds. She is present in you as your eternal nourisher and sustainer and knows all about your deepest needs.  There is no need to wail or to cry, just turn your body, mind and soul to her, rest against her and receive her acceptance and love. It is she in you who cherishes and soothes you and strokes away your tears. It is she in you who will fiercely protect and defend you, her true offspring. It is she in you who will mother you until the end."],
		equaliser: [
			"<em>Imagine being held by soft, warm arms—not too tightly, not too loosely, just right. Feel yourself cuddle in and take a good look at this ever-present mother’s eyes looking upon you with unconditional love. Drink of this nourishment and let it permeate every cell in your body. Do this whenever you feel the need.</em>",
			"<em>Consider how you treat yourself cruelly and without care. Look at your addictions. Identify the ways you are abandoning yourself and make a commitment to be more loving to yourself.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and be a mediator of healing for you.</strong>",
			"<em>God the Father, Dad, Father, Pop, Strength Giver, Guide, Benefactor, Provider, Disciplinarian, Firm Hand, Name Holder, Wise Man, Proud Father, Rule Maker</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Father and the Mother</strong>",
			"<em>I am the father and the mother in you and in your world. Look to me for your creating and holding.</em>",
			"<em>See me mirrored in your earthly parents, But know that here I cannot be perfect. For in flesh and blood I am fallible and vulnerable, and also a product of the generations that came before you.</em>",
			"<em>But see in them and in you the seeds of my perfection and wholeness. Rest in my arms, lean on my strength, and let yourself be fathered and mothered by the invisible Divine Perfection.</em>"]
	},
	{
		id: 11,
		name: "The Masculine Spiritual Quest",
		subTitle: "The Masculine and Feminine Dualities",

		image: TheMasculineSpiritualQuest,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of a mountain, beautifully formed and outlined against a clear sky. There is the impression of clarity and sharpness and of ascent.</em>",
			"The deep longing for “That” which contains all, is what moves you to reach ever upwards towards the Light. And so you take your longing and face your greatest fears, and climb. Your striving always contains the potential to fall, and yet you cannot stop. Here you face the capabilities and the limitations of who you are. You can go no further, reach no higher than your strength allows.",
			"How, whether, or why you fall, or can now continue the climb has little to do with luck. Your efforts and your achievement are a clear reflection of who you spiritually are and have grown to be. But know too that the mountain will endure and wait for you. Realise that it can never be too late, that it is impossible for you to miss out. And, that you will continue to move on your journey, your spiritual quest, cutting through the obscurities and illusions and ultimately ascending the limitations of your mind to find the true nature of your being."],

		equaliser: [
			"<em>Contemplate on the image—what does it say to you about transcending?</em>",
			"<em>Identify your quest—and make a commitment to honour this.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with to you and be a mediator of healing for you.</strong>",
			"<em>Void, Darkness, Sacred Dance, Sacred Spiral, Ritual, Grace, Abyss, Dream Weaving, Sacred Object, Sacrifice, Offering, Puja, Prostration, Merging, Supplication, Mystical, Prasad, Divine Nourishment, Kundalini, Cave, Sanctuary, Shrine, Altar</em>",
			"<em> <strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you?After equalising who is the new being you are creating?</strong></em>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> The Masculine and the Feminine Spiritual Quest </strong>",
			"<em>I am that within you which kindles the fire in your heart; those feelings which consume you and cannot be satiated by anything physical, by any man, woman or thing. I am the bringer of the longing and the aching that can never go away, which, in a world of death and impermanence will not die. In this world where nothing can be clung to, I am the heralder of That to which you can safely hold on to.</em>",
			"<em>Through the prompting of I in you, you can journey beyond time and enter the space within, and extend your mind beyond the idea of this, your life.</em>",
			"<em>Is It that which some call God, the Absolute or the Divine? What is this Being which is ever-present, ever around you, intrinsically connected to your every sense of you; which, you love and hate, welcome and abandon, long for and yet, wish to annihilate—that which you know you can love with more ardour than life itself?</em>",
			"<em>You are on a quest you can never forsake, for It will eat away at your heart. And even though you have forgotten It so many times, It can never leave you. For, Its memory remains deep within you until you unite with Its spiritual fire.</em>"],
	},
	{
		id: 12,
		name: "The Feminine Spiritual Quest",
		subTitle: "The Masculine and Feminine Dualities",
		image: TheFeminineSpiritualQuest,
		introduction: [
			"Who are these psychic play-mates affecting your body and soul so potently, masculine, feminine— yet, they are not always to do with you being a man or a woman?",
			"What are these essences moving through you dancing, weaving, arising, disintegrating, and which you can feel sometimes so painfully, sometimes so subtly, sometimes so blissfully, and sometimes not at all?",
			"And yet, they move through with such force that you have to ask: Who am I, beyond this psychic dualistic mask?"
		],
		description: [
			"<em>The image is of a cave. It is dusk and the cave holds a moist warmth. There is a ritual table near the cave’s entrance with some sacred objects placed upon it. The quality is of going within, of contemplation and of meditation, a place of retreat, of darkness and warmth, a place for the enactment of sacred dances and rituals.</em>",
			"Here rests the quiet, hushed darkness of your being; that place that is deeper than the deepest cavern, The Void—that nothingness which contains everything.",
			"This she in you is comfortable with the night and with the shadowy figures flickering in the mind. She knows how to find the Divine in the Earth and in the matter of all form. She knows how to hold and feel a stone and to connect with the spirit within, to touch a piece of wood and feel its fire, to contact the earth and feel its rhythmic beating pulse within her. She knows that the spirit and soul of each thing is manifest in everything, and in her most sublime moments she is at one with all. She is the stone, the skylark, the wind and the trees—she is the rustling of the leaves.",
			"Her rituals follow the seasons and her dances follow the cycles of life and growth, death and rebirth. In her most profound despair, yet pregnant with the seeds of luminosity, she in you is the Dark Night of the Soul, the blood weeping in the valley of life. It is only she who can go to the very depths—that place beyond the journey of tortuous discoveries, and yet emerge once more transformed.",
		],
		equaliser: [
			"<em>Contemplate for a while on the unknown—that which is hidden from you, within and around you. Let yourself relax into it. Become comfortable with it. Let yourself become at one with it. Where does the darkness begin and end? To complete, dance a prayer with your body.</em>",
			"<em>Gather some natural objects around you and sit with them. Just be present to your sitting and to the objects around you, for example, a leaf, a feather or a stone. Feel them, touch them, become one with them and explore the feelings in you that are generated by doing this.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you. </strong>",
			"<em>The Search, The Fall, Transcendence, Heaven, The Holy Grail, The Quest, The Vision, Martyr, The Ark, The Sword, The Light, Enlightenment, Consciousness, Awareness, Bliss, Illumination, Blessing, Spiritual Fire, Nirvana, Spiritual Path, Divine Journey, Vows, Initiation, Banishment, The Fall</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you?After equalising who is the new being you are creating?</em> </strong>",],

		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> The Masculine and the Feminine Spiritual Quest </strong>",
			"<em>I am that within you which kindles the fire in your heart; those feelings which consume you and cannot be satiated by anything physical, by any man, woman or thing. I am the bringer of the longing and the aching that can never go away, which, in a world of death and impermanence will not die. In this world where nothing can be clung to, I am the heralder of That to which you can safely hold on to.</em>",
			"<em>Through the prompting of I in you, you can journey beyond time and enter the space within, and extend your mind beyond the idea of this, your life.</em>",
			"<em>Is It that which some call God, the Absolute or the Divine? What is this Being which is ever-present, ever around you, intrinsically connected to your every sense of you; which, you love and hate, welcome and abandon, long for and yet, wish to annihilate—that which you know you can love with more ardour than life itself?</em>",
			"<em>You are on a quest you can never forsake, for It will eat away at your heart. And even though you have forgotten It so many times, It can never leave you. For, Its memory remains deep within you until you unite with Its spiritual fire.</em>"],
	},
	{
		id: 13,
		name: "Wisdom",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Wisdom,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature.",],
		description: [
			"<em>The image is of a pearl glistening as it nestles in an opened shell. The pearl has a quality of translucence and luminosity. These qualities show how wisdom can shine, and how its light can be seen and felt in an almost visible way. The pearl is symbolic of how wisdom is a revelation of a much deeper, hidden process of limitation and endurance, and its accompanying pain as it transforms from an uncomfortable grit to a lustrous jewel.</em>",
			"Perhaps it is not the grit that forms the pearl, but the pearl that creates the grit so that its being can evolve and come into form. You are already the knower and the holder of true wisdom, but for now, you have simply forgotten.",
			"And when, after all the times you have got it wrong, and all the dreadful blunders are past, when you can cease the thoughtless actions and the hurts, you will find what has always been there.  The pain will finally scour away the lining of this mere superficial ignorance—the veil of the illusion of this temporary grit. Yes, you are losing your innocence and naivety. Yes, you are finally growing up. Yes, the truth is that in order to achieve wisdom a sacrifice must be made, and a price must be paid."],
		equaliser: [
			"<em>Contemplate—does age have anything to do with wisdom?</em>",
			"<em>Reflect on a past situation that limited, confined, frustrated and irritated you and that caused you pain you had to endure for some time. What is the pearl you now have?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Imbecilic, Unthinking, Unconscious, Stupid, Foolish, Moronic, Gormless, Subnormal, Low I.Q., Uneducated, Non-Discriminate, Misunderstood, Simple, Illiterate, Empty Headed, Brainless, Thick</em>",
			"<strong><em> How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>",],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Wisdom and Ignorance </strong>",
			"<em>I am that in you which makes you aware of the need for fairness and justice, that makes you rational and able to make sound judgements. I am that in you which brings to your awareness the fact that your wise actions are not merely happy accidents, strokes of luck or chance intuitions. I am that in you which disciplines and trains you, to firmly root on solid and tested ground.</em>",
			"<em>Wisdom is something that you can aspire to, something that you can respect. Who in their right mind would wish to be thought of as ignorant or foolish, to be exposed in this and found out? One of your greatest fears is appearing not good enough, not clever enough, or just plain stupid.</em>",
			"<em>But when you know that your journey is a process of learning, that your blindness can at times be just right, that until your heart and mind are truly free, the duo of ignorance and wisdom will be your constant travelling companions, so, you can stop judging, let go, be open and patient, inspired that you will ultimately harvest the depths of your heart. You will claim its truthful wisdom according to a perfect Divine Timing.</em>"]
	},
	{
		id: 14,
		name: "Ignorance",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Ignorance,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature.",],
		description: [
			"<em>The image is of a closed oyster shell. The light around it is nebulous, misty and opalescent. Apart from this promising light there is nothing to indicate whether there is a pearl contained inside or not. To open the shell too soon out of mere curiosity is to potentially destroy any growth. To leave it closed and wait for nature to take its course requires patience, understanding and correct timing. And, of course—who knows whether there is a pearl inside or not?</em>",
			"Can you remember a day when you did not once think or act out of ignorance? Can you remember an hour that you can honestly say was full of complete understanding?  Think about the times in your past when you acted in ignorance, and consider the times to come when you still might act in this way, no matter how hard you try not to.  Are not your potential ignorance and stupidity, unconsciousness and lack of understanding, qualities you have to live with, both in yourself and in others? And when you see how ignorant you can be, how difficult it is to be wise and to always say and do the right thing, can you really afford to be arrogant and self-righteous again?",
			"To live comfortably with this reality is to live a heroic and inspired life. It means living with the discomfort of knowing this truth and using it as one of the aspects of the foundation for your growth. You are growing, and in spite of yourself, the promise of you as the pearl will always be there. You can rejoice in that!"],
		equaliser: [
			"<em>Think of the last time you were at the receiving end of someone’s ignorance. What made you react to it?</em>",
			"<em>Remember what you thought was a seemingly wise act that you did in the past. On reflection, how might this have actually been an ignorant one?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Literate, Knowledgeable, Consciously Aware, Wise, Luminary, Light Giving, Judicious, Sage, Sound Judgement, Discerning, Teaching, Illuminated, Highly Intelligent, High I.Q., Bright, Smart, Quick Witted, Mindful, Thoughtful, Considerate, Genius</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?<em> </strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Wisdom and Ignorance </strong>",
			"<em>I am that in you which makes you aware of the need for fairness and justice, that makes you rational and able to make sound judgements. I am that in you which brings to your awareness the fact that your wise actions are not merely happy accidents, strokes of luck or chance intuitions. I am that in you which disciplines and trains you, to firmly root on solid and tested ground.</em>",
			"<em>Wisdom is something that you can aspire to, something that you can respect. Who in their right mind would wish to be thought of as ignorant or foolish, to be exposed in this and found out? One of your greatest fears is appearing not good enough, not clever enough, or just plain stupid.</em>",
			"<em>But when you know that your journey is a process of learning, that your blindness can at times be just right, that until your heart and mind are truly free, the duo of ignorance and wisdom will be your constant travelling companions, so, you can stop judging, let go, be open and patient, inspired that you will ultimately harvest the depths of your heart. You will claim its truthful wisdom according to a perfect Divine Timing.</em>"]
	},
	{
		id: 15,
		name: "Generosity",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Generosity,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."
		],
		description: [
			"<em>The image is of two hands open and ready to both give and receive. They show the generosity of the greatest gift–that of the Self. They are uncluttered, empty and ready.</em>",
			"What more can you give to anyone than your-self—your unconditional body, mind, hands and presence? And what greater gift can you give to another than to allow yourself to be given to? Take away all the shoulds and ought nots, and the manipulations and coercions that are sometimes inextricably woven into your sharing; remove all ideas of doing good and being helpful and of keeping the status quo. Stop trying not to do something, even to not cause pain and you will know the true nature of generosity.",
			"There is no correct or right way, but if your thoughts, words and actions come from the truth of your heart, if they are pure and untainted by your attempts to control or manipulate or even patronise, then they cannot be ill-timed, harmful or inappropriate.",
			"It is a great honour, a great blessing to be allowed to give. In your giving it is you who is the truly honoured one. It is a great joy, a great blessing for you when you allow yourself to receive—when you allow yourself to be humble and vulnerable enough, to surrender control enough to receive.",
			"Divinity has generously given you life. What do you intend to do with it?",
		],
		equaliser: [
			"<em>Reflect on your last seeming generous act. What was in it for you?</em>",
			"<em>How do you share your happiness and sadness with others? Do you share both equally?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Grasp, Hold, Tighten, Contract, Block, Defensive, Mean, Denial, Self- Centred, Self- Motivated, Selfish, Avarice, Impoverished, Egocentric, Lie, Self -Seeking, Number One, Ride Roughshod, Steal</em>",
			"<em><strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong></em>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Generosity and Selfishness </strong>",
			"<em>The ability to give and receive without fear—to do so willingly, without conditions, without expectation of reward, is the essential nature of the heart.</em>",
			"<em>This is the heart’s ecstasy, that which inspires and motivates your true joy, and gives you the continual source of nourishment of Divine food. So when did that dark shadow creep into your heart, stifling and suffocating your natural essence? You poor dear, what is that pain, that wound, that steals into your heart to rob you and deny you? That contaminates your responses, your motivations, your delight in sharing and fully participating in the rapturous flow of life?</em>",
			"<em>When did it begin, this darkening of the light, this holding back of life-breath, this mistrust and misgiving, this conditional sharing of yourself? When did giving and receiving cease to be such a spontaneous joy,  become contrived and thought out, measured and allotted, according to the rules of your current deluded ideas of right and wrong? When did you last feel bliss through giving and receiving? When did you last feel deadened and heavy and afflicted at the very thought of it?</em>"]
	},
	{
		id: 16,
		name: "Selfishness",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Selfishness,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."
		],
		description: [
			"<em>The image is of a pair of hands held closely together. They are crossed and partially hidden.</em>",
			"The background scene is abundant with fruit-laden trees growing under a bright and sunny sky. The hands have abundant potential to give and may, indeed, appear to give a great deal, but underneath there is the constant fear of loss or pain and a  sense of needing to defend or protect the self.",
			"When was it that I counted more, when I began to form in such an inflated distorted way? This cannot be truly I, this painful and wounded way of sharing thine and mine. How can I let go of this I—how can I let go of its hold and be free? When was it that my fear of loss and abandonment began to enslave me, my fear of losing what I think of as me, but that is really only my pride, my falseness and my superficiality?",
			"When was it that in every act of giving, I began to look only for that which was of value to me? Where, when did I learn this deluded view, this strangulating and breath-sucking aspect of myself? But then, do I really want to let my grasping self go —to let go of my self-centred being and to share fully? Do I really want to know how to share something so that I can then no longer ever call it my own?"],
		equaliser: [
			"<em>Think of all the things you are afraid of losing. Visualise giving them away to help others be happy.</em>",
			"<em>Recall the last time you thought someone else acted in a selfish way and that made you angry and frustrated. Think of an appropriate way of giving this to yourself. </em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you. </strong>",
			"<em>Share, Bless, Gift, Openness, Do Gooder, Radiate, Smile, Altruistic, Magnanimous, Profuse, Unconditional, Free of Motive, Selfless, Service, Gift of the Heart </em>",
			"<em><strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong></em>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Generosity and Selfishness </strong>",
			"<em>The ability to give and receive without fear—to do so willingly, without conditions, without expectation of reward, is the essential nature of the heart.</em>",
			"<em>This is the heart’s ecstasy, that which inspires and motivates your true joy, and gives you the continual source of nourishment of Divine food. So when did that dark shadow creep into your heart, stifling and suffocating your natural essence? You poor dear, what is that pain, that wound, that steals into your heart to rob you and deny you? That contaminates your responses, your motivations, your delight in sharing and fully participating in the rapturous flow of life?</em>",
			"<em>When did it begin, this darkening of the light, this holding back of life-breath, this mistrust and misgiving, this conditional sharing of yourself? When did giving and receiving cease to be such a spontaneous joy,  become contrived and thought out, measured and allotted, according to the rules of your current deluded ideas of right and wrong? When did you last feel bliss through giving and receiving? When did you last feel deadened and heavy and afflicted at the very thought of it?</em>"]
	},
	{
		id: 17,
		name: "Courage",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Courage,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a figure walking over a path of hot coals. The act demands courage and fearlessness. There is the chance of being burned and hurt, but there is also the potential to pass through unharmed if there is enough mindfulness, trust and skill.</em>",
			"To be able to walk on fire, but not be overwhelmed, to be ignited and inspired and yet stay sane, is to be able to go beyond mere instinctual survival and basic humanness. This is the action of the heart where the higher mind triumphs and the Divine Spirit overcomes the instinctual, reflexive or fearful body. This is the essence of the hero and the just and fair warrior whose actions of heart and soul and their results are more than the possible luck or disaster of the fool.",
			"What courage do you need to walk on hot coals? Do you need faith? When you have faith and trust, it does not matter whoever else considers you foolish or brave. When you have faith and trust, you can consciously take the first step through the fire, unconcerned as to whether you will live or die. When you have faith and trust you can walk into the unknown and take your bravest and most fearless step yet."],
		equaliser: [
			"<em>How much do you compromise your truth in order to be liked and respected, or to keep the status quo?</em>",
			"<em>Think of someone you admire and respect for their courage and bravery. Try to identify what it is about them that you consider to be so inspiring. Find these qualities in yourself.</em>",
			"<em>Honour the courage in you that has brought you this far.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Shocked, Paralysed with Fear, Scared, Phobia, Terrorised, Coward, Yellow Belly, Wimp, Numbed, Spineless, Lily Livered, Incapacitated with Fear, Play Dead, Tremble and Shake, Gutless</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Courage and Fear</strong>",
			"<em>What can be more inspiring than the hero and the heroic act? Would you not like to be a hero, honoured and acknowledged for your bravery and wise actions, your courageous and warrior-like qualities in the face of adversity?</em>",
			"<em>The concept of courage gives us hope for the future. If human beings can courageously act in an inspired way, it shows hope and promise for this sometimes tentative and disturbing world.</em>",
			"<em>It shows that we are capable of facing, overcoming and surviving the most tremendous obstacles.</em>",
			"<em>It suggests our Divinity, our ability to become greater than what we think we are. For, an act of true courage does not merely involve looking after oneself. Honour is involved, the fight to save the other, the cause, the truth— that takes us beyond a merely instinctual and selfish response.</em>",
			"<em>And so we become attached to this valiant notion, this unshakeable proof that we can be Divine. But here we meet its opposing and ever-present companion, its counterpart of fear. In the very play of the hero, in the honouring of courageous acts, we dis-empower its complement of fear.</em>"]
	},
	{
		id: 18,
		name: "Fear",
		subTitle: "The Inspiration and Opposition Dualities",

		image: Fear,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a figure held in ice. The eyes are staring in terror from a body that looks cold, lifeless and unable to move. It knows it is trapped in the midst of the most overwhelming fear. The ice is symbolic of the rigidity and frigidity of fear, its coldness and how it hardens the heart making it unable to feel any love.</em>",
			"Your mouth is dry and you cannot swallow; you feel nauseous; your legs and hands are trembling and you can feel your heart thumping. You are numb, frozen, rigid—you are very, very scared. You are overcome with the most awful dread and you cannot think clearly. You are not sure what is going on, what is happening out there, beyond this freezing, icy cloak which seems to surround you. Why can you not shake it off? Why can you not escape from it? Why do your hands not move when you want them to? Why are you so stuck, so incapacitated by this dread, suspended between life and death? Is this not a state more intolerable than either life or death could be?",
			"Think of love, of warmth in your heart—of another’s pain, perhaps. Draw from your heart this fire of love and let it envelop you. Feel your warm blood moving through your veins once more. Let your heart fire melt this frozen cloak and burn away the phantoms of your fears."],
		equaliser: [
			"<em>Recall one of your first memories of being very scared. Are you still frightened?</em>",
			"<em>Imagine that you are a block of ice. Allow it/you to melt. What/who is it that is melting?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Bravery, Hero, Valiant, Honest and True, Brave Soldier, Fearless, Faith, Lion Hearted, True Grit, Pantheon, Honourable, Dutch Courage, Warrior, Fighter, Heroine, Suffragette, Freedom Fighter, Rescuer, Bravado</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Courage and Fear</strong>",
			"<em>What can be more inspiring than the hero and the heroic act? Would you not like to be a hero, honoured and acknowledged for your bravery and wise actions, your courageous and warrior-like qualities in the face of adversity?</em>",
			"<em>The concept of courage gives us hope for the future. If human beings can courageously act in an inspired way, it shows hope and promise for this sometimes tentative and disturbing world.</em>",
			"<em>It shows that we are capable of facing, overcoming and surviving the most tremendous obstacles.</em>",
			"<em>It suggests our Divinity, our ability to become greater than what we think we are. For, an act of true courage does not merely involve looking after oneself. Honour is involved, the fight to save the other, the cause, the truth— that takes us beyond a merely instinctual and selfish response.</em>",
			"<em>And so we become attached to this valiant notion, this unshakeable proof that we can be Divine. But here we meet its opposing and ever-present companion, its counterpart of fear. In the very play of the hero, in the honouring of courageous acts, we dis-empower its complement of fear.</em>"]
	},
	{
		id: 19,
		name: "Freedom",
		subTitle: "The Inspiration and Opposition Dualities",

		image: Freedom,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a young, happy and smiling face. There is freedom in the smile. In the background is a clear, bright sky with an open door. The door stands on its own with no supporting structure attached to it. Through the door one can see further into the open, cloudless sky. The view is symbolic of our potential to know the limitless view. The choice is yours whether you see it or not. </em>",
			"Observe your breathing—how constricted or flowing is it right now? Have you been holding it and allowing yourself to slowly suffocate? Relax and allow your breath to just be how it wants to be. There is no need for you to try to make it faster or slower, to strain or to make it flow a certain way. Relax, surrender, allow and feel freer.  Let that breath flow naturally through every cell of your being and allow your body to let go of all the stress. Feel how tiring it has been for you with the constant pressure of effort, the trying, the pushing and the cajoling to make everything fit and get it right. Why don’t you just stop working it and just relax and let yourself laugh?",
		],
		equaliser: [
			"<em>Would you like to be a free spirit? Be it! </em>",
			"<em>What would you like to do today, this week, this year? Do it! </em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you </strong>",
			"<em>Controlled, Constricted, Manacled, Bound, Limited, Held, Obstructed, Tied, Prisoner, Jailer, Suspended Sentence, Hung, Interned, Limbo, Hostage, Probation, Confined, Against the Wall, Captive, Detention, Out on Bail, Exiled, Life Sentence, Purgatory </em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Freedom and Imprisonment</strong>",
			"<em>What is it that is making you feel free or imprisoned? Can it really be anything to do with something external, or is it always an experience of your mind? An external situation can be freeing or imprisoning, but so can your every internal idea, concept and thought.</em>",
			"<em>When you smile, is your smile free of attachment, or is it bound by expectational chains? How free are your laughter and your tears? Can you be spontaneous, or are you limited to an automatic response?</em>",
			"<em>The world will remind you again and again of how little control you have. You will constantly meet with restrictions and limitations in your seeming attempts to have your own way. But this very uncomfortableness will be a Divinely constant and enduring caress leading you to the comfort of your True Freedom and Rest. Look at the sky with open and clear eyes and save yourself from being taken in by the unreality below. All you need do is observe and, as much as you can, remain unaffected.</em>"]
	},
	{
		id: 20,
		name: "Imprisonment",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Imprisonment,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of an unhappy face trapped behind translucent and nebulous prison bars.</em>",
			"Against the clear sky can be seen a closed door. The face looks as though it is in hell, although the sky is clear and bright and the prison bars are more imaginary than real. A door can be opened! What is holding this unhappy face in bondage?",
			"What keeps you in unhappiness, in a feeling of hell, bound and caught in helplessness in this virtually limitless, unbounded world where possibilities and choices are as endless as the stars? You may feel incapacitated and worthless, or trapped for the rest of your life, but are the bars that seem to imprison you so real? Your freedom is only a thought away.",
			"It is only your idea of the world and your expectations that continually disturb and narrow your vision. Let the bars dissolve. Turn around and look at the sky as it comes into view. Be open and allow life to reach out to you. Face the door and push it open."],
		equaliser: [
			"<em>Imagine your heart imprisoned and bound by iron bars. Visualise them dissolving until you are free!</em>",
			"<em>What emotion are you feeling right now? Observe it, allow it, dis-identify with it and see what happens.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Redemption, Flow, Paroled, Emancipated, Divorced, Let Out, Let Go Of, Deliverance, Narrow Escape, Free House, Unshackled, Gliding, Flying Free, Exonerated, Free Will, Your Choice, Play Safe, Relax, Just Be, The Unbearable Lightness of Being</em>",
			"<strong><em> How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Freedom and Imprisonment</strong>",
			"<em>What is it that is making you feel free or imprisoned? Can it really be anything to do with something external, or is it always an experience of your mind? An external situation can be freeing or imprisoning, but so can your every internal idea, concept and thought.</em>",
			"<em>When you smile, is your smile free of attachment, or is it bound by expectational chains? How free are your laughter and your tears? Can you be spontaneous, or are you limited to an automatic response?</em>",
			"<em>The world will remind you again and again of how little control you have. You will constantly meet with restrictions and limitations in your seeming attempts to have your own way. But this very uncomfortableness will be a Divinely constant and enduring caress leading you to the comfort of your True Freedom and Rest. Look at the sky with open and clear eyes and save yourself from being taken in by the unreality below. All you need do is observe and, as much as you can, remain unaffected.</em>"]
	},
	{
		id: 21,
		name: "Love",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Love,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a brightly-coloured crimson heart set against a background of gold. This is the symbol of sincerity, love and compassion. There is a small wound on the left- hand side of the heart where it has been pierced by Cupid’s bow. This depicts the heart’s initial awakening into the realm of feelings—without which your potential to emotionally experience and express cannot be fully born.</em>",
			"What is this thing called love—this something that we are sometimes prepared to live and die for, to fight and struggle for, which we cry out for and long to come into our lives? And yet which we deeply fear for we know that once pierced, once awakened, once burned by its flames we can never be the same again.",
			"A miracle takes place! You begin to think less about yourself and your heart begins to feel and reveal its full depth.  You become able to love and able to give and cherish in spite of your own cravings and needs. Your commitment to loving becomes more important than the feeling of being in love—that exciting infatuation. The exclusiveness of your heart stops.",
			"Only when you experience this can you depend on the constancy of your love. Only when your love profoundly matures will you understand the true meaning of Love."],
		equaliser: [
			"<em>What is the difference between infatuation and love? How can each be inspiring and helpful for you?</em>",
			"<em>Think of someone you feel that you love very deeply. How unconditional is this love? What does it depend on? What do you need to let go of in order to open your heart further?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Ill Will, Malice, Animosity, Contempt, Ambivalence, Hateful, Loathe, Rage War On, Suspicion, Envy, Jealousy, Assault, Malign, Repugnant, Aversion, Reject, Dislike, Destroy, Murder, Heinous, Abhor, Vendetta, Unforgiving, An Eye for an Eye, Slander</em>",
			"<strong><em> How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Love and Hate </strong>",
			"<em>To my Dearest Love,</em>",
			"<em>Oh my beloved, I love you so much and I hold you so dear. You ignite my passion and inspire my actions—you who are so beautiful, so gracious, so tender and more brilliant and dazzling to me than the stars in the sky. You open my heart and give me faith in the world once more. You take away my shadows and show me how I can live again! I thought before that I had loved, but it was merely a glimmer compared to this. I live for you I die for you—I am nothing without you. Please, please do not change and do not go away. For I could not bear to live without you! Please, my Dearest Love, never leave me, nor abandon me for another. I could not bear that. Say that you will be with me forever, say that you will always be there for me, say that you will always feel this way about me. Say that you will never lie to me or desert me. Yes, I know that you cannot promise, I know that sometimes things can change, but I swear to love you always and you must know that if you go I shall pine and fade away and I shall never be happy again. Please, my love, do not hurt me, for I do not want to hate you or close up my heart again. Please, do not do anything to make me angry or lose faith in you, to loathe you or worse—to feel nothing for you. Remember please, you who I love so very much, that everything depends on you.</em>",
			"<em>Yours always in love,</em>"
		]
	},
	{
		id: 22,
		name: "Hate",
		image: Hate,
		subTitle: "The Inspiration and Opposition Dualities",
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"The image is of a malignant and tumorous heart. It is blood red and black in colour on a background of uninviting grey. The image is symbolic of how our hatred eats away at our heart and its capacity for love. If the hatred is strong enough, if the malice and aversion become ingrained, then malignancy can set in.",
			"What is it that eats away at your core, at the very heart of you that stops you from finding the openness and heart-warmth you long for? What hides that feeling of joy you know exists but cannot grasp, that elusive feeling of warmth at the thought of life and other human beings?",
			"What is it that you still need to forgive, to let go of, to wish well of, so that your heart can cry into softness and be freed? Do you not sometimes feel its tears, its pain, as it tries to tell you of its fears? Realise how much it is longing and burning to be heard. It is you who suffers when you hate and attack your own self in this way.",
			"You must not die immersed in perpetual suspicion, revulsion or even arrogance. Let all these phantoms that have no real substance, be finally released into Love…"],
		equaliser: [
			"<em>Paint the symbol of the blackened and diseased heart. Know that what you paint is a reflective symbol of you. Continue to paint daily, weekly, monthly, until it changes into something else.</em>",
			"<em>Remember when you have loved intensely and this love has turned into hate. What does this say to you?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Amour, Rapture, Innocent Flirtation, Betrayal, Dalliance, Serenade, Infatuation, Ardour, Passion, Lust, Woo, Platonic Friendship, Unrequited Love, Un-loveable, Philanderer, Seducer, Love Letter, Torch Song, Wear Your Heart on Your Sleeve, Love Potion, Adulation, Beloved, Devotion, Compassion",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Love and Hate </strong>",
			"<em>To my Dearest Love,</em>",
			"<em>Oh my beloved, I love you so much and I hold you so dear. You ignite my passion and inspire my actions—you who are so beautiful, so gracious, so tender and more brilliant and dazzling to me than the stars in the sky. You open my heart and give me faith in the world once more. You take away my shadows and show me how I can live again! I thought before that I had loved, but it was merely a glimmer compared to this. I live for you I die for you—I am nothing without you. Please, please do not change and do not go away. For I could not bear to live without you! Please, my Dearest Love, never leave me, nor abandon me for another. I could not bear that. Say that you will be with me forever, say that you will always be there for me, say that you will always feel this way about me. Say that you will never lie to me or desert me. Yes, I know that you cannot promise, I know that sometimes things can change, but I swear to love you always and you must know that if you go I shall pine and fade away and I shall never be happy again. Please, my love, do not hurt me, for I do not want to hate you or close up my heart again. Please, do not do anything to make me angry or lose faith in you, to loathe you or worse—to feel nothing for you. Remember please, you who I love so very much, that everything depends on you.</em>",
			"<em>Yours always in love,</em>"
		]
	},
	{
		id: 23,
		name: "Protection",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Protection,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a closed and padlocked door. Outside it are two dragons protecting the entrance. The scene is bright and sunny. The dragons are the guardians of the treasures and knowledge within.</em>",
			"There is a time to close and lock the door and show the world that entry is by invitation only. It is your responsibility to show clearly that this is so. How can anyone else guess or assume whether you are available or not, whether you are prepared to be used at this time or not, unless you make your rules clear and show your boundaries and lines of demarcation?",
			"Perhaps you are afraid to say no or to show that you can withdraw. Perhaps you do not know how to ask for your needed space. Perhaps you do not feel you have anything of worth which needs protecting, defending or caring for. If you feel constantly invaded, abused and trespassed upon, what do you still need to learn and to fully believe in order to stop this onslaught?",
			"Until you learn how to care for and protect yourself, you cannot even begin to know or acknowledge your own value. Can you honestly expect someone else to do this task?  And when you do not know how to defend and guard what is precious and rare within yourself, how can you ever know and respect another’s sacred path and treasures?"],
		equaliser: [
			"<em>Draw or paint a golden-coloured circle. Within it place all the external and internal things you have which you feel are precious, and which need honouring, respecting and guarding. Place this somewhere hidden from others but accessible to you. Look at it and review it, if necessary, regularly.</em>",
			"<em>Contemplate on how you can be vulnerable, open, and yet protected at the same time.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Marauder, Looter, Pillager, Plunderer, Trespasser, Interloper, Interferer, Harass, Ambush, Meddler, Devastate, Attacker, Waylay, Onslaught, Slaughter, Murder, Beaten Up, Tricked, Stolen From, Cast Out, Homeless, Surprise Attack</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Protection and Invasion</strong>",
			"<em>When is it appropriate to defend and protect, and when is it appropriate to interfere and trespass? When should we allow others in and when should we not let them pass our boundary of self, our stronghold of self-worth?</em>",
			"<em>How can we ever know how to answer these questions without wisdom of the heart? We cannot know honour, respect or truth through a sentimental or misguided mind. Rules can sometimes be broken and bent in accordance with right action and thought. But the wise heart is neither mindless nor careless. It knows that it has nothing in the world to lose—that in this world of material form, we can only be transient caretakers. And the wise heart knows too, that when we allow others to take advantage, and we allow them to do us harm, it is we ourselves who take on the role of the true invader and plunderer of our very selves.</em>",
			"<em>You have a responsibility to respect and protect your self–to allow entry by invitation only. You need to ensure that this is accompanied by due care, respect and understanding. It is up to you to limit and keep your own boundaries. Remember always, that you are worth saving and defending.</em>"]
	},
	{
		id: 24,
		name: "Invasion",
		subTitle: "The Inspiration and Opposition Dualities",
		image: Invasion,
		introduction: [
			"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
			"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
			"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
		description: [
			"<em>The image is of a door with a broken lock. It is slightly ajar. There is no knowing whether someone is inside or not or whether anyone has entered at all. But the protective seal has been broken. It has been touched and interfered with. A dove can be seen flying into the distance as if from the door.</em>",
			"Is there a time when it is appropriate to break a seal of safety and trespass on sacred ground? Is there anything that we can call sacred and therefore untouchable, or is this a delusion giving us the mere pretence of security—a door, a wall to protect our vulnerability?",
			"But being vulnerable is not the same as being weak and need not be hidden. Your true vulnerability is always seen and visible for what it is—this is its real protection. And sometimes it may be necessary to fiercely attack your own and other’s dishonesties, prejudices and illusions, to break through the barriers of your fears and soar beyond their confinement.",
			"Learn the secret truth of your heart and its knowing of perfect right action and timing. Listen to your inner voice, to the compassionate and caring One and you need have no fear of devastation or annihilation."
		],
		equaliser: [
			"<em>Reflect on any of your past acts which you can now see as being interfering and invasive. What would you do differently now?</em>",
			"<em>Recall a time when someone was ruthless and trespassed into your sacred space? How did this affect you and what did you learn?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you</strong>",
			"<em>Hide, Defend, Respect, Honour, Guard, Care For, Cherish, Value, Deity, Secure, Foster, Nurture, Conserve, Take Refuge, Fortress, Barrier, Hedge, Stronghold, Asylum, Wall, Moat, Benefactor, Under Lock and Key</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Protection and Invasion</strong>",
			"<em>When is it appropriate to defend and protect, and when is it appropriate to interfere and trespass? When should we allow others in and when should we not let them pass our boundary of self, our stronghold of self-worth?</em>",
			"<em>How can we ever know how to answer these questions without wisdom of the heart? We cannot know honour, respect or truth through a sentimental or misguided mind. Rules can sometimes be broken and bent in accordance with right action and thought. But the wise heart is neither mindless nor careless. It knows that it has nothing in the world to lose—that in this world of material form, we can only be transient caretakers. And the wise heart knows too, that when we allow others to take advantage, and we allow them to do us harm, it is we ourselves who take on the role of the true invader and plunderer of our very selves.</em>",
			"<em>You have a responsibility to respect and protect your self–to allow entry by invitation only. You need to ensure that this is accompanied by due care, respect and understanding. It is up to you to limit and keep your own boundaries. Remember always, that you are worth saving and defending.</em>"]
	},
	{
		id: 25,
		name: "Birth",
		subTitle: "The Way and Transformation Dualities",
		image: Birth,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"],
		description: [
			"<em>The image is of a child breathing its first breath. As the new being has manifested, its face is screwed up against the unnatural light it is so acutely aware of and which it feels and sees as it comes into life.</em>",
			"To be born and to experience birth means to be new, and to begin anew with the need to surrender to that which is holding you. Here you require implicit trust. When you are new, how can you know the rules, how do you know what is right or wrong or how to care for yourself? And in birth you are defenceless, vulnerable and fragile. You are at your most critical hour. The promise is great, but it may not be met—there could be interference or attack.",
			"The beauty is that now there is something to be discovered, a new path ahead, a blank page, hope and the potential for anything.",
			"Perhaps true pain is the result of that which cannot find its expected fullness; that which is cut before its promise can begin. How many times have you experienced loss, unfulfillment, and bitter frustration, as your bud is broken before its flower can open and feel the sun?",
			"But then, feeling the sun means being ravaged by its heat. It means being changed and having light shine on you so ruthlessly that you cannot escape your burning fate. Only then will you know you have lived. Only when you live can you die."],

		equaliser: [
			"<em>Imagine that the child in the image is talking to you. What is this birth in you saying?</em>",
			"<em>How fearful are you right now? What has this to do with feeling new? Is this related to a memory of your physical birth?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Death Sentence, Deathly, Fatal, Grim Reaper, Father Time, Ghost, Walking Dead, Post-Mortem, Terminal, Bereft, Euthanasia, Lethal, Morbid, Macabre, Being Put Down, Untimely Death, Premature Death, Rigor Mortis, Extinction, Nothing, Annihilation, Destruction, Non-Existence, The End</em>",
			"<strong><em> How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong> "
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Birth and Death</strong>",
			"<em>The never-ending cycle of beginning and ending, of emerging and withdrawing, is the most natural thing in the world. What exists now will one day not be. What is to be will come to an end.</em>",
			"<em>But what pain we sometimes feel with such transience. Perhaps you have also felt the relief. How can we find the most natural thing in the world so unnatural—something to be so frightened of? We cannot run away from the truth of our birth and our death, for these are the realities of this existence.</em>",
			"<em>And we can fear birth as much as death, we fear the emergence of something, of someone as much as the leaving of something, of someone. How is it that we cannot peacefully let go and allow the space for these new hopes and dream endings?</em>"
		]
	},
	{
		id: 26,
		name: "Death",
		subTitle: "The Way and Transformation Dualities",
		image: Death,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"],
		description: [
			"<em>The image is of the moment the last breath is expelled. The face is already at peace. Life is over. The battle has ceased.</em>",
			"That which came into being and which lived its nature has now passed. Perhaps the promise was fulfilled—perhaps it was not. Maybe what was thought to be the promise was never even a reality. The real promise was what simply occurred. What does it matter now that it has ended?",
			"The certainty of death is real—you cannot change that. Maybe with the birth you had some control—maybe you were able to manipulate or make a covenant—but you cannot manipulate the promise of your death. Perhaps it is comforting to know that there is nothing you can do but to let it have its own way, for there is no earthly way of changing or avoiding this momentous event.",
			"Knowing this, you can fully live and enter the life which gives death its first breath—life as the parent of death, which mothers and fathers and cares for its final offspring."],
		equaliser: [
			"Contemplate on the promise of your death. How would you like it to be?",
			"<em>Remember the end of one of your long-held beliefs or attitudes such as a change in your religion—or perhaps the end of a friendship or relationship. In what way did this death release you and enable you to be born again?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Emergence, Cradle, First Breath, New Born, Begin, Genesis, Transience, Premature, Breech, Head First, Pre Natal, Post-Natal, Sprout, Bloom, On Time, Originate, Natal, Seed, Birth Mark, Bring Forth, Bear, Nativity, Flower, Creation</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Birth and Death</strong>",
			"<em>The never-ending cycle of beginning and ending, of emerging and withdrawing, is the most natural thing in the world. What exists now will one day not be. What is to be will come to an end.</em>",
			"<em>But what pain we sometimes feel with such transience. Perhaps you have also felt the relief. How can we find the most natural thing in the world so unnatural—something to be so frightened of? We cannot run away from the truth of our birth and our death, for these are the realities of this existence.</em>",
			"<em>And we can fear birth as much as death, we fear the emergence of something, of someone as much as the leaving of something, of someone. How is it that we cannot peacefully let go and allow the space for these new hopes and dream endings?</em>"
		]
	},
	{
		id: 27,
		name: "Clinging",
		subTitle: "The Way and Transformation Dualities",
		image: Clinging,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a new-born child clutching tightly the finger of its mother’s hand. The eyes are closed and the face is contented, yet slightly anxious. The child does not know why it clings, nor why the clinging is so comforting, and yet comforting it is. The grasping is instinctual and without thought.</em>",
			"What are you clinging to now and what have you clung to in the past? Is your need to cling so great that life would be intolerable without something or someone to hold on to? The holding reassures you and enables you to continue in your life but consider that what you cling to may no longer be necessary—that you have, in fact, outgrown this particular need. But then, perhaps you have become attached to the grasping itself and it has become a habit with you.",
			"There is a time to let go, even if to hold onto something else, perhaps even more tightly than before. But look carefully and closely at what you cling to, what you cleave to, and discover what you are held by. In finding this, you will discover what binds you."],
		equaliser: [
			"<em>Choose someone or something you desire to hold on to.  In your mind’s eye hold on as tightly as you can for as long as you can. Grasp strongly until you experience a change. Repeat until you feel freer.</em>",
			"<em>How loose or tight does your grasp need to be? Explore this.</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Let Go, Hand Over, Submit, Accept, Lose Control, Relax, Relinquish, De-Stress, Acquiesce, Capitulate, Succumb, Desert, Forsake, Forfeit, Lose, Bow Before, On Your Knees, Unconditional and Conditional Surrender, Sacrifice, Give Up, Cessation</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?<em></strong>"],

		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Clinging and Surrendering</strong>",
			"<em>Imagine a life without grasping—without the need to grasp, without the worry that you might lose or be lost or even miss out. Why do you cling? Is it out of necessity or fear? Is it sensible or is it folly to continually look for something to hold onto? What did you hold onto as you were born? What will you be able to hold onto as you die? Can you really expect to hold onto anything in this transient and ever-changing world? And when you fully surrender and relinquish possession of what you thought you owned you can find, perhaps, the full meaning of your life.</em>",
			"<em>At the moment of your greatest tension, when you find yourself holding on so tightly that your hands and your arms ache, you will find there is the greatest potential for your releasing, for your freedom and for the ties to break. Perhaps it is not a question of whether you cling or surrender, but what it is you cling to and what it is that can break.</em>"
		]
	},
	{
		id: 28,
		name: "Surrendering",
		subTitle: "The Way and Transformation Dualities",
		image: Surrendering,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a figure outlined against bright light. The hands are offered towards the light. This reflects the handing over of control while at the same time remaining open.</em>",
			"When you cease to resist or oppose, when you finally relax, let go and give in, can you not feel the most sublime relief? Perhaps this is not what you initially thought you wanted or wished for—perhaps even in the very act of surrendering you further some pain. But when you let go of the contest and the struggle you can finally breathe and submit to what is. Now you are open to reality. The gaining of a tiny glimmer of truth is worth this surrendering—the handing over. Look beneath the rubble of your misshapen hopes and illusory dreams and you will find the glistening of gold.",
			"Your body and soul weep for you to surrender. In their tension they cry out for relief from the strangulation of your parasitical holding. You are a flower longing to open its petals to the sun?  In your flowering you must surrender to the ravaging glare of the sun and find your true fulfilment."
		],
		equaliser: [
			"<em>Go on your knees like the figure in the image. Visualise light around and within you. Consciously let yourself relax—let go. Receive gladly what is given to you.</em>",
			"<em>Do a daily meditation for 21 days envisioning yourself as a flower opening to the sun.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Grasp, Glue, Stuck, Leech, Stick, Tenacious, Parasitical, Strangulate, Suffocate, Limpet, Clutch, Seize, Clinging Ivy, Inseparable, Indivisible, Siamese Twins, Merge, Consume, Possess, Own, Buy, Bought and Paid For, Mine, Belongs to Me, Stifled, Possession</em>",
			"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong> Clinging and Surrendering</strong>",
			"<em>Imagine a life without grasping—without the need to grasp, without the worry that you might lose or be lost or even miss out. Why do you cling? Is it out of necessity or fear? Is it sensible or is it folly to continually look for something to hold onto? What did you hold onto as you were born? What will you be able to hold onto as you die? Can you really expect to hold onto anything in this transient and ever-changing world? And when you fully surrender and relinquish possession of what you thought you owned you can find, perhaps, the full meaning of your life.</em>",
			"<em>At the moment of your greatest tension, when you find yourself holding on so tightly that your hands and your arms ache, you will find there is the greatest potential for your releasing, for your freedom and for the ties to break. Perhaps it is not a question of whether you cling or surrender, but what it is you cling to and what it is that can break.</em>"
		]
	},
	{
		id: 29,
		name: "The Path",
		subTitle: "The Way and Transformation Dualities",
		image: ThePath,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a curved path leading towards the horizon. The sun is full, the sky is clear, and the landscape is sharp and uncluttered. There is the hint of a forest to the left of the picture where we could possibly enter and get lost.</em>",
			"We need to be aware of our path, for it is this passage that gives us the confidence to take the next step. It shows us our course, timing, and our points of departure and arrival. Sometimes it is a well-known and well-trodden road and sometimes, exciting, new and unchartered ground. Sometimes we have something, someone to follow, but sometimes we are in the lead and are followed by others. Sometimes we have to walk alone.",
			"On the path we are shown whether we are journeying the right way, whether we are detoured or lost, whether we are in the clearing or in dense forest. How else can we know the difference between the wood and the trees? How else can we appreciate the sunrises and sunsets and the changing nuances of light? Here we are privileged to walk through seasons, discover shadows and darkening light.",
			"What we take with us is up to us. Some people are weighed down with excess baggage, some pull wagons, some carry backpacks and some have nothing at all. But learning what it is that you most need to carry will be a part of your joy."],
		equaliser: [
			"<em>Whose path are you following?</em>",
			"<em>How tired are you of the journey? What do you think you need to do at this time?</em>",
			"<em>How many paths are you capable of walking along at the moment?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Rite of Passage, Kissing Gate, Gate, Turnstile, Doorway, Opening, Closure, Barrier, Toll Bridge, Crossing, Entrance, Exit, Gate Crash, Threshold, Walk Right Through, Permit, Holder of the Key. Hand over the Key, Visa, Passport, The Ferryman, Double Lock, Security Check, The Gatekeeper, Bouncer, Barricade, Archway, Customs Control, Initiation</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Path and the Gateway</strong>",
			"<em>As you pause at the gateway, reflect on how you have come with your history while travelling on the path. There was a point of departure, a road, an arrival and a destination. Even if you are unconcerned where the path will lead you, you can be sure that you will have to move on, for no one and nothing can stay the same in eternal permanence. </em> ",
			"<em>The path and the gateway are necessary stages of this linear travel. And when you find yourself at the open or closed gate, it is here you will know just how far you have come. Here, you have the opportunity to stop and think, to wait and see and to reflect on the journey you have been on. Here you can decide whether to go forward or turn back, whether you will be allowed to even enter that passage which is still hidden and dark. Here you can ask yourself if, at this juncture, you are prepared to pay the price. </em>",
			"<em>It is here that many cry for Divine help, Divine guidance and Grace. It is here too that we can feel the most abandoned we have ever felt and entirely alone. This moment is no chance occurrence, but is part of the wisdom of it all. At this moment of potential initiation and transformation you can only enter on your own.</em>"
		]
	},
	{
		id: 30,
		name: "The Gateway",
		subTitle: "The Way and Transformation Dualities",
		image: Gateway,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a high wall with a gate in it. In the foreground there is the end of a path. The gate is closed, but a large key hangs near the lock. The horizon in the background looks bright, but there is no indication of what lies ahead.</em>",
			"So now you have arrived. Now you are ready. Now it is time. Do you enter or do you turn back? Will you be allowed to pass through the doorway? Can you dare to continue when you do not know what is before you? Do you feel excited or anxious in this pregnant moment of transition?",
			"The moment has come to decide whether you leave the landscape so familiar to you and risk passing into the unknown territory that lies ahead. Then again, perhaps you do not fear the actual transition as much as having to then live with your choices.",
			"And what if you find it is all a mistake and there is no turning back? What if you enter and the gate locks behind you and you find yourself trapped? So  it is worth taking the time to pause and consider deeply who and where you are now, and whether you feel ready to take the momentous mystery of this next step. Remember that you are as ready as you will ever be and really—what do you have to lose?"],
		equaliser: [
			"<em>You have just been told that the gate is locked and closed to you. If this news upsets you, what lengths are you prepared to go to open it?</em>",
			"<em>What is the rite of passage you are going through at this time? Create a meaningful ritual to honour and mark this time of change and transition.<em>",
			"<em>Why would you want to go back?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Traverse, Journey, Straight Line, Crooked Path, Left-Hand-Path, Rough Terrain, Dark Path, Walking in the Light, Crazy Paving, Smooth Surface, The Way, Uphill Struggle, Missing, Presumed Dead, The Track, Pathfinder, Bridle Path, Walking up the Aisle, Flight Path, Busy Thoroughfare, Cobbled Street, Walking on a Tightrope, Bridge, Slippery Surface, Falling off the Mountain, At the Steering Wheel, Being Driven, Back Seat Driver, The Path of Righteousness, The Path of Truth, The Path of Service</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>The Path and the Gateway</strong>",
			"<em>As you pause at the gateway, reflect on how you have come with your history while travelling on the path. There was a point of departure, a road, an arrival and a destination. Even if you are unconcerned where the path will lead you, you can be sure that you will have to move on, for no one and nothing can stay the same in eternal permanence. </em> ",
			"<em>The path and the gateway are necessary stages of this linear travel. And when you find yourself at the open or closed gate, it is here you will know just how far you have come. Here, you have the opportunity to stop and think, to wait and see and to reflect on the journey you have been on. Here you can decide whether to go forward or turn back, whether you will be allowed to even enter that passage which is still hidden and dark. Here you can ask yourself if, at this juncture, you are prepared to pay the price. </em>",
			"<em>It is here that many cry for Divine help, Divine guidance and Grace. It is here too that we can feel the most abandoned we have ever felt and entirely alone. This moment is no chance occurrence, but is part of the wisdom of it all. At this moment of potential initiation and transformation you can only enter on your own.</em>"
		]
	},
	{
		id: 31,
		name: "Decay",
		subTitle: "The Way and Transformation Dualities",
		image: Decay,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a once perfect and beautiful rose. The petals have begun to wither and blacken. The once faultless form is disintegrating. There is now no sense of vitality or life, only, of decay, dissolution and the beginnings of decomposition. The symbol is indicative of the transience of physical beauty and life, and of the process of ravaging which ultimately affects all in this world that takes the plunge and comes into being.</em>",
			"Decay is the shadow of ageing, the dark night of our heroic and hardy life. It continues faithfully from the moment of birth to long past the moment of death. Its promise manifests at the hour of our birth and finds its joyous zenith after our death. It is more resilient than both.",
			"How many times have you averted your eyes from rotting flesh, a maggoty carcass or a gangrenous wound? Perhaps it looked too ugly, too difficult for you to accept and to understand? And yet you could not really avoid the scene.  And even though you looked away there was the sweet, sickly, pungent smell of rotting form or flesh. How can you understand the worth of the seeming lifelessness of such a rotting heap—especially when it once had life, vitality, beauty and hope? How did its promise lead to this point, this dark state where no hope remains? Is this what you find hard to digest. What you would so quickly like to cover up? Is this why you hide and bury your dead and plant flowers on your graves to make them look pretty?"
		],
		equaliser: [
			"<em>Recall when you first encountered the process of decay. Try to remember your initial feelings about it and how it affected you. How is all of this symbolically relevant to you at the moment?</em>",
			"<em>Visualise how you imagine your body will be after it has died.  In your imagination observe the process of your body rotting away and finally crumbling into dust. Affirm that you are more than this body.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Re-Organize, Rebirth, Recreate, Born Anew, Re-Structured, Fertilised, Transformed, Alchemical, Chemical Reaction, Dust to Dust, Eternal Cycle, Compost Heap, Wheel of Samsara, Spiritual Rebirth, Ferment, Brew, Chrysalis, Perennial, Evergreen</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Decay and Regeneration</strong>",
			"<em>Oh, sweet flower, I sing praises to you! I watch you awaken as the sun rises and bask in its radiant brilliance. I stroke your petals tenderly and you in turn give to me so much of your delicate sweetness and honeyed perfume.As the sun sets, I watch you wither before my eyes. Perhaps you do not know what is happening to you, except that you must surrender to this ruthless law. As I watch you, I wish with all my heart that you do not have to leave me this way. It is too painful for me to be by your side, but neither can I bear to leave you. As I look at you shrivel up and wither, I feel that I am dying too. I see my own coming death. The days pass and I see your body rot. It begins to stink and become putrid. Where there was once firm beauty and an aromatic smell, there is now a slimy and pungent form. Where did my love go to—where are you now? I shut you away from my eyes and my heart because this disintegrating mass cannot be you. I burn as I watch you crumble, dust to dust. I hide from my despair and I try to not even think of you. I go about my business and try to forget what I have experienced, that I ever knew you. Yet in my heart there is something of you that eternally remains to stir me, to jar me so that I will never become the same again. I see you return to me again and again in different and ever-increasing forms. You tantalise me and seduce my mind until I know finally that there is no end, until I know that somehow, in some form, you and I will never be again as we were, but we will be born again. Knowing this is my most beloved and poignant pain.</em>"]
	},
	{
		id: 32,
		name: "Regeneration",
		subTitle: "The Way and Transformation Dualities",
		image: Regeneration,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a small rotting rose lying on the top of a compost heap. Steam can be seen arising from the mound. There is a sense of life and growth coming into form. Here we see the potential of re-creation through the changing of form and through the chemical process of the compost heap.</em>",
			"How can something as seemingly repellent as the lingering decomposition following death contain within it such inherent abundance? Perhaps this can help you to appreciate that nothing ever truly dies or goes to waste; that in fact, there is nothing which is not used again and again.",
			"Death and its decay may appear to be such a sacrifice but it is its form’s decomposition and regeneration that creates new life and in turn, creates new life forms. But then, perhaps it only appears strange to us when we view our world as split and fragmented. But if only we could see it as whole, interdependent, a unit, it would make more sense.",
			"Does it matter if one day I am the rose and the next the rotting form on the compost heap? Does it matter if I am the maggot eating away at the decaying flesh, or the vibrant healthy body which has not yet met its deadly fate? And if this is how my body moves and grows and is reborn—what of my soul and its formlessness?"],
		equaliser: [
			"<em>How quickly or slowly do you wish your body to be disposed of when you die? What about emotional and material deaths?</em>",
			"<em>Meditate alternatively on the idea of being the maggot and the rotting flesh as it was once vibrant and alive. What did it eat off?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Decompose, Rot, Disintegrate, Break Up, Fall Away, Eaten Away, Contaminated, Musty, Gangrenous, Suppurate, Thaw, Dissolve, Destruct, Maturate, Pass on, Dust, Maggoty, Putrefaction, Perish, Melt, Mouldy, Incubate</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Decay and Regeneration</strong>",
			"<em>Oh, sweet flower, I sing praises to you! I watch you awaken as the sun rises and bask in its radiant brilliance. I stroke your petals tenderly and you in turn give to me so much of your delicate sweetness and honeyed perfume.As the sun sets, I watch you wither before my eyes. Perhaps you do not know what is happening to you, except that you must surrender to this ruthless law. As I watch you, I wish with all my heart that you do not have to leave me this way. It is too painful for me to be by your side, but neither can I bear to leave you. As I look at you shrivel up and wither, I feel that I am dying too. I see my own coming death. The days pass and I see your body rot. It begins to stink and become putrid. Where there was once firm beauty and an aromatic smell, there is now a slimy and pungent form. Where did my love go to—where are you now? I shut you away from my eyes and my heart because this disintegrating mass cannot be you. I burn as I watch you crumble, dust to dust. I hide from my despair and I try to not even think of you. I go about my business and try to forget what I have experienced, that I ever knew you. Yet in my heart there is something of you that eternally remains to stir me, to jar me so that I will never become the same again. I see you return to me again and again in different and ever-increasing forms. You tantalise me and seduce my mind until I know finally that there is no end, until I know that somehow, in some form, you and I will never be again as we were, but we will be born again. Knowing this is my most beloved and poignant pain.</em>"]
	},
	{
		id: 33,
		name: "Stasis",
		subTitle: "The Way and Transformation Dualities",
		image: Stasis,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"],
		description: [
			"<em>The image is of a circular path. In the far distance the dark outline of a figure can be seen walking upon the path which leads nowhere. There are footprints on it as though indented through aeons of time. It is dusk and the sky is not clear, but there is enough light to see the path and the footprints.</em>",
			"Our apparent movement can blind us. Through the act of moving, of walking, we can fool ourselves into believing that we are indeed going forward, transforming, changing, whilst in reality, we are groping around in a seemingly endless, mindless circle.",
			"Are you blinded by your conditioning? Are you set on your path, unable to see the truth of where you are really going? Are your habits and conditioning so great that the very idea of transformation cannot penetrate the trodden path of your mind? You may say that you are not held or stuck in this way—but then prove how you are not.",
			"How many times have you heard of what to do and what not to do? Think of how ingrained your conditioned self-image might be. Remember the times you have been manipulated and coerced into acting in certain ways. How many times have you tried to please, even lied, until it became second nature to you. How many times have you acted as the real you?"],
		equaliser: [
			"<em>​How many times have you experienced profound change, either in your outer or your inner life? Does this change excite you or terrify you—or what?</em>",
			"<em>How do you feel you are on a treadmill and if so how can you get off? What do you feel this would involve and how willing are you to pay the price?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Transfiguration, Change of Form, Radical Change, Gone off the Straight and Narrow, Switched Paths, Structural Change, Evolution, Transform the Nature of, Complete Change, Butterfly, Leap Into the Void, Revolution, Involution, Profound Change, Not the Same Anymore, From an Ugly Duckling into a Swan</em>",
			"<strong> <em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],

		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Stasis and Metamorphosis</strong>",
			"<em>Staying the same does not mean not changing. But we can change without really changing. We can pay a certain lip service, change enough to get by, but deep down we know when we are pretending and when it’s a lie",
			"<em>Sometimes people look as though they have transformed. They even speak differently—sometimes even in another language. Sometimes they change their careers—look older and more mature, but inside they are no different than before. And why not? After all, do we really want to pay the price of a revolution, and experience a total change of heart? If we become too different, we will cease to know who we were and are. And anyway, do we really want to completely give up our deep attachments, our dreams, our fantasies and our hopes? The question is, are you willing to pay the price for any self-realisation?</em>",
			"<em>And even if your dreams are more like nightmares, you may find it easier to stay with what you know than what you don’t. The very idea of undergoing a metamorphosis can be too phenomenal and perhaps too dangerous for your own and others’ liking. Is it possible that this kind of transformation is sane? Is it really worth the price?</em>"
		]
	},
	{
		id: 34,
		name: "Metamorphosis",
		subTitle: "The Way and Transformation Dualities",
		image: Metamorphosis,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"],
		description: [
			"<em>The image is of a human being changing into a swan—or could it be an angel? The being is caught in the middle of the most profound change of its life. It is aware that its form is changing. It can see the process as it occurs, but can do nothing to stop it. The metamorphosis has begun and will continue until the transformation is complete.</em>",
			"It is the most radical change possible—a total change of form—and yet there is a sense of evolution. The metamorphosis has come from somewhere, even though the jump is so great that it is difficult to see that last crucial step which led it here. But now, there is the knowing that there can be no going back.",
			"When did you realise that you could not turn back, that you could no longer go on changing in gentle stages? Do you remember that point when you really did not recognise yourself anymore, when the world looked so different from before, when you were more changed than you could ever have imagined?",
			"One morning you awoke to find who you were had gone. Yes, the indications were there, but you did not recognise them. You could not put it all together, but now you understand. It must be the right time—it must be the correct hour."
		],
		equaliser: [
			"<em>How much do you yearn for this kind of transformation—why not go for it?</em>",
			"<em>Let the swan/angel talk to you about how it feels right now? Know that this is a deep and wonderful part of you communicating with you.</em>",
			"<strong>​Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Stagnant, Not moving, Stuck, Held, Uncertainty, Cease to Flow, Habitual, Sluggish, Fallen Unconscious, Dulled, Swamped, Conditioned Response, Chronic, Caught, Fixed, Rigid, Tight, Inflexible, Standing Still, Limbo, Held in the Balance, The Fall, Purgatory, Static, Imprisonment, Probation, Hardened Criminal, Incarceration, Detention, Held in Bondage, Bought and Paid For</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Stasis and Metamorphosis</strong>",
			"<em>Staying the same does not mean not changing. But we can change without really changing. We can pay a certain lip service, change enough to get by, but deep down we know when we are pretending and when it’s a lie",
			"<em>Sometimes people look as though they have transformed. They even speak differently—sometimes even in another language. Sometimes they change their careers—look older and more mature, but inside they are no different than before. And why not? After all, do we really want to pay the price of a revolution, and experience a total change of heart? If we become too different, we will cease to know who we were and are. And anyway, do we really want to completely give up our deep attachments, our dreams, our fantasies and our hopes? The question is, are you willing to pay the price for any self-realisation?</em>",
			"<em>And even if your dreams are more like nightmares, you may find it easier to stay with what you know than what you don’t. The very idea of undergoing a metamorphosis can be too phenomenal and perhaps too dangerous for your own and others’ liking. Is it possible that this kind of transformation is sane? Is it really worth the price?</em>"
		]
	},
	{
		id: 35,
		name: "Well-Being",
		subTitle: "The Way and Transformation Dualities",
		image: WellBeing,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of an abundant garden with fruit hanging from the trees. There is a river nearby with a gushing waterfall. Children are playing in the foreground. The sun is shining brightly. All looks happy and well. The scene shows the abundant and healthy life that we can experience and which is the aim of most human beings. There is the promise of vitality and growth in the sunshine.</em>",
			"It is good to feel well, contented, happy, peaceful and overflowing with vitality. It is good to be able to experience this precious time, to be able to recognise its beauty and to know how to make the most of its bounty. And when you do experience this abundance, it is good to share your blessings amongst your family, friends and associates, to rejoice with them, honouring your gifts and letting this full-time open your heart.",
			"It is your heart that will truly appreciate this wealth, vitality and well-being. Do not think of how or when it will end. Do not muddy the now with fearful thoughts, projections and anxieties of what may or may not be. Neither contaminate this precious time with smugness or complacency. Enjoy fully. Celebrate the rarity and share your happiness in every possible way."
		],
		equaliser: [
			"<em>Are you healthy and well at this moment? If so, how are you celebrating and honouring this precious time?</em>",
			"<em>How do you share your good fortune with others?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Tumour, Malady, Invalid, Distemper, Unhealthy, Sickness, Impaired, Handicapped, Malignant, Needing Medication, Crippled, Suffering, Mentally Ill, Insane, Pathogen, Congenital Illness, Fever, Symptoms, Inoculation, Contagious, Leper, Nervous Anxiety and Stress, Nervous Breakdown, No Hope, Terminally Ill, Chronic Fatigue, Slow and Painful Death, Lingering, In Constant Pain</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>",
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Well-Being and Disease</strong>",
			"<em>To be well is to feel content and comfortable with feelings of happiness, satisfaction, good fortune and ease. When you have well-being you are a joy to others and can give something positive to the world.</em>",
			"<em>The world likes us to be this way. The world needs us to take care of its stress. Our parents, family, work colleagues and friends are relieved and need us to be healthy and fit.</em>",
			"<em>This is “the right way” and how we are encouraged to live our lives, to live out the Big Dream—the path of well-being and happiness—the fairy-tale, happy-ever-after ending.</em>",
			"<em>But then, what do you do with the reality of sickness, ill-health and disease, which rarely passes us by? What do you do with your depression, your health crises and your emotional and mental pain? What do you do with the ever-present threat of heart disease, cancer, AIDS and other afflictions that are always hiding in the shadows and can confront you at any unexpected time? Where do you place your fears and anxieties about your health?</em>"
		]
	},
	{
		id: 36,
		name: "Disease",
		subTitle: "The Way and Transformation Dualities",
		image: Disease,
		introduction: [
			"The Way is the route along which we move, the passage, path, highway or method by which we travel. But on this journey, we invariably come to the crossroads, to points of momentous possibilities and potential where we can change our destiny, our life and being; where we can transform into something other than what we have been; something we may only have caught a glimpse of before.",
			"But, we need to be walking on the way, whether we wander or search, hide or rest, no matter whether we wear the garment of the traveller, the fool or the beggar. It does not matter how much we carry or how we dress. It is how we journey and the road we are on which takes us to the places of transition, transformation and rest.",
			"Our path is so unique and yet strangely similar to those of others we know. Sometimes we travel with them, but sometimes we have to go on alone. Sometimes we leave them behind and sometimes they leave us. But, there is always the promise of movement and change as our guiding star. We always face the unknown, and the chance to go forward or turn back. We may feel it is entirely out of our hands, but on such a journey, there actually is no going back. Can we really expect to be able to have full reign and manoeuvre over something which is so great and far-reaching?",
			"You may believe that your way may not necessarily be predestined, but how can you ever know? Maybe there is nothing that cannot be avoided. Nevertheless, sometimes you have to be able to believe in the possibility of your dreams coming true. And then there is always the unexpected, the Great Mystery which we can only guess at. With the continual possibility of free choice, fate, conscious action and the secret, your journey becomes all the more crucial and timelier where every moment counts"
		],
		description: [
			"<em>The image is of a once abundant garden, but now there is little sign of life. The trees have rotting fruit on them and they appear blackened with disease. The waterfall has dried up.</em>",
			"At what point did I become diseased—where and when did it begin? I wish I knew, for then I could catch this thing, this entity and destroy it instead of being destroyed by it.",
			"I was once so happy in my ignorance. I did not even have an inkling of you, I could not really appreciate what I had, until I met you, Shadow, you who have overcast me, but to whom I will not succumb. I did not know that you could be such an awakener and yet you are so dark and elusive. I can only clutch at the message of your pain, your sickliness and your weakness.",
			"And yet you have helped me to wake up as if from a long, deep sleep. You have helped me see the fragile significance of all that exists. You have helped me find more compassion for all. I know, that one day, everyone will experience your decided fate. It is your whisper that gives my soul its sense of urgency. I know now I will one day run out of time, that health and well-being cannot go on indefinitely, that even the dark time of meeting you will end."
		],
		equaliser: [
			"<em>What does disease look like to you? When you imagine this, send love to it.</em>",
			"<em>Think about any wishes or dreams, ideas or expectations you hold which may be diseased. What medicine do you feel they need?</em>",
			"<strong> Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Hale and Hearty, Contended, Peace of Mind, Well, Fit, Strong, Comfortable, Happy, Fortunate, Satisfactory, Agreeable, Well-Balanced, Eminently Sane, Vital Force, Vital Flow, Energetic, Athletic, Wholesome, Sprightly, Robust, Decontaminated, Free-of-Disease, Insurable, Young and Healthy, Resilient, Given the All Clear</em>",
			"<em>How do the words you have chosen interrelate with you? How do they merge with you and balance you?</em>",
			"<strong><em>After equalising who is the new being you are creating?</em></strong>"
		],
		completion: [
			"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
			"<strong>Well-Being and Disease</strong>",
			"<em>To be well is to feel content and comfortable with feelings of happiness, satisfaction, good fortune and ease. When you have well-being you are a joy to others and can give something positive to the world.</em>",
			"<em>The world likes us to be this way. The world needs us to take care of its stress. Our parents, family, work colleagues and friends are relieved and need us to be healthy and fit.</em>",
			"<em>This is “the right way” and how we are encouraged to live our lives, to live out the Big Dream—the path of well-being and happiness—the fairy-tale, happy-ever-after ending.</em>",
			"<em>But then, what do you do with the reality of sickness, ill-health and disease, which rarely passes us by? What do you do with your depression, your health crises and your emotional and mental pain? What do you do with the ever-present threat of heart disease, cancer, AIDS and other afflictions that are always hiding in the shadows and can confront you at any unexpected time? Where do you place your fears and anxieties about your health?</em>"
		]
	},
	{
		id: 37,
		name: "Om",
		subTitle: "Wholeness and the Void",
		image: Om,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a written Sanskrit OM enclosed in a circle with the sound spelt out phonetically around the edge. It is not enough to look at the symbol—You have to make the sound.</em>",
			"I dare you to sing me, for I am the sound of you! I am the vibration of all that is named in the Universe and more. So, do not read me—chant me, sing me and hear your voice, bringing into being the joy of Divine creation. Sound me and hear my force vibrating throughout your every cell.  Sound me and listen to me through the Divine ears of your Heart as I chant you into perfect form.",
			"Sing me and you will know who you are and where you came from. Hear me through your voice, through every living thing, hear my sound through every whisper of movement, every stillness of sound. Have I not always sung to you? Have we not always been one being—one sound, one extraordinary tremble in the greatness of the manifestation of form?"
		],
		equaliser: [
			"<em>Chant OM in as many variations of tone and pitch as you can.</em>",
			"<em>Are you ready to hear your own sound? Chant the sound of OM internally affirming that you are now ready!</em>",
			" <strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Sacred Sound, Reverberate, Vibrate, Frequency, Harmony, The Sound of Creation, Chant, Resonate, Mantra, Harmonics</em>",
			"<em>Cacophony, Out of Tune, Discordant, Jarring Sound, Blasting, Background Noises, Deafening Rabble, Violating Sound</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 38,
		name: "Divine Child",
		subTitle: "Wholeness and the Void",
		image: DivineChild,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a newly born child lying blissfully on the petals of a flower. The birth is Divine, in that it has not manifested through a physical body. The child is self-born.,I am that within you which is free and spontaneous, living and growing without the need of hidebound conventions, limiting habits and the bondage of suffocating care. Look at me and taste the wonderment of untarnished innocence, the sweetness of my purity and eternal delight.</em>",
			"I am that within you which is free and spontaneous, living and growing without the need of hidebound conventions, limiting habits and the bondage of suffocating care. Look at me and taste the wonderment of untarnished innocence, the sweetness of my purity and eternal delight.",
			"I will not physically mature, wither and die. I am continually alive and have no need to be reborn. I am free of regrets, open and inviting and with the taste of nectar on my tongue. I can meet you each day in playfulness and delight and create with you the very magic of newness, invention and surprise.",
			"You cannot pin me down and make me sane in your very intellectual way, you cannot place a uniform on me or institutionalise my Divine craziness. You cannot find me unless you become the same as me. But when we meet, I promise you that we will joyously play together in this wondrous Divine Play."
		],
		equaliser: [
			"<em>What does this Divine Child want from you right now? What gifts does it have for you?</em>",
			"<em>Allow wondrousness into your life now.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Divine Birth, Innocence, Purity, Spontaneity, Creativity, Magical, Joyful, Lotus-Born, Free Birth, Nativity, Genesis, Virginal Birth, Playful, Delightful, Creative Play, Free Play, Wanted Baby, Divine Play, Leela, Fun and Games, Self-Born, Cherub</em>",
			"<em>Aborted, Miscarriage, Unplanned Pregnancy, Illegitimate, Abandoned Baby, Abandoned at Birth, Abused Child, Traumatised at Birth, Premature Baby, Still Born, Damaged Foetus, Deformed Baby, Unwanted Baby</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 39,
		name: "The Three Of Life",
		subTitle: "Wholeness and the Void",
		image: TheThreeOfLife,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of an evergreen tree, abundant with an array of 12 different ripe fruits. There are four streams of water flowing from it carrying the life-force to the corners of the earth. The fruits represent the 12 astrological signs and their spiritual qualities of growth. Eating the fruit of the tree of life leads to immortality.</em>",
			"I am the never-ending source of life, the eternal manifestation of all that is life. I exist within all things and am untarnished by good and evil. I grow the fruits of the One. Know me, eat me, drink me and you will discover the vibrancy and animation of life. Partake of me in you and you will know no death. Acknowledge the flow of my waters and my eternal wish-fulfilment and you will realise the eternal river of abundance, life and consciousness.",
			"Enter me and feel your own divinely given abundance, the source of your existence and power. Touch my consciousness and let your awareness flow like streams of eternal clarity. Look beyond the knowledge and discoveries of your educated mind. Go beyond the limitations of logic and intellectual reasoning for I am nowhere but in the centre of your being and rooted in the evergreen and the cyclical growth of fruitful life."
		],
		equaliser: [
			"Visualise that you are The Tree of Life. Imagine a stream of conscious life flowing from you. Experience yourself as abundant and fruit laden. Allow yourself to give to you and all creation.",
			"<em>Imagine and choose 12 kinds of fruit on the tree and reflect on the spiritual qualities of each. What gifts are they giving to you now?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em> Life Force, Immortal, Consciousness, Evergreen, Fruit Laden, Bodhi Tree, Ancient Being, Branch, Streams of Life, As Strong as an Oak Tree, Strength-Giving, Wish-Fulfilling Tree, Hardwood, Seasoned Wood, Log, Protective Branches, Blossoming, Flowering, Bamboo Tree Weeping Willow, Rotten Timber, Rotten all the Way Through, Stump, Hanging Tree</em>",
			"<em>Infested with Termites, Crucifix, Dead Wood, Unseasoned, Weak Sapling, Twiglet, Fallen Tree, Hacked Down, Sawed Off, Wooden Coffin</em>",
			"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>",],
		completion: ["Nothing to display"]
	},
	{
		id: 40,
		name: "Urorobus",
		subTitle: "Wholeness and the Void",
		image: Urorobus,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a snake, encircled and eating its own tail. There is no break in the circle, indicating a continuous cyclic pattern of that which returns to its own beginning—the eternal cycle of disintegration and regeneration.</em>",
			"I am the beginning and the end in you, the totality of your existence. I require no support, no relief or aid in my ongoing existence of disintegration and re-emergence. I am the momentum of disintegration and its opposite. I crave for this collapse as I crave for the food of my renewal and re-absorption. I am the power that eternally drinks of itself and becomes anew.",
			"I am always beginning and ending, this primal source of your creation in form. I am your eternal latent power—the force that sustains you and regenerates you in all of your life’s potential and in all that has yet to come into form. I am I in you that can transcend the relative truth of the moment, the fragility of the impermanent second in time, the illusion of death and the illusion of birth.",
			"I in you eat your allotted time and I am held in that vast continuum of space, that vacuum of timelessness. Connect with me and know the power of your revival and reconstruction—a power within you that you can never control or misuse. Connect with me and know that, no matter what happens, you and I together create your eternal movement of the beginnings and endings of your fate in time and space."
		],
		equaliser: [
			"<em>Visualise that you are the snake eating its own tail. What do you feel you are eating and what is it that is being nourished and reborn?</em>",
			"<em>Where in your body would you like to place this symbol and Why?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Self-Nourishing, Absorption, Digest, Self-Sustaining, Independent Life, Regeneration, Re-Integration, Eternal Wheel, Eternal Cycle, Kundalini, Life Force, Coiler, Constrictor, Primordial, Never Ending</em>",
			"<em>Venomous, Poisonous, Starved, Unable to Digest, No Stomach for It, Death,<em>",
			"<em>No Life Force Remaining, Snake Bite, Lethal Dose, Dependant<em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating? </em></strong>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 41,
		name: "The Lotus",
		subTitle: "Wholeness and the Void",
		image: TheLotus,
		introduction: [
			"<em>Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace. </em>",
			"<em>But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.</em>",
			"<em>And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.</em>",
			"<em>From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before.</em>"
		],
		description: [
			"<em>The image is of a white lotus with a hint of pink on the edges of its petals. The lotus is resting quietly on clear water. There is an air of stillness and serenity as the lotus displays its immaculate beauty. The touch of pink is indicative of the warmth of the heart. One cannot see the mud beneath the surface from which the lotus has raised itself.</em>",
			"I am the Divine Love in you, transcended—your ultimate Compassion. I am the Love that emerges from the depths of your being and I am unsullied, pure, and the manifestation of your enlightened heart. Look to me and absorb me and know the perfected being you are. Look to me and see who you truly are, beyond the murky emotional base of your material existence, and its desires and wants. Look to me and realise how, in your spiritual unfoldment, you will find within its centre your eternal grace and beauty and your Divine Heart.",
			"How can you grieve when this truth is here? How can you hurt and feel bitter or be afraid? How can you envy another when you can see the unfolding love of your own heart? It must happen sometime. Even you cannot stop your eventual rising, flowering and opening to the light. You cannot stop the softness of your petals quivering in the sun. Just let it be. Feel and be vulnerable, and you will find your strength."
		],
		equaliser: [
			"<em>Imagine yourself rooted in the mud and slime under the water and emerging as the lotus flowering in the light of the sun. Do this as a daily meditation until you know that you and the lotus are one.</em>",
			"<em>Daily paint the lotus and let this practice heal you.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Compassion, Unfolding, Developing, Flower, Bud, Chakra Petals, Perfection, Stillness, Nectar, Water Lily, The Time of Flowering, Rising Above, Reaching Up, Heart Opening, Thousand-Petalled-Lotus, Blossoming, Unconditional Love</em>",
			"<em>Dirty, Stuck in the Mire, Muck, Unable to Open, Shrivelled, Conditional Love, Swamped, Undeveloped, Trampled On, Withered, Tarnished, Closed Chakras, Sullied</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"],
		completion: ["Nothing to display"]
	},
	{
		id: 42,
		name: "The Alchemical Marriage",
		subTitle: "Wholeness and the Void",
		image: TheAlchemicalMarriage,
		introduction: [
			"<em>Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace. </em>",
			"<em>But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.</em>",
			"<em>And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.</em>",
			"<em>From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before.</em>"
		],
		description: [
			"<em>The image is of a round glass vase with an androgynous figure contained within it standing in violet waters. This is symbolic of the chemical fusion of the male and female principles of form and their transformation.</em>",
			"I am that in you which is whole and not yet separated. I am that within you which has been separated and is now whole, transformed by the inner merging and union of what you thought was opposite to you. I cannot be found in your outer realm and you cannot capture or conjure me up through bonding with an outer mate.  It is only within the depths of your being that you can find and know me, where you can satisfy your love and your desire for union.",
			"It is only here, that I, the King and the Queen, the father and mother, the merging of two lovers, reside. Find me, connect with me, and you will know what it is to leave the strain and the struggle behind—your longing for completeness and the despair of feeling unmet by any partner in your outer world. Claim me and know what it is to end that fruitless desire to belong to any outer form, to that which has eluded you and which you have not yet managed to hold on to with any permanence. Find me, and for just a second, feel complete and at one."
		],
		equaliser: [
			"<em>Consider what feels complete in you. When you find it, celebrate and honour it.</em>",
			"<em>Imagine that you are the symbol moving in the swaying chemical waters. Who is your partner?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Alchemist, Conjunction, Conciliation, Androgyny, Union, Chemical Fusion, Melting Pot, Bride and Groom, King and Queen, Husband and Wife, Marriage, Union Blessed by God, Wedding Band, Transmutation, Sublimation, Tantric Union, Wholeness, One with God </em>",
			"<em>Separate, Individual, Partnerless, Incomplete, Jilted at the Altar, Individuate, Looking for a Mate, Artificial Gold, Disconnected, Concubine, Gigolo, Pure, Spinster, Bachelor</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em> </strong>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 43,
		name: "The Cosmic Joke",
		subTitle: "Wholeness and the Void",
		image: TheCosmicJoke,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a clown’s face appearing through the starry night sky. The clown is laughing and the finger is pointing at you.</em>",
			"Congratulations—you’ve woken up. I was wondering when you would realise how funny this all is. When did you begin to realise that it’s not at all as serious as you thought? I mean, it’s really funny, isn’t it? It’s really funny that you are here at all—that you are even playing this game. Ha!",
			"I’ve been waiting and watching you to see when you would get the punch line. I have been waiting to see your surprise when you realised what it was all about, when you saw through the trickery, the fantasy and found the truth. And all this time, you thought that it was something different. You thought it was all so relevant–isn’t that so funny? And what about the timing—wasn’t I superb? You could not have worked out such perfect timing even if you had forever to plan it. Doesn’t it amaze you just how bizarrely perfect it all is?",
			"Now you see clearly the madness and all the running around for nothing. All that searching and seeking for something that does not even exist—what a gag! Yes, you have been running around in circles…  Now you know that you don’t have to take it all so seriously, you can relax and let go. Phew! What a relief! You know now that you do not need to take it all so personally because it’s THE COSMIC JOKE!",
		],
		equaliser: [
			"<em>Think of the times when you have felt set-up. Who do you think the cosmic joker really is?</em>",
			"<em>Remember the last time you felt a serious situation was very funny. What did you let go of in order to feel this?</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Fait Accompli, Tickled Pink, Divine Craziness, Belly Laugh, Universally Funny, Trickster, Set Up, Get a Custard Pie Thrown at You, Perfect Timing, The Finger Is Pointing at You, Clowning Around, One-Liner, Secret Joke, Mimic, Ludicrous, Die Laughing, Ha Ha</em>",
			"<em>No Laughing Matter, Taking Things Seriously, Solemn, Grave, Keep a Straight Face, It’s Not Funny, Deathly Joke, Die Laughing, Why Are You Laughing? Humourless, You’re No Fun to Be with, All Work and No Play Makes Jack a Dull Boy, Dead Pan</em>",
			"<em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 44,
		name: "Enlightenment",
		subTitle: "Wholeness and the Void",
		image: Enlightenment,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a golden Buddha sitting in the lotus position. There is a quality of silence, of stillness and openness. The background sky is blue and there are fast-moving clouds passing by. The image depicts the wisdom, equanimity, poise and quietness of our inherently sublime nature.</em>",
			"I am you who realises the fullness of everything beyond the limited conceptions of your mind. I am not agitated, pushed and pulled by external or internal discordant sounds or movements—I am peace and am complete in myself. I have no need of anyone or anything but feel connected to the oneness and joyousness of all.",
			"Connect with me and surrender the trappings of your illusory mind and see Truth shining brilliantly within the myriad manifestations of life. Realise that you are free to Be as Divinity’s Beloved Child—unattached, blissful and content in any role, action and play.",
			"In the full stillness of this moment, let your breath inhale, relax and exhale. Enjoy your breathing and your life without the need to fret and think. Let the hustle and bustle surrounding you be as it is. There is no need for you to waver because you are Free.",
		],
		equaliser: [
			"<em>Stop worrying, fretting and trying to make things work. Don’t think about it. Enjoy the fullness of the moment. Sit like the Buddha figure in the image. Stay with the present moment as much as possible. If thoughts, memories, images or feelings arise, allow them to be—neither push them away nor attempt to follow them, no matter how magical or enticing they appear. Remain in the stillness for a pre-arranged period of time. If it feels good, attempt to do this for 20-30 minutes each day as part of your daily routine.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Contemplative, Meditate, Consciousness, Inner Journey, Freedom from The Mind, Blissful, Self-Realised, Awakened, Heart Wisdom, Divine Intelligence, Equanimity, Detached, Discerning, Sat-Chit-Ananda, Inner Silence, Vision Quest</em>",
			"<em>Inanimate, Restrained, Book Knowledge, Ignorant, Disturbed, Crazy, Can’t Stay Still, Agitated, Out of Balance, Confused, Easily Led, Caught By The Mind, Unconscious, Driven</em>",
			"<em>How do the words you have chosen interrelate with you? How do they merge with you and balance you?</em>",
			"<em>After equalising who is the new being you are creating?</em>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 45,
		name: "The Cosmic Dance",
		subTitle: "Wholeness and the Void",
		image: TheCosmicDance,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of a golden feminine figure moving joyously in dance in the spaciousness of the sky.</em>",
			"<em>I am you who dances in ecstatic abandonment with the perfect momentum of Creation. My partner is the Cosmos and I dance in complete harmony with all. As I move I connect to and interrelate with every atom and universal cell and vibrate according to perfect Divine Timing. I disintegrate and manifest, am affected and transformed in utter rapport with everything and everyone that was, is and is yet to come into form.  As I twirl and whirl, I am mindless, but I am at one with the innate Divine Intelligence of all.</em>",
			"I am a key to your increasing loosening and detachment from that which has to go. One day you will meet me in freedom and you will recognise how the Dance began. My Divine movement is your transformation. Mindfully centre yourself, focus, move yourself and let go…"
		],
		equaliser: [
			"<em>Dance in abandonment and in awareness.</em>",
			"<em>In meditation, visualise every particle of Creation dancing in a colourful array. Imagine yourself in the centre in unity with all.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>The Dance of Shiva, Nataraj, Momentum, Sun Dance, Sacred Dance, Ballet, Twirl, Sufi Whirling, Earth Dance, The Four Directions Dance, Veil Dancing, Dance with Abandonment, Belly Dance, Waltz, Solo Dance, Fire Dance, Sway, Sky Dancer</em>",
			"<em>Stationary, Legless, I can’t Dance, People are Watching Me, Self-Conscious, Inhibited, Not Allowed to Dance, Tiptoe, Crawl, Immobile, Prowl, Shuffle, Meander, I Need a Choreographer, Ungrounded</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
		],
		completion: ["Nothing to display"]
	},
	{
		id: 46,
		name: "The Void",
		subTitle: "Wholeness and the Void",
		image: TheVoid,
		introduction: [
			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
		],
		description: [
			"<em>The image is of an empty black space.</em>",
			"I am the sacred nothing in you from which you came and which you will re-enter again and again. I am the pregnant emptiness in time that manifests and emerges into dimensions and forms, into the eternal cycle of endlessness. I am the circle before it began, the seed before the moment of conception, before any form.",
			"I am the nothingness on which you really stand—your true empty ground. I am the one you do not wish to know or enter, the You that you distort with your mind and even attempt to deny its very existence. Like a genie, you keep me bottled and corked in your illusionary world, and in your imagination you think that this saves you from the fall. But I am the one in you that will fly free from any entrancing entrapment and one day will make you disappear into The Void."
		],
		equaliser: [
			"<em>Relax, let go and discover what it feels like to let yourself dissolve into the nothingness of no-thing.</em>",
			"<em>Look at the black space and enter into its centre.</em>",
			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
			"<em>Black Space, Mysterious, The Unmanifested, Not yet Come into Form, Empty Circle, The Unlimited, The Incomprehensible, Before Creation Began, The Emptiness of Everything, The Absolute, Unlimited Potential, Nothingness, Expecting</em>",
			"<em>Null and Void, Useless, Cluttered, Limited, Black Hole, Nothing There, Zero, No Space to Create, Where There Is No Hope, The Dark Night of the Soul, When You Die There Is Nothing</em>",
			"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>",
		],
		completion: ["Nothing to display"]
	},
]

