import React from 'react';
import './ShowChakra.scss';
import {animated, useTransition} from "react-spring";
import {useStoreActions, useStoreState} from "easy-peasy";

const ShowChakra = (props) => {

	const {play: {showChakraImage}} = useStoreState((state => state));

	const {play: {updateShowChakraImage}} = useStoreActions((actions => actions));

	const fadeIn = useTransition(!showChakraImage, null, {
		from: {
			opacity: 0,
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0,
		},
		// order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	})

	return (

		fadeIn.map(({item, props: animation, key}) =>
			!item && <animated.div className="show-chakra" style={animation} key={key}>
				<div className="show-chakra__btn-container ">
					<button className="show-chakra__btn" onClick={() => {
						updateShowChakraImage(false);
					}}>
						go back
					</button>
				</div>

			</animated.div>
		)
	);
}

export default ShowChakra;
