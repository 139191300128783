import React, {useEffect, useRef, useState} from 'react';
import './Header.scss'
import logoPng from '../../Assets/Main/Logo.png';
import {useStoreState} from "easy-peasy";
import {NavLink, useHistory, useLocation} from "react-router-dom";
import {animated, useSpring} from "react-spring";

const array = ["/play"]
const completeArray = [...array, "/reading", "/about"]
const Header = () => {
	const {home: {detailsAnimation, cardAnimation}} = useStoreState(state => state)
	const location = useLocation();

	const [coords, setCoords] = useState({});
	const playRef = useRef();
	const readRef = useRef();
	const aboutRef = useRef();

	useEffect(() => {
		if (playRef.current && readRef.current && aboutRef.current) {

			const {x: playX, y: playY} = playRef.current.getBoundingClientRect();
			const {x: readX} = readRef.current.getBoundingClientRect();
			const {x: aboutX} = aboutRef.current.getBoundingClientRect();
			setCoords({
				...{
					playX, playY, readX, aboutX
				}
			})
			return () => {
				playRef.current = false;
				readRef.current = false;
				aboutRef.current = false;

			}
		}
	}, [playRef, readRef, aboutRef, cardAnimation, detailsAnimation])


	const column = {
		top: `${coords.playY + 20}px`,
		left: array.includes(location.pathname) ?
			`${coords.playX}px` : location.pathname.includes('/reading') ?
				`${coords.readX}px` : location.pathname.includes('/about') ? `${coords.aboutX}px` : '0px',
		display: completeArray.includes(location.pathname) ? "block" : "none",
		opacity: '1'
	}


	const spring = coords.playX && location.pathname !== '/' ? {
		from: {...column},
		to: {...column}
	} : {
		to: {
			display: "none",
			opacity: '0',
			top: `${coords.playY ? coords.playY + 20 : 0}px`,
			left: `${coords.playX ? coords.playX : 0}px`
		}
	}


	const navSlideAnimation = useSpring({...spring})

	const history = useHistory();

	return (
		<header className="header card-animation">

			{
				((cardAnimation && detailsAnimation) || location.pathname !== '/') &&
				<>
					{/*<div className="header__logo-container">*/}
					{/*	<img src={logo} alt="Logo for divine play" className="header__logo"/>*/}
					{/*	<p className="header__logo-text">Divine Play</p>*/}
					{/*</div>*/}

					<NavLink to={"/"} onClick={() => {
						history.push("/");
						window.location.reload();
					}}>
						<img src={logoPng}
							 alt="Logo for divine play"
							 className="header__logo"/>
					</NavLink>

					<nav className="nav">

						<NavLink className="nav__link" target="_blank" ref={playRef} to={"/"}>
							HOME
						</NavLink>

						<NavLink className="nav__link" target="_blank" ref={playRef} to={"/play"}>
							PLAY/ GET READING
						</NavLink>

						<NavLink className={`nav__link ${location.pathname === "/" ? "nav__link-disabled" : ""}`}
								 target="_blank"
								 onClick={(event) => {
									 if (location.pathname === "/") {
										 event.preventDefault()
									 }
								 }}
								 ref={readRef} to={"/reading"}>
							HOW TO WORK WITH YOUR READING
						</NavLink>

						<NavLink className={`nav__link ${location.pathname === "/" ? "nav__link-disabled" : ""}`}
								 ref={aboutRef} to={"/about"}
								  target="_blank"
								 onClick={(event) => {
									 if (location.pathname === "/") {
										 event.preventDefault()
									 }
								 }}
						>
							ABOUT DIVINE PLAY
						</NavLink>

						<a href="https://mynavati.com/" className="nav__link" rel="noopener noreferrer"
						   target="_blank"
						>BACK TO MYNAVATI.COM</a>
						<animated.div style={navSlideAnimation} className="nav__slide"/>
					</nav>
				</>
			}
		</header>
	);
}

export default Header;
