import home from './HomeModel';
import play from './PlayModel';

const storeModel = {
	home,
	play

}

export default storeModel;
