import React, {useEffect} from 'react';
import "./DualityThreeCard.scss"
import {animated, useSpring, useSprings} from "react-spring";
import CardDeck from "../../../UI/CardDeck/CardDeck";
import {useStoreActions, useStoreState} from "easy-peasy";

const cards = [
	{
		label: "Card 1, Past",
		x: 10
	},
	{
		label: "Card 2, Present",
		x: 50
	},
	{
		label: "Card 3, Future",
		x: 100
	}
];


const DualityThreeCard = ({animation, ...props}) => {

	const {play: {dualityThreeCardAnimationOver}} = useStoreState(state => state);
	const {play: {updateDualityShuffleScreen}} = useStoreActions(actions => actions);

	useEffect(() => {
		if (dualityThreeCardAnimationOver) {
			const timer = setTimeout(() => {
				updateDualityShuffleScreen(true);
			}, 1000)

			return () => {
				clearTimeout(timer)
			}
		}
	}, [dualityThreeCardAnimationOver, updateDualityShuffleScreen])


	const cardAnimation = useSprings(cards.length, cards.map((element) => {
			return {
				from: {
					transform: `translate(0px, ${element.x}px) scale(1) rotateX(0)`,
				}, to: {
					transform: `translate(0px, 0px) scale(1) rotateX(0)`,
				},
				config: {duration: 1000},

			}
		})
	)

	const fadeIn = useSpring({
		from: {
			opacity: 1
		},
		to: {
			opacity: dualityThreeCardAnimationOver ? 0 : 1,
			display:  dualityThreeCardAnimationOver ? "none" : "grid",
		},
		config: {duration: 500},
	});

	return (
		<animated.div className="three" style={dualityThreeCardAnimationOver ? {...fadeIn} : {...animation}}>
			<div className="three__deck">
				{
					cardAnimation.map((animation, index) =>
						<animated.div style={animation} key={index}>
							<CardDeck width={"9rem"} height={"15rem"} radius={"1.3em"} offset={20} number={1} disableAnimation={true}/>
							<p className="three__label">{cards[index].label}</p>
						</animated.div>
					)
				}
			</div>

			<animated.div className="three__details" style={animation}>
				<p className="three__title">What do the three Duality and Wholeness Cards Represent?</p>

				<p className="three__subtitle">Card 1, Past</p>
				<p className="three__description">The first <strong>Duality or Wholeness Card</strong> you choose symbolises the psycho-spiritual
					energies that you
					have recently left, the role you have been playing and which has a bearing on the present.</p>

				<p className="three__subtitle">Card 2, Present</p>
				<p className="three__description">The second card you choose symbolises your energies in the present moment. This card shows you what
					you are identified with right now, unconsciously or consciously and what is making you feel, think and react as you do. Your
					understanding and integration of the meaning of this card, gives you the key to making conscious, positive choices in your
					future.</p>

				<p className="three__subtitle">Card 3, Future</p>
				<p className="three__description">The third card is the final outcome to your question. This card inspires you to make positive
					choices for <strong>HEAL</strong>ing, happiness and awareness. It is related to your perceptions of the past and present <strong>
						Dualities </strong>or <strong>Wholeness Cards</strong> (first and second card) and your integration of them. This is the card
					which heralds the possibilities and choices of your HEALing.</p>

			</animated.div>
		</animated.div>
	);
}


export default DualityThreeCard;
