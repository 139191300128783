import React, {useCallback, useEffect, useState} from 'react';
import {animated, useSprings} from "react-spring";
import {useStoreActions, useStoreState} from 'easy-peasy';
import {useHistory} from "react-router-dom";

import HomeDetails from "./HomeDetails/HomeDetails";
import './Home.scss';
import fire from '../../Assets/Elements/Fire.png'
import water from '../../Assets/Elements/Water.png'
import air from '../../Assets/Elements/Air.png'
import earth from '../../Assets/Elements/Earth.png'
import woman from '../../Assets/Duality/FeminePsychicModeOfExpression.png';
import mandala from '../../Assets/Positivity/Mandala.png';
import masc from '../../Assets/Duality/MasuclinePsychicModeOfExpression.png';
import redChakra from '../../Assets/Colors/red.png';
import shadowBall from '../../Assets/ShadowBall.png';
import sorceress from '../../Assets/Duality/TheHighPriestess.png';
import Toast from "../UI/Toast/Toast";


export default React.memo((props) => {


	const negativePosition = -40;
	const positivePosition = 40;

	const ElementObject = [
		{
			id: 0,
			name: "Fire",
			xy: [negativePosition, negativePosition],
			image: fire,

		}, {
			id: 1,
			name: "Air",
			xy: [positivePosition, negativePosition],
			image: air
		},
		{
			id: 2,
			name: "Water",
			xy: [negativePosition, positivePosition],
			image: water
		},
		{
			id: 3,
			name: "Earth",
			xy: [positivePosition, positivePosition],
			image: earth
		}
	]

	const center = 15 * 16;
	const xCords = [-20, 190, 420];
	const yCords = [40, 280];

	const xCordsTablet = [-30, 150, 350];
	const yCordsTablet = [20, 200];

	const CollapsingCardObject = [
		{
			id: 0,
			name: "Red Chakra",
			xy: [-10, center - 50],
			xyDetails: [xCords[0], yCords[1]],
			rotate: 8,
			rotateInitial: 0,
			image: redChakra,
			zIndex: 2

		}, {
			id: 1,
			name: "Masculine Psychic Mode of Expression",
			xy: [80, center - 100],
			xyDetails: [xCords[0], yCords[0]],

			rotate: -9,
			rotateInitial: -3,
			image: masc,
			zIndex: 3

		},
		{
			id: 2,
			name: "The Sorceress",
			xy: [300, center - 150],
			// xyDetails: [xCords[1], yCords[0]],
			xyDetails: [xCords[2], yCords[0]],

			rotate: -10,
			rotateInitial: -20,
			image: sorceress,
			zIndex: 2.1
		},
		{
			id: 3,
			name: "Feminine Psychic Mode of Expression",
			xy: [240, center - 50],
			// xyDetails: [xCords[1], yCords[1]],
			xyDetails: [xCords[1], yCords[0]],
			rotateInitial: 3,
			rotate: 14,
			image: woman,
			zIndex: 2.8
		},
		{
			id: 4,
			name: "Shadow Ball",
			xy: [160, center - 40],
			// xyDetails: [xCords[2], yCords[0]],

			xyDetails: [xCords[1], yCords[1]],

			rotate: 4,
			rotateInitial: 9,
			image: shadowBall,
			zIndex: 4
		},
		{
			id: 5,
			name: "A Circular Mandala",
			xyDetails: [xCords[2], yCords[1]],
			xy: [400, center - 110],
			rotateInitial: -5,
			rotate: -15,
			image: mandala,
			zIndex: 3
		}
	]

	const CollapsingCardObjectTablet = [
		{
			id: 0,
			name: "Red Chakra",
			xy: [-10, center - 100],
			xyDetails: [xCordsTablet[0], yCordsTablet[1]],
			rotate: 8,
			rotateInitial: 0,
			image: redChakra,
			zIndex: 2

		}, {
			id: 1,
			name: "Masculine Psychic Mode of Expression",
			xy: [50, center - 150],
			xyDetails: [xCordsTablet[0], yCordsTablet[0]],

			rotate: -9,
			rotateInitial: -3,
			image: masc,
			zIndex: 3

		},
		{
			id: 2,
			name: "The Sorceress",
			xy: [250, center - 200],
			// xyDetails: [xCords[1], yCords[0]],
			xyDetails: [xCordsTablet[2], yCordsTablet[0]],

			rotate: -10,
			rotateInitial: -20,
			image: sorceress,
			zIndex: 2.1
		},
		{
			id: 3,
			name: "Feminine Psychic Mode of Expression",
			xy: [170, center - 100],
			// xyDetails: [xCords[1], yCords[1]],
			xyDetails: [xCordsTablet[1], yCordsTablet[0]],
			rotateInitial: 3,
			rotate: 14,
			image: woman,
			zIndex: 2.8
		},
		{
			id: 4,
			name: "Shadow Ball",
			xy: [120, center - 90],
			// xyDetails: [xCords[2], yCords[0]],

			xyDetails: [xCordsTablet[1], yCordsTablet[1]],

			rotate: 4,
			rotateInitial: 9,
			image: shadowBall,
			zIndex: 4
		},
		{
			id: 5,
			name: "A Circular Mandala",
			xyDetails: [xCordsTablet[2], yCordsTablet[1]],
			xy: [300, center - 100],
			rotateInitial: -5,
			rotate: -15,
			image: mandala,
			zIndex: 3
		}
	]

	const {play: {resetPlay}} = useStoreActions((actions => actions));

	useEffect(() => {
		resetPlay();
	}, [resetPlay]);

	const elementCardAnimation = useSprings(ElementObject.length, ElementObject.map((element) => {
			return {
				from: {
					transform: `translate(${element.xy[0]}px, ${element.xy[1]}px)`,
				}, to: {
					transform: `translate(0px, 0px)`,
				},
				config: {duration: 1000},
			}
		})
	);


	const [detailsActive, setDetailsActive] = useState(false);
	const [detailsActiveText, setDetailsActiveTest] = useState(false);

	const [detailsToggle, setDetailsToggle] = useState(false);
	const {home: {cardAnimation}} = useStoreState(state => state)

	const {home: {updateCardAnimation, updateDetailsAnimation}} = useStoreActions(actions => actions)


	useEffect(() => {
		const timer = setTimeout(() => {
			updateCardAnimation(true);
		}, (500 * 6) + 3000)

		return () => {
			clearTimeout(timer);
		}
	}, [updateCardAnimation])

	const [isTablet, setIsTablet] = useState(false);

	useEffect(() => {
		setIsTablet(window.innerWidth < 1150);

		function handleResize() {
			setIsTablet(window.innerWidth < 1150);
		}

		window.addEventListener("resize", handleResize);
		return () => window.removeEventListener("resize", handleResize);
	}, [setIsTablet])




	useEffect(() => {
		return () => {
			updateCardAnimation(false);
			updateDetailsAnimation(false);
		};
	}, []);


	const switchRotateAnimation = useCallback(() => {
		setDetailsActiveTest(value => !value)
		setDetailsToggle(true);
		setDetailsActive((value) => !value);
	}, [setDetailsActiveTest, setDetailsToggle, setDetailsActive]);


	let mainObject = {};

	mainObject = isTablet ? [...CollapsingCardObjectTablet] : [...CollapsingCardObject];
	const history = useHistory();
	const collapseCardAnimation = useSprings(mainObject.length,
		mainObject.map((element) => {
			return {
				from: {
					position: 'absolute',
					left: `${!detailsActive ? element.xy[0] : element.xyDetails[0]}px`,
					top: `${!detailsActive ? element.xy[1] : element.xyDetails[1]}px`,
					transform: `scale(2.3)  translate(0, 0px) rotateZ(${!detailsActive ? element.rotateInitial : 0}deg)`,
					opacity: 0,
					zIndex: element.zIndex
				},
				to: {
					left: `${!detailsActive ? element.xy[0] : element.xyDetails[0]}px`,
					top: `${!detailsActive ? element.xy[1] : element.xyDetails[1]}px`,
					transform: `scale(${1}) translate(0, 0px) rotateZ(${!detailsActive ? element.rotateInitial : 0}deg)`,
					opacity: 1
				},
				config: {duration: 500},
				delay: !detailsActive && !detailsToggle ? element.id ? 1000 + (800 * element.id) : 1000 : 0
			}
		}))


	return (
		<div className="home">
			{cardAnimation && <div className="home__details">
				<HomeDetails detailsActive={detailsActiveText} detailsToggle={detailsToggle}/>

				<div className="d-flex  h-100 justify-content-between" style={!isTablet ? {width: "40%"} : {width: "75%"}}>
					<div className={`${!isTablet ? "w-40" : "w-40"}`}>
						<button type="button"
								className="btn "
								onClick={async () => {
									await switchRotateAnimation()
								}}>Read More
						</button>
					</div>


					<div className={`${!isTablet ? "w-40" : "w-40"}`}>
						<button type="button" className="btn"
								onClick={() => {
									history.push('/play')
								}}>
							Lets Begin
						</button>
					</div>
				</div>

			</div>}

			<div className="home__animation">
				{
					elementCardAnimation.map((animation, index) => {
						return (<animated.img src={ElementObject[index].image}
											  style={{...animation}}
											  key={ElementObject[index].id}
											  alt={`${ElementObject[index].name} Element Card of Divine Play`}
											  className="home__element-card "/>)
					})
				}
				{
					collapseCardAnimation.map((animation, index) => {
						return (<CollapsingCard
							image={CollapsingCardObject[index].image}
							animation={{...animation}}
							name={CollapsingCardObject[index].name}
							key={CollapsingCardObject[index].id}
						/>)
					})
				}
			</div>

		</div>
	)
});


const CollapsingCard = ({animation, image, name}) => {

	return (
		<animated.div style={{
			...animation,
			backgroundImage: `url(${image})`
		}} className="collapsing__card"/>
	)
}


