import PrimordialSound from "../Assets/Positivity/PrimordialSound.png"
import Compassion from "../Assets/Positivity/Compassion.png"
import Meditation from "../Assets/Positivity/Meditation.png"
import Affirmation from "../Assets/Positivity/Affirmation.png"
import ChantingForFreedomFromFearAndBandage from "../Assets/Positivity/ChantingForFreedomFromFearAndBandage.png"
import Silence from "../Assets/Positivity/Silence.png"
import Prayer from "../Assets/Positivity/Prayer.png"
import Movement from "../Assets/Positivity/Movement.png"
import Expression from "../Assets/Positivity/Expression.png"
import Mandala from "../Assets/Positivity/Mandala.png"
import GivingAndReceiving from "../Assets/Positivity/GivingAndReceiving.png"
import Gratitude from "../Assets/Positivity/Gratitude.png"
import KeepingGoodCompany from "../Assets/Positivity/KeepingGoodCompany.png"
import Surrender from "../Assets/Positivity/Surrender.png"
import Smile from "../Assets/Positivity/Smile.png"
import LettingGoOfAddiction from "../Assets/Positivity/LettingGoOfAddiction.png"
import SelfWorth from "../Assets/Positivity/SelfWorth.png"
import AcceptingYourDarkness from "../Assets/Positivity/AcceptingYourDarkness.png"

export default [
	{
		id: 1,
		image: PrimordialSound,
		name: "Primordial Sound"
	},
	{
		id: 2,
		image: Compassion,
		name: "Compassion"
	},
	{
		id: 3,
		image: Meditation,
		name: "Meditation"
	},
	{
		id: 4,
		image: Affirmation,
		name: "Affirmation"
	},
	{
		id: 5,
		image: ChantingForFreedomFromFearAndBandage,
		name: "Chanting for Freedom from Fear and Bondage"
	},

	{
		id: 6,
		image: Silence,
		name: "Silence"
	},
	{
		id: 7,
		image: Movement,
		name: "Movement"
	},
	{
		id: 8,
		image: Expression,
		name: "Expression",

	},
	{
		id: 9,
		image: Mandala,
		name: "Mandala",

	},
	{
		id: 10,
		image: GivingAndReceiving,
		name: "Giving And Receiving"
	}, {
		id: 11,
		image: Gratitude,
		name: "Gratitude"
	}, {
		id: 12,
		image: KeepingGoodCompany,
		name: "Keeping Good Company",

	}, {
		id: 13,
		image: Surrender,
		name: "Surrender"
	}, {
		id: 14,
		image: Smile,
		name: "Smile"
	},
	{
		id: 15,
		image: Prayer,
		name: "Prayer"
	},
	{
		id: 16,
		image: LettingGoOfAddiction,
		name: "Letting Go of Addiction"
	},
	{
		id: 17,
		image: SelfWorth,
		name: "Self-Worth"
	},
	{
		id: 18,
		image: AcceptingYourDarkness,
		name: "Accepting Your Darkness"
	}
]
