import React, {useEffect, useState} from 'react';
import "./Conclusion.scss";
import PlayHeader from "../PlayHeader/PlayHeader";
import {animated, useSpring, useSprings} from "react-spring";
import DualityCardArray from "../../../Helpers/DualityCard";
import ManAsHumanBeing from "../../../Assets/Duality/ManAsHumanBeing.png"
import WomanAsHumanBeing from "../../../Assets/Duality/WomanAsHumanBeing.png"
import flip from "../../../Assets/flip.png"
import ShadowBall from "../../../Assets/ShadowBall.png"
import {useStoreActions, useStoreState} from "easy-peasy";
import facebook from "../../../Assets/facebook.png";
import instagram from "../../../Assets/instagram.png";
import web from "../../../Assets/web.png";
import youtube from "../../../Assets/youtube.png";


let imageArray = [
	{
		id: 1,
		image: ManAsHumanBeing
	},
	{
		id: 30,
		image: WomanAsHumanBeing
	},
	{
		id: 21,
		image: ManAsHumanBeing
	},
	{
		id: 41,
		image: WomanAsHumanBeing
	},
	{
		id: 11,
		image: ManAsHumanBeing
	}
]

const Conclusion = (props) => {
	const {play: {shadowFinalCards, elementCardSelectedIndex, positivityCardSelected, chakraColorCardSelected, mode, conclusionAnimationOver}} = useStoreState((state => state));
	const selectedCard = [...chakraColorCardSelected, ...shadowFinalCards, ...positivityCardSelected]
	const {play: {updateHidePlayFooter, updateConclusionOver}} = useStoreActions((state => state));
	//
	// mode === 3 ?  selectedCard.splice(3, 0 , {id: 4, image: ShadowBall})
	// 	: selectedCard.splice(2, 0 , {id: 4, image: ShadowBall})

	const fallAnimation = useSprings(selectedCard.length, selectedCard.map((element, index) => {
		return {
			from: {
				transform: "scale(1.3)",
				opacity: 0,
			}, to: {
				transform: "scale(1)",
				opacity: 1
			},
			config: {duration: 1000},
			delay: (mode === 3 && index > 2) || (mode === 2 && index > 1) ? (index * 1000) + 3000 : (index * 1000) + 2000
		}
	}))

	const fadeIn = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1
		},
		config: {duration: 2000},
	});

	const fadeInText = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1
		},
		delay: mode === 3 ? 7000 : 5000,
		config: {duration: 1000},
	});

	const fadeOut = useSpring({
		from: {
			opacity: 1,
		},
		to: {
			opacity: conclusionAnimationOver ? 0 : 1
		},
		config: {duration: 1000},
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			updateHidePlayFooter(false);
		}, mode === 3 ? 8000 : 6000)

		return () => {
			clearTimeout(timer);
		}
	}, [])


	useEffect(() => {
		if (conclusionAnimationOver) {
			const timer = setTimeout(() => {
				updateConclusionOver(true);
			}, 1000)

			return () => {
				clearTimeout(timer);
			}
		}

	}, [updateConclusionOver, conclusionAnimationOver])

	const [rotatedIndex, setRotatedIndex] = useState(-2);


	return (
		<animated.div className="conclusion__container" style={fadeOut}>
			<PlayHeader/>
			<p className="conclusion__title">This concludes your <strong> Divine Play </strong> reading</p>
			<div className="conclusion">
				<animated.p className="conclusion__label"
							style={fadeInText}
				>Click below to view and download your reading and journal.
				</animated.p>

				{mode === 2 && <div className=""/>}
				{
					fallAnimation.map((animation, index) =>
						<ConclusionCard key={index}
										index={index}
										rotateIndex={rotatedIndex}
										setRotateIndex={setRotatedIndex}
										animation={animation} element={selectedCard[index]}/>
					)
				}

				{mode === 2 && <div className=""/>}
				<animated.p className="conclusion__label conclusion__label-left" style={fadeInText}>
					If you would like to know more about Mynavati,
					her meditations, books, inspirations and wisdom,
					please visit either of the links below

					<div className="conclusion__icons">
						<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634"
						   target="_blank"
						   rel="noopener noreferrer"
						   className="">
							<img src={facebook} alt="facebook" className="conclusion__icon"/>
						</a>
						<a href="https://www.instagram.com/mynavatis_inspirations/" className="" target="_blank"
						   rel="noopener noreferrer">
							<img src={instagram} alt="instagram" className="conclusion__icon"/>
						</a>
						<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
						   rel="noopener noreferrer"
						   className="">
							<img src={youtube} alt="youtube" className="conclusion__icon"/>
						</a>
						<a href="https://mynavati.com" className="conclusion__icon" target="_blank"
						   rel="noopener noreferrer">
							<img src={web} alt="web" className="conclusion__icon"/>
						</a>
					</div>
				</animated.p>

				<animated.div className="conclusion__element-container" style={fadeIn}>
					<div className="conclusion__element-card" style={{backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}/>
				</animated.div>
			</div>

		</animated.div>
	);
}

const ConclusionCard = ({animation, setRotateIndex, element, index, rotateIndex, ...props}) => {
	const {play: {mode}} = useStoreState((state => state));


	const nextImage = (id) => {
		if ([37, 38, 39, 40, 41, 42, 43, 44, 45].includes(id)) {
			return DualityCardArray.filter((ele => ele.id === 46))[0];
		} else if (id % 2 === 0) {
			return DualityCardArray.filter((ele => ele.id === id - 1))[0];
		} else {
			return DualityCardArray.filter((ele => ele.id === id + 1))[0];
		}
	}

	const [rotate, setRotate] = useState(false);
	const rotateAnim = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) scale(1) rotateY(0deg)",
			opacity: 1,
		},
		to: {
			transform: `translate3d(0px, 0px, 0) scale(1) rotateY(${rotate ? -180 : 0}deg)`,
			opacity: 1
		},
		config: {duration: 1000},
		delay: mode === 3 && index === 2 ? 1000 : mode === 2 && index === 1 ? 1000 : 0
	});

	const iconAnimation = useSpring({
		from: {
			transform: "translate3d(0px, -100px, 0px)",
			opacity: 0,
		},
		to: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: ((((mode === 3 && [1, 2, 3].includes(index)) || (mode === 2 && [1].includes(index))) && (rotateIndex === -2 || rotateIndex === -1)) || (rotateIndex === index)) ? 1 : 0,
		},
		delay: (mode === 3 && rotateIndex === -2) ? 7000 : (mode === 2 && rotateIndex === -2) ? 5000 : 500
	});

	const calculateDelay = () => {
		if (rotateIndex === -1) {
			return 2000;
		} else if (rotateIndex === -2) {
			if (mode === 3) {
				return 5000
			} else if (mode === 2) {
				return 4000
			}
		}
	}

	const shadowBallAnimation = useSpring({
		from: {
			top: "-30%",
			left: "-10%",
			opacity: 0,
			transform: "scale(1.3)",
		},
		to: [{
			top: (rotateIndex !== -2 || rotateIndex !== -1) ? ((mode === 3 && (rotateIndex === 1 || rotateIndex === 3))) ? "-30%" : ((mode === 3 && rotateIndex === 2) || (mode === 2 && rotateIndex === 1)) ? "-30%" : "-30%" : "-30%",
			left: (rotateIndex !== -2 || rotateIndex !== -1) ? ((mode === 3 && (rotateIndex === 1 || rotateIndex === 3))) ? "-10%" : ((mode === 3 && rotateIndex === 2) || (mode === 2 && rotateIndex === 1)) ? "-50%" : "-10%" : "-10%",
			opacity: ((mode === 3 && rotateIndex === 2) || (mode === 2 && rotateIndex === 1)) ? 0 : 1,
			transform: "scale(1)",

		}],
		config: {duration: 1000},
		delay: calculateDelay()
	});


	return (

		<animated.div style={{
			...animation,
		}}>

			<animated.div className="conclusion-flip__container"
						  key={element.id}
						  style={rotateAnim}>
				<div className="conclusion-flip__card"
					 style={{
						 backgroundImage: `url(${element.image})`,
					 }}/>
				<div className="conclusion-flip__card"
					 style={{
						 backgroundImage: `url(${nextImage(element.id) && nextImage(element.id).image})`,
						 transform: `rotateY(180deg)`
					 }}
				/>

				<animated.div style={iconAnimation} className="conclusion__icon-container">
					<img src={flip} alt="Click to Flip"
						 className="conclusion__icon-flip"
						 onClick={() => {
							 setRotate((value) => {
								 if (!value) {
									 setRotateIndex(index)
								 } else {
									 setRotateIndex(-1)
								 }
								 return !value
							 });

						 }}/>
				</animated.div>
			</animated.div>


			{((mode === 3 && index === 2) || (mode === 2 && index === 1)) &&
			<animated.div className="conclusion__shadow"
						  style={{...shadowBallAnimation}}>
				<div className="conclusion-flip__card"
					 style={{
						 backgroundImage: `url(${ShadowBall})`,
					 }}/>
			</animated.div>
			}
		</animated.div>
	)
};

export default Conclusion;
