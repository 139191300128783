import {action} from 'easy-peasy';

const home = {
	cardAnimation: false,
	detailsAnimation: false,
	resetAnimation: false,

	imagesLoaded: false,

	updateCardAnimation: action((state, value) => {
		state.cardAnimation = value;
	}),
	updateResetAnimation: action((state, value) => {
		state.cardAnimation = value;
	}),
	updateDetailsAnimation: action((state, value) => {
		state.detailsAnimation = value;
	}),
	updateImagesLoaded: action((state, value) => {
		state.imagesLoaded = value;
	})
};


export default home;
