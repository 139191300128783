import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import './Element.scss';
import elements from '../../../Helpers/ElementCard';
import getShuffledArray from "../../../Helpers/RandomizeArray";
import {animated, useSpring, useSprings, useTransition} from "react-spring";
import cardBack from '../../../Assets/cardBack.png';
import cardBackWide from '../../../Assets/cardBackWide.png';
import PlayHeader from "../PlayHeader/PlayHeader";
import parse from 'html-react-parser';

let shuffledElements = [...getShuffledArray(elements)];
shuffledElements = [...getShuffledArray(shuffledElements)];
const negativePosition = -25;
const positivePosition = 25;


const position = (index, currentIndex) => index === 1 && currentIndex === 1 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` :
	index === 2 && currentIndex === 2 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1) ` :
		index === 3 && currentIndex === 3 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` :
			index === 0 && currentIndex === 0 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` : `translate3d(${0}, ${0}, 0) scale(1, 1)`

const transformOrigin = (index, currentIndex) => index === 1 && currentIndex === 1 ? `left bottom` :
	index === 2 && currentIndex === 2 ? `right top` :
		index === 3 && currentIndex === 3 ? `left top` :
			index === 0 && currentIndex === 0 ? `right bottom` : `right bottom`

const translateCard = (index) => index === 1 ? `translate3d(${positivePosition}px, ${negativePosition}px, 0) scale(1,1)` :
	index === 2 ? `translate3d(${negativePosition}px, ${positivePosition}px, 0) scale(1, 1)` :
		index === 3 ? `translate3d(${positivePosition}px, ${positivePosition}px, 0) scale(1,1)` :
			index === 0 ? `translate3d(${negativePosition}px, ${negativePosition}px, 0) scale(1,1)` : `translate3d(${0}, ${0}, 0) scale(1,1)`


const Element = () => {

	const {play: {elementCardSelected}} = useStoreState((state => state));
	const [showFeel, setShowFeel] = useState(false);


	useEffect(() => {
		if (elementCardSelected) {
			const timeout = setTimeout(() => {
				setShowFeel(true);
			}, 1100);
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [elementCardSelected])

	return (
		<>
			<PlayHeader/>
			{!showFeel ? <ElementSelect/> : <ElementQuestion/>}
		</>
	);


}


const ElementQuestion = React.memo(() => {

	const {play: {elementCardSelectedIndex, elementFeel, elementFeelAdded, elementAnimationOver}} = useStoreState((state => state));
	const {play: {updateElementFeel, updateElementProcessOver}} = useStoreActions((state => state));


	const [flipped, set] = useState(true);

	useEffect(() => {
		if (elementAnimationOver) {
			const timer = setTimeout(() => {
				updateElementProcessOver(true);
			}, 1000)
			return () => {
				clearTimeout(timer);
			}
		}
	}, [elementAnimationOver, updateElementProcessOver])

	const transitLeft = useTransition(elementAnimationOver, null, {
		from: {
			transform: "translate3d(-200px, 0px, 0px)",
			opacity: 0,
		},
		enter: {
			transform: "translate3d(0px, 0px, 0)",
			opacity: 1,
		},
		leave: {
			transform: "translate3d(-200px, 0px, 0px)",
			opacity: 0,
		},
		config: {duration: 1000},
	});

	const rotate = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
		},
		to: [{
			transform: "translate3d(0px, 0px, 0) rotateY(-180deg)"
		},
		],
		config: {duration: 3000},
		delay: 1000,
	});

	const transitDown = useSpring({
		from: {
			transform: "translate3d(0px, -100px, 0px)",
			opacity: 0,
		},
		to: [{
			transform: "translate3d(0px, 0px, 0)",
			opacity: 1,
		},
		],
		delay: 3000,
		config: {duration: 1000},
	});

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},
		],
		delay: 3000,
		config: {duration: 1000},
	});

	const fadeOut = useSpring({

		opacity: elementAnimationOver ? 0 : 1,
		config: {duration: 1000},
	});

	const fadeIn = useTransition(elementFeelAdded, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: elementAnimationOver ? 0 : 1
		},
		leave: {
			opacity: 0
		},
		config: {duration: 1000},
		trail: 1000,
		order: ['leave', 'enter', 'update']
	})

	useEffect(() => {
		const timeout = setTimeout(() => {
			set(false)
		}, 1200)
		return (() => {
			clearTimeout(timeout);
		})
	}, [])


	return (
		<div className="element">

			{transitLeft.map(({item, props: animation, key}) =>
				!item && <animated.div className="element-flip__root" key={key} style={animation}>
					<animated.div className="element-flip__container" style={rotate}>
						<div className="element-flip__card"
							 style={{
								 backgroundImage: `url(${cardBack})`,
							 }}/>
						<div className="element-flip__card"
							 style={{
								 backgroundImage: `url(${elementCardSelectedIndex[0].image})`,
								 transform: `rotateY(180deg)`
							 }}/>
					</animated.div>
				</animated.div>
			)}
			<animated.div className="element-flip__details">
				{
					!flipped && fadeIn.map(({item, props: animation, key}) =>
						!item ?
							<animated.div className="element-flip__sub-details" style={animation} key={key}>
								<animated.p className="element__title" style={transitDown}>Your
									Pick {elementCardSelectedIndex[0].name}</animated.p>
								<animated.p className="element__description" style={transitDown}>Feel what this Element
									and its energy means to you. It is helpful to
									note down your thoughts and feelings.
								</animated.p>
								<div className="element-flip__input-container">
									<animated.textarea name="question"
													   style={transitWidth}
													   className="element-flip__input"
													   placeholder="Start typing..."
													   maxLength={280}
													   value={elementFeel}
													   onChange={(event) => {
														   updateElementFeel(event.target.value);
													   }}
									/>

									<animated.p className="element-flip__input-counter"
												style={{...transitWidth, width: "auto"}}
									>{elementFeel.length}/280
									</animated.p>
								</div>
							</animated.div>

							:

							<animated.div className="element-flip__sub-details" style={animation} key={key}>
								<animated.div style={fadeOut}>

									<p className="element__title" style={{marginBottom: "2em"}}>Qualities
										of {elementCardSelectedIndex[0].name}</p>

									<p className="element__description">{parse(elementCardSelectedIndex[0].quality1)}</p>
									<p className="element__description">{parse(elementCardSelectedIndex[0].quality2)}</p>
								</animated.div>

							</animated.div>
					)
				}
			</animated.div>
		</div>
	)
});


const ElementSelect = React.memo(() => {

	const [clicked, setClicked] = useState(false);
	const [clickedIndex, setClickedIndex] = useState(-1);
	const {play: {elementCardSelected}} = useStoreState((state => state));


	const elementAnimation = useSprings(shuffledElements.length, shuffledElements.map((element, index) => {
		return {
			from: {
				transform: translateCard(index),
				backgroundImage: `url(${cardBack})`
			}, to: {
				transform: (clicked && clickedIndex !== -1 && !elementCardSelected) ? position(clickedIndex, index) : `translate3d(${0}, ${0}, 0) scale(1,1)`,
				transformOrigin: clicked && clickedIndex !== -1 ? transformOrigin(clickedIndex, index) : "top bottom"
			},
			config: {duration: 1000}
		}
	}));
	const transitLeft = useSpring({
		from: {
			transform: "translate3d(50px, 0px, 0px)",
			opacity: 0,
		},
		to: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: elementCardSelected ? 0 : 1
		},
		config: {duration: 1000}
	});
	const transitLeftDescription = useSpring({
		from: {
			transform: "translate3d(50px, 0px, 0px)"
		},
		to: {
			transform: elementCardSelected ? "translate3d(0px, -20px, 0)" : "translate3d(0px, 0px, 0px)"
		},
		config: {duration: 1000}
	});
	const {play: {updateElementCardSelectedIndex}} = useStoreActions((actions => actions));


	return (
		<div className="element">
			<div className="element__cards">
				{elementAnimation.map((animation, index) =>
					<animated.div
						className={`element__card  ${(clicked && clickedIndex !== index) && `element__card-disabled`}`}
						style={{
							...animation, backgroundImage: `url${cardBackWide}`
						}}
						key={index}
						onClick={() => {
							if (!clicked) {
								setClicked(true);
								setClickedIndex(index);
								updateElementCardSelectedIndex(shuffledElements[index])
							}
						}}
					/>
				)
				}
			</div>

			<animated.div className="element__details" style={transitLeft}>
				<p className="element__title">Pick an Element Card by clicking on the card</p>
				<animated.p className="element__description" style={transitLeftDescription}>Think of each Element as
					a catalyst – in this case as an Alchemical Reactor. Each Element integrates your chosen Dualities and Wholeness Card energies in
					its own particular way. These energies will be revealed to you as you choose your card.
				</animated.p>
				<animated.p className="element__description" style={transitLeftDescription}>Be as spontaneous, open
					and willing as you can be. Let the Card
					choose you.
				</animated.p>
			</animated.div>
		</div>
	)
})
export default React.memo(Element);
