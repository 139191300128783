import React from 'react';
import {useStoreState} from "easy-peasy";

const PlayHeader = (props) => {
	const {play: {mode}} = useStoreState((state => state));

	return (
		<p className="play__main-heading">Play / Reading
			- {mode === 1 ? 'Energies of the Day' : mode === 2 ? 'One Card Method' : mode === 3 ? 'Three Card Method' : 'Default'}</p>
	);
}

export default PlayHeader;
