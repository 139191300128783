import React, {useCallback, useState} from 'react';
import {animated, useSprings} from "react-spring";
import "./Mobile.scss";
import CardDeck from "../UI/CardDeck/CardDeck";
import fire from '../../Assets/Elements/Fire.png'
import water from '../../Assets/Elements/Water.png'
import air from '../../Assets/Elements/Air.png'
import earth from '../../Assets/Elements/Earth.png'
import woman from '../../Assets/Duality/FeminePsychicModeOfExpression.png';
import mandala from '../../Assets/Positivity/Mandala.png';
import masc from '../../Assets/Duality/MasuclinePsychicModeOfExpression.png';
import redChakra from '../../Assets/Colors/red.png';
import shadowBall from '../../Assets/ShadowBall.png';
import sorceress from '../../Assets/Duality/TheHighPriestess.png';


const negativePosition = -40;
const positivePosition = 40;

const ElementObject = [
	{
		id: 0,
		name: "Fire",
		xy: [negativePosition, negativePosition],
		image: fire,

	}, {
		id: 1,
		name: "Air",
		xy: [positivePosition, negativePosition],
		image: air
	},
	{
		id: 2,
		name: "Water",
		xy: [negativePosition, positivePosition],
		image: water
	},
	{
		id: 3,
		name: "Earth",
		xy: [positivePosition, positivePosition],
		image: earth
	}
]

const center = 7 * 16;
const xCords = [-20, 190, 420];
const yCords = [40, 280];


const CollapsingCardObject = [
	{
		id: 0,
		name: "Red Chakra",
		xy: [-20, center],
		rotate: 30,
		rotateInitial: 8,
		image: redChakra,
		zIndex: 2

	}, {
		id: 1,
		name: "Masculine Psychic Mode of Expression",
		xy: [40, center - 20],
		rotate: -9,
		rotateInitial: -10,
		image: masc,
		zIndex: 3

	},
	{
		id: 2,
		name: "The Sorceress",
		xy: [190, center - 50],
		// xyDetails: [xCords[1], yCords[0]],
		rotate: -10,
		rotateInitial: -10,
		image: sorceress,
		zIndex: 2.1
	},
	{
		id: 3,
		name: "Feminine Psychic Mode of Expression",
		xy: [140, center - 20],
		// xyDetails: [xCords[1], yCords[1]],
		rotateInitial: 3,
		rotate: 8,
		image: woman,
		zIndex: 2.8
	},
	{
		id: 4,
		name: "Shadow Ball",
		xy: [80, center - 20],
		// xyDetails: [xCords[2], yCords[0]],
		rotate: 4,
		rotateInitial: 6,
		image: shadowBall,
		zIndex: 4
	},
	{
		id: 5,
		name: "A Circular Mandala",
		xy: [230, center - 40],
		rotateInitial: -18,
		rotate: -17,
		image: mandala,
		zIndex: 3
	}
]

const Mobile = (props) => {


	const elementCardAnimation = useSprings(ElementObject.length, ElementObject.map((element) => {
			return {
				from: {
					transform: `translate(${element.xy[0]}px, ${element.xy[1]}px)`,
				}, to: {
					transform: `translate(0px, 0px)`,
				},
				config: {duration: 1000},
			}
		})
	);


	const [detailsActive, setDetailsActive] = useState(false);
	const [detailsActiveText, setDetailsActiveTest] = useState(false);

	const [detailsToggle, setDetailsToggle] = useState(false);


	const collapseCardAnimation = useSprings(CollapsingCardObject.length,
		CollapsingCardObject.map((element) => {
			return {
				from: {
					position: 'absolute',
					left: element.xy[0],
					top: element.xy[1],
					transform: `scale(2.3)  translate(0, 0px) rotateZ(${!detailsActive ? element.rotateInitial : 0}deg)`,
					opacity: 0,
					zIndex: element.zIndex
				},
				to: {
					left: element.xy[0],
					top: element.xy[1],
					transform: `scale(${1}) translate(0, 0px) rotateZ(${!detailsActive ? element.rotateInitial : 0}deg)`,
					opacity: 1
				},
				config: {duration: 500},
				delay: !detailsActive && !detailsToggle ? element.id ? 1000 + (800 * element.id) : 1000 : 0
			}
		}))


	const switchRotateAnimation = useCallback(() => {
		setDetailsActiveTest(value => !value)
		setDetailsToggle(true);
		setDetailsActive((value) => !value);
	}, [setDetailsActiveTest, setDetailsToggle, setDetailsActive]);


	return (
		<div className="mobile">
			<p className="mobile__title">Welcome to Divine Play</p>

			<div className="mobile__animation">
				{
					elementCardAnimation.map((animation, index) => {
						return (<animated.img src={ElementObject[index].image}
											  style={{...animation}}
											  key={ElementObject[index].id}
											  alt={`${ElementObject[index].name} Element Card of Divine Play`}
											  className="mobile__element-card "/>)
					})
				}
				{
					collapseCardAnimation.map((animation, index) => {
						return (<CollapsingCard
							image={CollapsingCardObject[index].image}
							animation={{...animation}}
							name={CollapsingCardObject[index].name}
							key={CollapsingCardObject[index].id}
						/>)
					})
				}
			</div>


			<p className="mobile__description"	>Due to the nature of Divine Play, it can only be truly experienced and played on a suitably sized screen;
				either on a desktop PC, laptop or large iPad / Tablet in landscape mode.<br/><br/>
				Unfortunately the game cannot be played on smaller tablets or mobile phones. Please use an alternative device </p>

			<div className="w-25 margin-auto" style={{marginBottom: "2em"}}>
				<button className=" btn " onClick={() => {
					window.open('http://www.mynavati.com/', '_blank')
				}}>Go Back
				</button>
			</div>
			<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"}
					  offset={20} number={5} disableAnimation={false}/>

		</div>
	);
}

export default Mobile;

const CollapsingCard = ({animation, image, name}) => {

	return (
		<animated.div style={{
			...animation,
			backgroundImage: `url(${image})`
		}} className="mobile__collapsing__card"/>
	)
}
