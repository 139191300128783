import React, {useEffect, useRef, useState} from 'react';
import "../../ChakraColor/ChakraColor.scss";
import {animated, useSpring, useTransition} from "react-spring";
import chakraColor from "../../../../Helpers/ChakraColorCard"
import getShuffledArray from "../../../../Helpers/RandomizeArray";
import {useStoreActions, useStoreState} from "easy-peasy";
import Shuffle from "../../../Animation/Shuffle/Shuffle";
import cardBack from '../../../../Assets/cardBack.png';
import PlayHeader from "../../PlayHeader/PlayHeader";
import CardDeck from "../../../UI/CardDeck/CardDeck";
import facebook from "../../../../Assets/facebook.png";
import instagram from "../../../../Assets/instagram.png";
import web from "../../../../Assets/web.png";
import youtube from "../../../../Assets/youtube.png";


const shuffledElements = [...getShuffledArray(chakraColor)];


const ChakraColor = (props) => {

	const {play: {chakraColorShuffleStart, chakraColorSelectCompleted, hidePlayFooter}} = useStoreState((state => state))
	const {play: {updateChakraColorCardSelected, updateHidePlayFooter}} = useStoreActions((state => state))

	const [shuffleStart, setShuffleStart] = useState(false);
	const [shuffleComplete, setShuffleComplete] = useState(false);
	const [shuffleOver, setShuffleOver] = useState(false);

	const transitLeft = useTransition(chakraColorShuffleStart, null, {
		from: {
			transform: "translate3d(0px, -50px, 0px)",
			opacity: 0,
		},
		enter: {

			transform: "translate3d(0px, 0px, 0px)",
			opacity: 1,
		},
		leave: {
			transform: "translate3d(0px, -50px, 0px)",
			opacity: 0,
		},
		config: {duration: 600},
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (chakraColorShuffleStart) {
				setShuffleStart(true);
			}
		}, 2000);

		return () => {
			clearTimeout(timeout);
		}
	}, [setShuffleStart, updateHidePlayFooter, chakraColorShuffleStart])


	useEffect(() => {
		if (shuffleComplete) {
			updateHidePlayFooter(false);
			const timeout = setTimeout(() => {
			}, 1000)
			return () => {
				clearTimeout(timeout);
			}
		}

	}, [shuffleComplete, updateHidePlayFooter])

	useEffect(() => {
		if (chakraColorSelectCompleted) {
			const timeout = setTimeout(() => {
				setShuffleOver(true)
			}, 2000)
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [chakraColorSelectCompleted])


	return (
		<>
			<PlayHeader/>
			{
				!shuffleStart ?

					transitLeft.map(({item, props: animation, key}) => {
						return !item && <div className="chakra" key={key}>
							<animated.div className="chakra__details" style={animation}>
								<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5} disableAnimation={true}/>
							</animated.div>
							<animated.div className="chakra__description" style={{...animation, alignSelf: "center"}}>
								<p className="main__title">Chakra Colour Cards</p>
								<p className="main__description">This card identifies the psycho-spiritual energy level of you that is predominant
									today and is a potent doorway to this level’s emotions, feelings and spirituality.</p>
							</animated.div>
						</div>
					})
					:
					(shuffleStart && !shuffleOver) ?
						<Shuffle items={shuffledElements} cardLimit={1} shuffleCount={2}
								 title={"Pick a Chakra Color Card Card"}
								 subtitle={"by clicking on the card"}
								 alignment={"380px"}
								 setShuffleComplete={setShuffleComplete}
								 pushCard={updateChakraColorCardSelected}
								 animationRewind={chakraColorSelectCompleted}/>
						: shuffleOver ?
						<ChakraColorFeel updateHideFooter={updateHidePlayFooter} hidePlayFooter={hidePlayFooter}/> : ""
			}
		</>
	);
}


const ChakraColorFeel = React.memo(({updateHideFooter, hidePlayFooter, ...props}) => {

	const {play: {chakraColorCardSelected, elementCardSelectedIndex}} = useStoreState((state => state))

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		config: {duration: 2000},
	});
	const rotate = useTransition(true, null, {
		from: {
			transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
			opacity: 0,
		},
		enter: [
			{
				transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
				opacity: 1,
			},
			{
				transform: "translate3d(0px, 0px, 0) rotateY(-180deg)",
				opacity: 1,
			},
		],
		leave: {
			transform: "translate3d(0px, 0px, 0) rotateY(-180deg)",
			opacity: 1,
		},
		config: {duration: 2000},
		delay: 1000,
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			updateHideFooter(false);

			return () => {
				clearTimeout(timer)
			}
		}, 4000)
	})

	return (
		<div className="chakra">
			<div className="chakra__element-details">
				{rotate.map(({item, props: animation, key}) =>
					item && <animated.div className={`chakra-flip__container-2`} style={animation} key={key}>
						<div className="chakra-flip__card"
							 style={{
								 backgroundImage: `url(${cardBack})`,
							 }}/>
						<div className="chakra-flip__card"
							 style={{
								 backgroundImage: `url(${chakraColorCardSelected[0].image})`,
								 transform: `rotateY(180deg)`
							 }}/>
					</animated.div>
				)}
			</div>
			<ChakraDescription/>
		</div>
	)
})

const ChakraDescription = React.memo(({...props}) => {

	const {play: {updateShowChakraImage}} = useStoreActions((actions => actions));

	const {play: {chakraColorCardSelected, chakraFeelAdded, chakraProcessOver}} = useStoreState((state => state))

	const [nav, setNav] = useState(false);

	const fadeInDescription = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		delay: 4000,
		config: {duration: 2000},
	});

	const fadeIn = useTransition(nav, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	})


	const descRef = useRef();
	const presRef = useRef();
	const [coords, setCoords] = useState({});


	useEffect(() => {
		if (presRef.current && descRef.current) {
			const {x: prescX, y: prescY} = presRef.current.getBoundingClientRect();
			const {x: descX} = descRef.current.getBoundingClientRect();
			setCoords({
				...{
					prescX, prescY, descX
				}
			})
		}
	}, [chakraFeelAdded]);



	return (
		!chakraFeelAdded ? <div className="chakra-element__description ">
			<animated.div className="" style={{...fadeInDescription}}>
				<p className="chakra__title">{`You picked the ${chakraColorCardSelected[0].name} Chakra!`}</p>
				<p className="chakra__description" style={{marginTop: "1em"}}>What do you see and feel? Feel what this Chakra and its energy
					means to you.</p>

				<div className="chakra__btn-container w-100">
					<button className="chakra__btn w-20" onClick={() => {
						updateShowChakraImage(true);
					}}>click here
					</button>
					<p className="chakra__sub-text  w-100">Click on the button to see the colour-coded diagram of
						the Chakras.</p>
				</div>

				<p className="chakra__description">Place your left hand palm on the centre of the Chakra and place
					your right hand palm on top of the back of your left hand. It is helpful to note down your thoughts
					and feelings.</p>
			</animated.div>
		</div> : !chakraProcessOver ? <div className="chakra-element__description">
			<p className="chakra__title w-75">{`
				The ${chakraColorCardSelected[0].name} Chakra Card - Represents The ${chakraColorCardSelected[0].represents} Chakra`}</p>
			<div className="chakra__nav-container">
				<p className="chakra__sub-text cursor-pointer"
				   ref={descRef}
				   onClick={() => {
					   setNav(false)
				   }}>Description</p>
				<p className="chakra__sub-text cursor-pointer"
				   ref={presRef}

				   onClick={() => {
					   setNav(true)
				   }}>Prescription for Healing</p>

				{coords && <div className="chakra__nav-slide" style={{
					top: `${coords.prescY + 10}px`,
					left: !nav ? `${coords.descX}px` : `${coords.prescX}px`,
				}}/>}
			</div>
			<div className="chakra__nav">
				{
					fadeIn.map(({item, props: animation, key}) =>
						!item ? <animated.div className="chakra__nav-content" key={key} style={animation}>
								{chakraColorCardSelected[0].description.map((element, index) => <p
									className="chakra__description" key={index}>{element}</p>)}
							</animated.div>
							: <animated.div className="chakra__nav-content" key={key} style={animation}>
								{chakraColorCardSelected[0].healing.map((element, index) => <p
									className="chakra__prescription" key={index}>{element}</p>)}
							</animated.div>
					)
				}
			</div>
		</div> : <div className="chakra-element__description">
			<p className="element__title" style={{marginBottom: "2em"}}>This concludes your reading</p>
			<p className="element__description">Click below to download your card of the day.</p>
			<p className="element__description">If you would like to know more about Mynavati,
				her meditations, books, inspirations and wisdom, please click on the links below</p>

			<div className="journal__icons">
				<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634" target="_blank"
				   rel="noopener noreferrer">
					<img src={facebook} alt="facebook" className="journal__icon"/>
				</a>
				<a href="https://www.instagram.com/mynavatis_inspirations/" target="_blank"
				   rel="noopener noreferrer">
					<img src={instagram} alt="instagram" className="journal__icon"/>
				</a>
				<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
				   rel="noopener noreferrer"
				>
					<img src={youtube} alt="youtube" className="journal__icon"/>
				</a>
				<a href="https://mynavati.com" target="_blank"
				   rel="noopener noreferrer">
					<img src={web} alt="web" className="journal__icon"/>
				</a>
			</div>
		</div>
	)
})

export default React.memo(ChakraColor);
