import fire from '../Assets/Elements/Fire.png'
import water from '../Assets/Elements/Water.png'
import air from '../Assets/Elements/Air.png'
import earth from '../Assets/Elements/Earth.png'




export default [
	{
		id: 0,
		name: "Fire",
		image: fire,
		quality1: "The Fire Element as your base, indicates that the energies related to you and your situation will be played out through the Force of Spirit. Healing will be fast. Insights will be spontaneous.",
		quality2: "The lesson—Be open to passion, purification, creativity and intuition, without becoming annihilated and burned out.",
	}, {
		id: 1,
		name: "Water",
		image: water,
		quality1: "The Water Element as your base, indicates that the energies related to you and your situation will be played out through the Force of Emotion. Healing will be cleansing. Insights will be releasing.",
		quality2: "The lesson—Be open to empathy, sympathy, compassion and dissolution without becoming overwhelmed and drowned.",
	}, {
		id: 2,
		name: "Air",
		image: air,
		quality1: "The Air Element as your base, indicates that the energies related to you and your situation will be played out through the Force of Mind. Healing will be conscious. Insights will be sharp.",
		quality2: "The lesson—Be open to communication, illumination, change and clarification without becoming confused and lost.",
	}, {
	id: 3,
		name: "Earth",
		image: earth,
		quality1: "The Earth Element as your base, indicates that the energies related to you and your situation will be played out through the Force of Body. Healing will be grounding. Insights will be concrete.",
		quality2: "The lesson—Be open to steadiness, sensation, materiality and tenacity without becoming too fixed and stuck.",
	},
]
