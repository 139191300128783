import React, {useRef, useState} from 'react';
import "./Journal.scss";
import facebook from "../../../Assets/facebook.png";
import instagram from "../../../Assets/instagram.png";
import web from "../../../Assets/web.png";
import youtube from "../../../Assets/youtube.png";

import PlayHeader from "../PlayHeader/PlayHeader";
import {useStoreActions, useStoreState} from "easy-peasy";
import DualityCardArray from "../../../Helpers/DualityCard";
import htmlToImage from 'html-to-image';
import {animated, useSprings} from "react-spring";
import {saveAs} from 'file-saver';
import PositivityDescription from "../Positivity/PositivityDescription/PositivityDescription";
import parse from 'html-react-parser';
import Toast from "../../UI/Toast/Toast";

const list = [{
	id: 1,
	title: 'Info',
	description: 'This is an info toast component',
	backgroundColor: '#5bc0de',
}]


const nextImage = (id) => {
	if ([37, 38, 39, 40, 41, 42, 43, 44, 45].includes(id)) {
		return DualityCardArray.filter((ele => ele.id === 46))[0];
	} else if (id % 2 === 0) {
		return DualityCardArray.filter((ele => ele.id === id - 1))[0];
	} else {
		return DualityCardArray.filter((ele => ele.id === id + 1))[0];
	}
}


const Journal = (props) => {
	const pdfRef = useRef();


	const {
		play: {
			shadowFinalCards, elementCardSelectedIndex, positivityCardSelected,
			chakraColorCardSelected, dualityCardSelected, question,
			elementFeel, chakraFeel, dualityFeelCard1, dualityFeelCard2,
			dualityFeelCard3, dualityFlippedFeel, mode
		}
	} = useStoreState((state => state));

	const {
		play: {
			resetPlay
		}
	} = useStoreActions((actions => actions));

	const array = mode === 3 ? [
		chakraColorCardSelected[0].image,
		dualityCardSelected[0].image,
		dualityCardSelected[1].image,
		dualityCardSelected[2].image,
		nextImage(dualityCardSelected[0].id).image,
		nextImage(dualityCardSelected[1].id).image,
		nextImage(dualityCardSelected[2].id).image,
		positivityCardSelected[0].image
	] : [
		chakraColorCardSelected[0].image,
		dualityCardSelected[0].image,
		nextImage(dualityCardSelected[0].id).image,
		positivityCardSelected[0].image
	];

	const transitionArray = useSprings(array.length, array.map((element, index) => {
		return {
			from: {
				transform: `translate(${0}px, 0px) scale(1.2) rotateX(0)`,
				opacity: 0,

			},
			to: {
				transform: `translate(0px, 0px) scale(1) rotateX(0)`,
				opacity: 1,

			},
			delay: index * 800,
			config: {duration: 800}
		}
	}))

	const [background, setBackground] = useState(false);
	const [toast, setToast] = useState(false);

	return (
		<div className="journal">
			{toast ?
				<Toast
					toastList={list}
					position={"top-left"}
					autoDelete={false}
					autoDeleteTime={30000}
				/> : <p/>}
			<PlayHeader/>
			<div className="journal__title-container">
				{/*<ReactToPdf targetRef={pdfRef} filename="div-blue.pdf">*/}
				{/*	{({toPdf}) => (*/}
				{/*		<button onClick={toPdf} className="journal__btn">Download Journal</button>*/}
				{/*	)}*/}
				{/*</ReactToPdf>*/}
				<button onClick={() => {

					setBackground(true);
					setToast(true);


					pdfRef.current.style.width = "1250px"
					pdfRef.current.style.padding = "1em 3em"
					htmlToImage.toPng(pdfRef.current)
						.then(function (dataUrl) {
							saveAs(dataUrl, 'Journal.png');
							setBackground(false);
							setTimeout(() => {
								setToast(false);
							}, 4000)
							pdfRef.current.style.width = "1200px"
							pdfRef.current.style.padding = "0"

						})

						.catch(function (error) {
							console.error('oops, something went wrong!', error);
							setBackground(false);
						});

					// htmlToImage.toBlob(pdfRef.current)
					// 	.then(function (blob) {
					// 		saveAs(blob, 'Journal.png');
					// 	});

				}} className="journal__btn">{background ? "Downloading..." : "Download Journal"}
				</button>

				<p className="journal__title">The Journal</p>
				<div className="journal__icons">
					<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634" target="_blank"
					   rel="noopener noreferrer">
						<img src={facebook} alt="facebook" className="journal__icon"/>
					</a>
					<a href="https://www.instagram.com/mynavatis_inspirations/" target="_blank"
					   rel="noopener noreferrer">
						<img src={instagram} alt="instagram" className="journal__icon"/>
					</a>
					<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
					   rel="noopener noreferrer"
					>
						<img src={youtube} alt="youtube" className="journal__icon"/>
					</a>
					<a href="https://mynavati.com" target="_blank"
					   rel="noopener noreferrer">
						<img src={web} alt="web" className="journal__icon"/>
					</a>
				</div>
			</div>

			<div className="w-100" ref={pdfRef}
				 style={background ? {background: "linear-gradient(90deg, rgba(0, 76, 255, 1) 0%, rgba(173, 0, 255, 1) 97%)"} : null}>
				<div className="w-100">
					<p className="journal__subtitle">Congratulations on finishing your Divine Play reading. To know how to work with your reading,
						please
						visit the <strong> How to work with your reading</strong> section of www.mynavati.com/divineplay</p>
					<div className="journal__main-card">
						{
							transitionArray.map((animation, index) =>
								<animated.div className="journal__div-1" key={index} style={{
									backgroundImage: `url(${array[index]})`,
									...animation
								}}/>
							)
						}

						<div className="journal__div-2"/>
						{mode === 2 && <div className="journal__div-empty"/>}
						<div className="journal__element-container">
							<div className="journal__element-card" style={{
								backgroundImage: `url(${elementCardSelectedIndex[0].image})`
							}}/>
						</div>
					</div>
					<Section title={"Your Question"} number={1}/>

					<p className="journal__question">{question}</p>

					<Section title={"The Element Cards"} number={2}/>
					<Element element={elementCardSelectedIndex[0]} elementFeel={elementFeel}/>

					<Section title={"The Chakra Color Cards"} number={3}/>
					<ChakraColorCard element={chakraColorCardSelected[0]} chakraFeel={chakraFeel}/>

					<Section title={"The Duality and Wholeness Cards"} number={4}/>

					<DualityCard past={dualityCardSelected[0]} present={dualityCardSelected[1]} future={dualityCardSelected[2]}
								 dualityFeelCard1={dualityFeelCard1}
								 element={elementCardSelectedIndex[0]}
								 mode={mode}
								 dualityFeelCard2={dualityFeelCard2} dualityFeelCard3={dualityFeelCard3}/>
					<DualityCard2 past={nextImage(dualityCardSelected[0].id)} present={dualityCardSelected[1] && nextImage(dualityCardSelected[1].id)}
								  dualityFlippedFeel={dualityFlippedFeel}
								  element={elementCardSelectedIndex[0]}
								  mode={mode}
								  future={dualityCardSelected[2] && nextImage(dualityCardSelected[2].id)}
					/>

					<CardDetails past={dualityCardSelected[0]} flipped={nextImage(dualityCardSelected[0].id)} value={"Past"} mode={mode}/>

					{mode === 3 && <CardDetails past={dualityCardSelected[1]} flipped={nextImage(dualityCardSelected[1].id)} value={"Present"}/>}
					{mode === 3 && <CardDetails past={dualityCardSelected[2]} flipped={nextImage(dualityCardSelected[2].id)} value={"Future"}/>}
					<Section title={"The Shadow Ball Card"} number={5}/>
					<ShadowBall past={shadowFinalCards[0]} present={shadowFinalCards[1]}
								future={shadowFinalCards[2]} mode={mode}/>

					<Section title={"The Positive Karma Card"} number={6}/>
					<Positivity element={positivityCardSelected[0]}/>


					<Section title={"Print your Duality And Wholeness Card"} number={7} mode={mode}/>
					<Print past={dualityCardSelected[0] && dualityCardSelected[0].image}
						   pastFlip={dualityCardSelected[0] && nextImage(dualityCardSelected[0].id).image}
						   present={dualityCardSelected[1] && dualityCardSelected[1].image}
						   presentFlip={dualityCardSelected[1] && nextImage(dualityCardSelected[1].id).image}
						   future={dualityCardSelected[2] && dualityCardSelected[2].image}
						   futureFlip={dualityCardSelected[2] && nextImage(dualityCardSelected[2].id).image}
						   mode={mode}
					/>
					<hr className="journal__hr"/>

					{background && <div className="d-flex justify-content-center w-100" style={{paddingBottom: "3rem", height: "10rem"}}>
						<p className="footer__text margin-auto">COPYRIGHTS. 2020 | ALL RIGHTS RESERVED TO MYNAVATI.COM</p>

					</div>}


				</div>
			</div>

			<div className="journal__title-container">
				<button className="journal__btn" onClick={() => {
					resetPlay();
				}}>Another Reading ?
				</button>
				<button onClick={() => {
					setToast(true);

					setBackground(true);
					htmlToImage.toPng(pdfRef.current)
						.then(function (dataUrl) {
							saveAs(dataUrl, 'Journal.png');
							setBackground(false);
							setTimeout(() => {
								setToast(false);
							}, 4000)
							pdfRef.current.style.width = "1200px"
							pdfRef.current.style.padding = "0"
						})

				}} className="journal__btn">{background ? "Downloading...." : "Download Journal"}
				</button>
				<button className="journal__btn" onClick={() => {
					window.scroll({
						top: 0,
						left: 0,
						behavior: 'smooth'
					});
				}}>Back to Top
				</button>
			</div>
			<div className="journal__title-container" style={{margin: "4em 0"}}>
				<p className="journal__title">To know more and get updates please connect to Mynavati</p>
				<div className="journal__icons">
					<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634" target="_blank"
					   rel="noopener noreferrer">
						<img src={facebook} alt="facebook" className="journal__icon"/>
					</a>
					<a href="https://www.instagram.com/mynavatis_inspirations/" target="_blank"
					   rel="noopener noreferrer">
						<img src={instagram} alt="instagram" className="journal__icon"/>
					</a>
					<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
					   rel="noopener noreferrer"
					>
						<img src={youtube} alt="youtube" className="journal__icon"/>
					</a>
					<a href="https://mynavati.com" target="_blank"
					   rel="noopener noreferrer">
						<img src={web} alt="web" className="journal__icon"/>
					</a>
				</div>
			</div>
		</div>
	);
}

export default Journal;

const Section = ({title, number, ...props}) => {

	return (
		<div className="journal__section">
			<div className="journal__section-border"/>
			<div className="journal__section-main">
				<p className="journal__title">{title}</p>
				<p className="journal__title">{number}</p>
			</div>
		</div>
	)
}

const Element = ({element, elementFeel}) => {

	return (
		<div className="journal__grid">

			<div className="journal__grid-element" style={{
				backgroundImage: `url(${element.image})`
			}}/>
			<div className="journal__grid-details">
				<p className="journal__grid-subtitle">Your Element</p>
				<p className="journal__grid-title">Your Pick {element.name}</p>

				<p className="journal__grid-subtitle">What you see and feel</p>
				<p className="journal__grid-feel">{elementFeel}</p>

				<p className="journal__grid-title">Qualities of {element.name}</p>

				<p className="journal__grid-desc">{element.quality2}</p>

				<p className="journal__grid-desc">{element.quality1}</p>
			</div>
		</div>
	)
}

const ChakraColorCard = ({element, chakraFeel}) => {

	return (
		<>
			<div className="journal__grid">
				<div className="journal__grid-chakra"
					 style={{
						 backgroundImage: `url(${element.image})`
					 }}
				/>
				<div className="journal__grid-details">
					<p className="journal__grid-subtitle">Your Chakra Colour Card</p>
					<p className="journal__grid-title">The {element.name} Chakra Card</p>

					<p className="journal__grid-subtitle">What you see and feel</p>
					<p className="journal__grid-feel">{chakraFeel}</p>
				</div>
			</div>

			<div className="journal__grid">
				<div className="journal__grid-first">
					<p className="journal__grid-title">The {element.name} Chakra Card - Represents <br/> The {element.represents} Chakra</p>
					<p className="journal__grid-subtitle">Description</p>

					{
						element.description.map((element, index) =>
							<p className="journal__grid-desc" key={index}>{parse(element)}</p>
						)
					}


				</div>
				<div className="journal__grid-details">
					<p className="journal__grid-subtitle">Prescription for Healing</p>
					<p className="journal__grid-title">The {element.name} Chakra Card</p>

					{
						element.healing.map((element, index) =>
							<p className="journal__grid-desc" key={index} style={{margin: 0}}><em> {parse(element)} </em></p>
						)
					}


					<p className="journal__grid-subtitle"
					   style={{marginTop: "3em"}}
					><strong>Prescription for Healing</strong></p>

					<a className="journal__grid-desc" rel="noopener noreferrer" href="https://youtube.com/mynavati"
					   target="_blank">www.youtube.com/mynavati</a>

				</div>
			</div>
		</>
	)

}

const DualityCard = ({past, present, future, dualityFeelCard1, dualityFeelCard2, dualityFeelCard3, mode}) => {

	return (
		<div className="journal__grid-2">
			{mode === 3 ? <div className="journal__grid-2-intro">
				<div className="journal__placeholder"
					 style={{
						 backgroundImage: `url(${past && past.image})`
					 }}
				>
					{/*<div className="journal__placeholder-label-container">*/}
					{/*	<p className="journal__grid-2-feel">Past</p>*/}
					{/*</div>*/}
				</div>

				<div className="journal__placeholder" style={{
					backgroundImage: `url(${present && present.image})`
				}}>
					{/*<div className="journal__placeholder-label-container">*/}
					{/*	<p className="journal__grid-2-feel">Present</p>*/}
					{/*</div>*/}
				</div>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${future && future.image})`
				}}>
					<div className="journal__placeholder-label-container">
						<p className="journal__grid-2-feel">Future</p>
					</div>
				</div>


			</div> : <div className="journal__grid-2-intro">
				<div className="journal__placeholder"
					 style={{
						 backgroundImage: `url(${past && past.image})`,
						 gridColumn: "2/3"
					 }}
				>
					{/*<div className="journal__placeholder-label-container">*/}
					{/*	<p className="journal__grid-2-feel">Past</p>*/}
					{/*</div>*/}
				</div>
			</div>
			}


			<div className="">
				<p className="journal__grid-2-subtitle">What I see and Feel <br/> [ Front Side ]</p>
			</div>


			<div className="">
				<p className="journal__grid-2-desc">The first card (to the left-hand side) is the psycho-spiritual energies that you have recently
					left
					or are leaving behind.</p>

				<p className="journal__grid-2-feel">{dualityFeelCard1}</p>
				{mode === 3 && <>
					<p className="journal__grid-2-desc">The second card (in the middle) is the energies you are identified with at this time
						unconsciously
						or consciously and what is making you feel, think and react as you do.</p>
					<p className="journal__grid-2-feel">{dualityFeelCard2}</p>
					<p className="journal__grid-desc">The third card (to the right-hand side) is your final outcome to your question, heralding the
						possibilities and choices of your <strong>HEALing</strong> .</p>
					<p className="journal__grid-2-feel">{dualityFeelCard3}</p>
				</>
				}
			</div>

		</div>
	)
};

const DualityCard2 = ({past, present, future, dualityFlippedFeel, element, mode}) => {

	return (
		<div className="journal__grid-3">
			<div className="">
				<p className="journal__grid-2-desc">The Interrelated Opposite on the underside of your chosen Duality Card may be more confronting to
					you than its uppermost side or make you feel uncomfortable. You may be less ready to consciously accept what is being said. You
					might feel that the image means nothing to you at all. But try to stay open. By merely looking at the image your subconscious mind
					will absorb its message. Have trust in this. Keep looking at it until you gain some insight or at least feel more comfortable.
					Reading the card description will also help you access what this image personally means to you.</p>

				<p className="journal__grid-2-feel">{dualityFlippedFeel}</p>

			</div>
			<div className="">
				<p className="journal__grid-2-subtitle">What I see and Feel <br/> [ Back Side ]</p>
			</div>
			<div className="journal__grid-3-intro">

				<div className="journal__placeholder" style={{
					backgroundImage: `url(${past && past.image})`
				}}>
					{/*<div className="journal__placeholder-label-container">*/}
					{/*	<p className="journal__grid-2-feel">Past</p>*/}
					{/*</div>*/}
				</div>

				{mode === 3 && <>
					<div className="journal__placeholder" style={{
						backgroundImage: `url(${present && present.image})`
					}}>
						{/*<div className="journal__placeholder-label-container">*/}
						{/*	<p className="journal__grid-2-feel">Present</p>*/}
						{/*</div>*/}
					</div>
					<div className="journal__placeholder" style={{
						backgroundImage: `url(${future && future.image})`
					}}>
						{/*<div className="journal__placeholder-label-container">*/}
						{/*	<p className="journal__grid-2-feel">Future</p>*/}
						{/*</div>*/}
					</div>
				</>
				}

				<div className="journal__grid-3-element-container">
					<div className="journal__grid-3-element-card" style={{
						backgroundImage: `url(${element.image})`
					}}/>
				</div>
			</div>
		</div>
	)
};

const CardDetails = ({past, flipped, value, mode}) => {
	return (
		<>
			<div className="journal__grid">

				<div className="journal__grid-intro">
					<div className="journal__placeholder-1" style={{
						backgroundImage: `url(${past.image})`
					}}/>
					{/*{mode === 3 && <p className="journal__grid-2-feel">{value}</p>}*/}

				</div>
				<div className="journal__grid-details">
					<p className="journal__grid-title">{past.name}<br/> ( No. {past.id} )</p>
					<p className="journal__grid-desc">Description</p>

					{
						past.description.map((element, index) =>
							<p className="journal__grid-desc" key={index}>{parse(element)}</p>
						)
					}


					<p className="journal__grid-desc">Equaliser</p>

					{
						past.equaliser.map((element, index) =>
							<p className="journal__grid-desc" key={index}>{parse(element)}</p>
						)
					}
				</div>
			</div>
			<div className="journal__grid">

				<div className="journal__grid-intro">
					<div className="journal__placeholder-1" style={{
						backgroundImage: `url(${flipped.image})`
					}}/>

				</div>
				<div className="journal__grid-details">
					<p className="journal__grid-title">{flipped.name}<br/> ( No. {flipped.id} )</p>
					<p className="journal__grid-desc">Description</p>
					{
						flipped.description.map((element, index) =>
							<p className="journal__grid-desc" key={index}>{parse(element)}</p>
						)
					}

					<p className="journal__grid-desc">Equaliser</p>

					{
						flipped.equaliser.map((element, index) =>
							<p className="journal__grid-desc" key={index}>{parse(element)}</p>
						)
					}
				</div>
			</div>
		</>
	)
}

const ShadowBall = ({past, present, future, mode}) => {

	return (
		<div className="journal__shadow">

			{mode === 3 ?
				<div className="journal__shadow-intro">

					<div className="journal__placeholder" style={{
						backgroundImage: `url(${past && past.image})`,
					}}/>
					<div className="journal__placeholder" style={{
						backgroundImage: `url(${present && present.image})`
					}}>
						<div className="journal__placeholder__ball"/>
					</div>
					<div className="journal__placeholder" style={{
						backgroundImage: `url(${future && future.image})`
					}}/>
				</div> :
				<div className="journal__shadow-intro">
					<div className="journal__placeholder" style={{
						backgroundImage: `url(${past && past.image})`,
						gridColumn: "2/3"
					}}>
						<div className="journal__placeholder__ball"/>
					</div>
				</div>
			}
			<div className="journal__shadow-description">
				<p className="journal__title">The Shadow Ball Selected. <br/> {mode === 3 ? present && present.name : past && past.name} (
					No. {mode === 3 ? present && present.id : past && past.id} )</p>

				<p className="journal__grid-desc" style={{marginTop: "3em"}}>When you shuffle the <strong> <em> Shadow Ball Card </em></strong> ,
					it will highlight the card and its energy that is currently or will be especially important
					to you. Maybe this energy is leaving or arriving or maybe you need to delve deeper into it.
					It is suggested that whichever card the Shadow Ball chooses is meditated upon until you have further clarity
				</p>

				<p className="journal__grid-desc">For example, if the secret side of you always longed to be more safe and trusting, the <strong>Shadow
					Ball Card</strong> will reveal this by emphasising (touching) a
					particular <strong> Duality</strong> or <strong> Wholeness </strong> card. Say, the <strong>Shadow Ball Card</strong> touches the
					Protection side of your card. This will help trigger a conscious understanding in you of the deeper meaning of this card or any
					other card or Wholeness cards you have chosen. Perhaps you need to be more aware of the idea of Protection or what this really
					means for you in terms of trust?
				</p>
			</div>
		</div>
	)

}

const Positivity = ({element}) => {

	return (
		<div className="journal__shadow">
			<div className="journal__shadow-intro-1">
				<div className="journal__placeholder-2" style={{
					backgroundImage: `url(${element.image})`
				}}/>
			</div>
			<div className="journal__shadow-description">
				<p className="journal__title" style={{marginBottom: "2em"}}>The Positive Karma Card of {element.name}<br/>(No. {element.id})</p>

				<PositivityDescription id={element.id}/>
			</div>
		</div>
	)
};

const Print = ({past, present, future, pastFlip, presentFlip, futureFlip, mode}) => {

	return (

		mode === 3 ?
			<div className="journal__print">
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${past})`
				}}/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${pastFlip})`
				}}/>
				<div/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${present})`,
				}}/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${presentFlip})`,
				}}/>
				<div/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${future})`
				}}/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${futureFlip})`
				}}/>
			</div> :
			<div className="journal__print">
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${past})`,
					gridColumn: "4/5"
				}}/>
				<div className="journal__placeholder" style={{
					backgroundImage: `url(${pastFlip})`,
					gridColumn: "5/6"
				}}/>
				<div/>
			</div>


	)
}
