import React, {useEffect, useState} from 'react';
import {animated, useSpring} from "react-spring";
import DualityCardArray from "../../../../Helpers/DualityCard";
import ShadowBall from "../../../../Assets/ShadowBall.png"
import getShuffledArray from "../../../../Helpers/RandomizeArray";
import "./ShowShadowCard.scss";
import {useStoreActions, useStoreState} from "easy-peasy";


const shadowBall = {id: 4, image: ShadowBall};


const ShowShadowCard = (props) => {
	const {play: {shadowFinalCards, dualityCardSelected, elementCardSelectedIndex, mode, shadowProcessAnimationOver, chakraColorCardSelected}} = useStoreState((state => state));
	const {play: {updateShadowFinalCards, updateShadowProcessOver}} = useStoreActions((state => state));

	const [chakraColor, setChakraColor] = useState([]);


	useEffect(() => {
		let array = [...[]];
		array = dualityCardSelected.map((element) => {
			const number = Math.round(Math.random());
			if (number) {
				if ([37, 38, 39, 40, 41, 42, 43, 44, 45].includes(element.id)) {
					console.log("Here");
					return DualityCardArray.filter((ele => ele.id === element.id))[0];
				} else if (element.id % 2 === 0) {
					return DualityCardArray.filter((ele => ele.id === element.id - 1))[0];
				} else {
					return DualityCardArray.filter((ele => ele.id === element.id + 1))[0];
				}
			} else {
				return element;
			}
		})
		array = [...getShuffledArray(array)];
		array = [...getShuffledArray(array)];
		mode === 2 ? setChakraColor([...chakraColorCardSelected, ...array, shadowBall]) : setChakraColor([...array, shadowBall]);
		updateShadowFinalCards(array);
	}, [updateShadowFinalCards, setChakraColor, mode])

	useEffect(() => {

		if (shadowProcessAnimationOver) {
			const timer = setTimeout(() => {
				updateShadowProcessOver(true);
			}, 1000)
			return () => {
				clearTimeout(timer);
			}
		}

	}, [shadowProcessAnimationOver])

	const fadeIn = useSpring({
		from: {
			opacity: 0,
		},
		to: {
			opacity: 1,
		},
		delay: 400,
		config: {duration: 1000}
	});

	const fadeInDescription = useSpring({
		from: {
			opacity: 0,
			transform: `translate3d(0px, -150px, 0)  scale(1)`,

		},
		to: {
			opacity: shadowProcessAnimationOver ? 0 : 1,
			transform: `translate3d(0px, 0px, 0) scale(1)`,
		},
		delay: shadowProcessAnimationOver ? 0 : 3000,
		config: {duration: 1000}
	});

	const fadeOutCard = useSpring({
		from: {
			opacity: 1,
			transform: `translate3d(0px, 0px, 0)  scale(1)`,

		},
		to: {
			opacity: shadowProcessAnimationOver ? 0 : 1,
			transform: shadowProcessAnimationOver ? `translate3d(-50px, 0px, 0) scale(1)` : `translate3d(0px, 0px, 0) scale(1)`,
		},
		config: {duration: 1000},
	});


	return (
		<div className="show-shadow">
			<animated.div className="show-shadow__details" style={fadeOutCard}>
				<animated.div className="show-shadow__element-card"
							  style={{backgroundImage: `url(${elementCardSelectedIndex[0].image})`, ...fadeIn}}>
					{
						chakraColor.map((element, index) => {
								return <DualityCard
									key={index}
									element={element}
									index={index}
								/>
							}
						)
					}
				</animated.div>
			</animated.div>

			<animated.div className="show-shadow__description" style={fadeInDescription}>
				<p className="show-shadow__title">Shadow Ball Selected </p>
				{mode === 3 ? <p className="show-shadow__title">{shadowFinalCards.length > 0 && shadowFinalCards[1].name} (
						No. {shadowFinalCards.length > 0 && shadowFinalCards[1].id} )</p>
					: <p className="show-shadow__title">{shadowFinalCards.length > 0 && shadowFinalCards[0].name} (
						No. {shadowFinalCards.length > 0 && shadowFinalCards[0].id} )</p>
				}

				<p className="show-shadow__desc">
					When you shuffle <strong>the Shadow Ball Card</strong> , it will highlight the card and its energy
					that is currently, or
					will be especially important to you. Maybe this energy is leaving or arriving or maybe you need to
					delve deeper into it. It is suggested that whichever card the Shadow Ball chooses is meditated upon
					until you have further clarity</p>

				<p className="show-shadow__desc">
					For example, if the secret side of you always longed to be more safe and trusting, the
					<strong>Shadow Ball Card </strong> will reveal this by emphasising (touching) a
					particular <strong> Duality </strong> or <strong> Wholeness </strong> card. Say, the
					Shadow Ball Card touches the Protection side of your card. This will help trigger a conscious
					understanding in you of the deeper meaning of this card or any other card or Wholeness cards you have
					chosen. Perhaps you need to be more aware of the idea of Protection or what this really means for you
					in terms of trust?
				</p>
			</animated.div>
		</div>
	);
}


const DualityCard = ({element, index, ...props}) => {

	const {play: {mode}} = useStoreState((state => state));

	const transitionCardX = (index) => {
		if (index === 0) {
			return 7 * 16;
		} else if (index === 1) {
			return 0;
		} else if (index === 2) {
			return -7 * 16;
		} else if (index === 3) {
			return 7 * 16;
		}
	}

	const rotate = useSpring({
		from: {
			transform: `translate3d(${transitionCardX(index)}px, ${index === 3 ? (8 * 16) : 0}px, ${20 - index * 2}px) scale(1.3)`,
			opacity: 0,
		},
		to: [
			{
				transform: `translate3d(0px, ${index === 3 ? (8 * 16) : 0}px, 0) scale(1)`,
				opacity: 1
			},
			{
				transform: `translate3d(0px, 0px, 0) scale(1)`,
				opacity: 1
			}],
		config: {duration: 1000},
		delay: (mode === 3 && index === 3) || (mode === 2 && index === 2) ? 1500 : 0
	});


	return (
		<animated.div
			className={`show-shadow__container  show-shadow__card-${(mode === 2) ? (index === 0 ? index + 1 : index === 1 ? index + 1 : index + 2) : index + 1}`}
			style={rotate}
			key={element.id}>
			<div className="show-shadow__card "
				 style={{
					 backgroundImage: `url(${element.image})`,
				 }}/>
			{/*<div className="show-shadow__card"*/}
			{/*	 style={{*/}
			{/*		 backgroundImage: `url(${element.image})`,*/}
			{/*		 transform: `rotateY(180deg)`*/}
			{/*	 }}/>*/}
		</animated.div>
	)
};

export default ShowShadowCard;
