import React, {useEffect, useState} from 'react';
import "../../Positivity/Positivity.scss";
import PlayHeader from "../../PlayHeader/PlayHeader";
import {animated, useTransition} from "react-spring";
import {useStoreActions, useStoreState} from "easy-peasy";
import facebook from "../../../../Assets/facebook.png";
import instagram from "../../../../Assets/instagram.png";
import web from "../../../../Assets/web.png";
import youtube from "../../../../Assets/youtube.png";

import getShuffledArray from "../../../../Helpers/RandomizeArray";
import PostivityCard from "../../../../Helpers/Positivity";
import PositivityDescription from "../../Positivity/PositivityDescription/PositivityDescription";
import cardBack from '../../../../Assets/cardBack.png';
import Shuffle from "../../../Animation/Shuffle/Shuffle";
import CardDeck from "../../../UI/CardDeck/CardDeck";

const cards = [...getShuffledArray(PostivityCard)];


const PositivityEnergy = (props) => {
	const [moreInfo, setMoreInfo] = useState(false);
	const {play: {updateHideButton, updateHidePlayFooter, updatePositivityCardSelected}} = useStoreActions((actions => actions));
	const {play: {positivityShuffleStart, positivityCardSelectComplete}} = useStoreState((state => state));

	const fadeInContainer = useTransition(positivityShuffleStart, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 1000}
	});
	const onClickButton = () => {
		if (!moreInfo) {
			setMoreInfo(true);
			updateHideButton(false);
		} else {
			setMoreInfo(false);
			updateHideButton(true);
		}
	}
	const [shuffleComplete, setShuffleComplete] = useState(false);
	const [shuffleOver, setShuffleOver] = useState(false);
	const [shuffleStart, setShuffleStart] = useState(false);
	useEffect(() => {
		const timeout = setTimeout(() => {
			if (positivityShuffleStart) {
				setShuffleStart(true);
			}
		}, 2000);
		return () => {
			clearTimeout(timeout);
		}
	}, [setShuffleStart, updateHidePlayFooter, positivityShuffleStart])
	useEffect(() => {
		if (shuffleComplete) {
			updateHidePlayFooter(false);
			const timeout = setTimeout(() => {
			}, 1000)
			return () => {
				clearTimeout(timeout);
			}
		}

	}, [shuffleComplete, updateHidePlayFooter])
	useEffect(() => {
		if (positivityCardSelectComplete) {
			const timeout = setTimeout(() => {
				setShuffleOver(true)
			}, 2000)
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [positivityCardSelectComplete])

	return (
		<div className="positivity__container">
			<PlayHeader/>
			{!shuffleStart ? fadeInContainer.map(({item, props: animation, key}) =>
				!item && <animated.div className="positivity" style={animation} key={key}>
					<div className="positivity__deck">
						<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5} disableAnimation={true}/>
					</div>
					<div className="positivity__details">
						<p className="main__title">The Positive Karma Card</p>
						<PositivityDetails moreInfo={moreInfo}/>
						<div className="w-20">
							<button className="btn btn-outline" onClick={onClickButton}>{moreInfo ? "BACK" : "NEXT"}</button>
						</div>
					</div>
				</animated.div>
			) : (shuffleStart && !shuffleOver) ? <Shuffle items={cards} cardLimit={1} shuffleCount={1}
														  title={"Pick a Positive Karma Card"}
														  subtitle={"by clicking on the card"}
														  alignment={"200px"}
														  setShuffleComplete={setShuffleComplete}
														  pushCard={updatePositivityCardSelected}
														  animationRewind={positivityCardSelectComplete}/>
				: <ShowPositivityCard/>
			}
		</div>
	)
}

export default React.memo(PositivityEnergy);

const PositivityDetails = React.memo(({moreInfo, ...props}) => {

	const fadeIn = useTransition(moreInfo, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});


	return (
		<div className="positivity__text-main">
			{
				fadeIn.map(({item, props: animation, key}) =>
					!item ?

						<animated.div className="positivity__text-container" key={key} style={animation}>
							<p className="positivity__subtitle">
								<em> Karma is the force of creation, where from all things have their life.</em> <br/>
								Bhagavad-Gita—8.3</p>

							<p className="positivity__description">
								The true meaning of Karma is action. It is the active principle of the Divine Play of God—what the Vedic tradition
								calls Leela.
							</p>

							<p className="positivity__description">
								Brahman, the Creator, transformed Himself into the world and weaved His creative spell of Power into the Universe.
								When we fall under the Power of His spell and think that all that we see is real in Itself, when we see ourselves as
								separately real and we cannot perceive the unity of Brahman, we fall into Maya (Illusion). Our world of Maya is one of
								constant change—like an unfolding dance of the Universe and the vital force of this movement is Karma.</p>
						</animated.div> :
						<animated.div className="positivity__text-container" key={key} style={animation}>
							<p className="positivity__description">
								In Karmic action, everything and everyone is connected with each other. In this play of Duality, as long as we are
								under the spell of illusion, and think that we are separate and real, its laws bind us. Whatever you think, feel and
								do will have a reaction, a resound and a reverberation.
							</p>

							<p className="positivity__description">
								At the moment you play with the <strong> Shadow Ball Card </strong> you consciously link the past, present, and the
								When you break the spell and wake up to your True Reality, when you experience Freedom from the play and know that you
								are not separate from God you will be free of Karma.

							</p>
							<p className="positivity__description">
								Until then, consciously make an effort to be mindful of your actions. Experience the positivity of good Karma.
								Experience the positivity of good action.
							</p>
						</animated.div>
				)
			}
		</div>

	)

})

const ShowPositivityCard = React.memo(() => {
	const {play: {positivityCardSelected, positivityProcessOver}} = useStoreState((state => state));


	const rotate = useTransition(true, null, {
		from: {
			transform: "translate3d(0px, 0px, 0px) scale(1) rotateY(0deg)",
			opacity: 0,
		},
		enter: [
			{
				transform: "translate3d(0px, 0px, 0px)  scale(1) rotateY(0deg)",
				opacity: 1,
			},
			{
				transform: "translate3d(0px, 0px, 0)  scale(1) rotateY(-180deg)",
				opacity: 1,
			},
			{
				transform: "translate3d(0px, 0px, 0)  scale(1.1) rotateY(-180deg)",
				opacity: 1,
			},
		],
		leave: {
			transform: "translate3d(0px, 0px, 0) scale(1.1) rotateY(-180deg)",
			opacity: 1,
		},
		config: {duration: 2000},
		delay: 1000,
	});

	return (
		<animated.div className="positivity">
			<div className="positivity__deck">
				{rotate.map(({item, props: animation, key}) =>
					item && <animated.div className="positivity-flip__container"
										  style={animation} key={key}>
						<div className="positivity-flip__card"
							 style={{
								 backgroundImage: `url(${cardBack})`,
							 }}/>
						<div className="positivity-flip__card"
							 style={{
								 backgroundImage: `url(${positivityCardSelected[0].image})`,
								 transform: `rotateY(180deg)`
							 }}/>
					</animated.div>
				)}

			</div>
			{!positivityProcessOver ? <div className="positivity__details-1">
				<p className="positivity__title">The Positive Karma Card of {positivityCardSelected[0].name} <br/> (No. {positivityCardSelected[0].id})
				</p>
				<PositivityDescription id={positivityCardSelected[0].id}/>
			</div> : <div className="positivity__details-1">
				<p className="element__title" style={{marginBottom: "2em"}}>This concludes your reading</p>
				<p className="element__description">Click below to download your card of the day.</p>
				<p className="element__description">If you would like to know more about Mynavati, her meditations, books, inspirations
					and wisdom, please click on the links below</p>
				<div className="journal__icons">
					<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634" target="_blank"
					   rel="noopener noreferrer">
						<img src={facebook} alt="facebook" className="journal__icon"/>
					</a>
					<a href="https://www.instagram.com/mynavatis_inspirations/"  target="_blank"
					   rel="noopener noreferrer">
						<img src={instagram} alt="instagram" className="journal__icon"/>
					</a>
					<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
					   rel="noopener noreferrer"
					>
						<img src={youtube} alt="youtube" className="journal__icon"/>
					</a>
					<a href="https://mynavati.com" target="_blank"
					   rel="noopener noreferrer">
						<img src={web} alt="web" className="journal__icon"/>
					</a>
				</div>
			</div>
			}
		</animated.div>
	)
});
