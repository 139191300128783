import React from 'react';
import './HomeDetails.scss';
import {animated, useSpring} from "react-spring";
import {useStoreActions} from "easy-peasy";

export const fadeInObject = {
	from: {
		opacity: 0
	},
	to: {
		opacity: 1
	},
	config: {duration: 2000}
}


const HomeDetails = ({detailsActive, detailsToggle, ...props}) => {


	return (
		<div className="home-details">
			{
				!detailsActive ?
					<Description detailsActive={detailsActive} detailsToggle={detailsToggle}/>
					: <Details detailsActive={detailsActive} detailsToggle={detailsToggle}/>
			}
		</div>
	);
}


export default HomeDetails;


const Description = ({detailsActive, detailsToggle, ...props}) => {

	const {updateDetailsAnimation} = useStoreActions(actions => actions['home'])

	const fadeInAnimation = useSpring({...fadeInObject});

	const textPopUpAnimation = useSpring(!detailsToggle ? {
		from: {
			opacity: 0,
			transform: `translate(0, 50px)`
		},
		to: [
			{
				opacity: 1,
				transform: `translate(0, 0px)`
			}
		],
		onRest: () => {
			updateDetailsAnimation(true)
		},
		config: {duration: 600},
		delay: 600
	} : {});

	const textPopDownAnimation = useSpring(detailsToggle ? {
		from: {
			opacity: 0,
			transform: `translate(0, -50px)`
		},
		to: [
			{
				opacity: 1,
				transform: `translate(0, 0px)`
			}
		],
		config: {duration: 600},
		delay: 600
	} : {});


	return (
		<animated.div className={"home-details__column"}
					  style={fadeInAnimation}>
			<animated.div style={textPopUpAnimation}>
				<h1 className="home-details__header">Welcome to Divine Play</h1>
				<p className="home-details__para">
					Use <strong>Divine Play</strong> to understand who you really are, what
					motivates you, what controls you, what attracts you, what limits you, your fears,
					your strengths and your truth. This is an
					opportunity to be more conscious, less restricted more complete, more Whole, to
					truly <strong>HEAL</strong> ” - Mynavati
				</p>
			</animated.div>
			<animated.div style={textPopDownAnimation}>
				<p className="home-details__para">
					It uses <strong>Archetypes</strong>, the original psychic imprinting on our
					Collective
					Unconscious
					and <strong>Dualities</strong> –
					interrelated opposites in our relative world, in order to understand the world
					within you.
				</p>
				<p className="home-details__para">
					Our world is a world of Duality and <strong>Divine Play</strong> shows you how to be
					creative
					and
					have
					fun with the
					energies of <strong>Dualities</strong>, so that you are not a victim of them, so
					that you
					can be
					a
					master of them and
					move you towards Healing, Wholeness and Freedom on whatever level you are ready for.
				</p>
			</animated.div>
		</animated.div>
	)
}

const Details = ({detailsActive, ...props}) => {
	const fadeInAnimation = useSpring({...fadeInObject});
	return (
		<animated.div className={"home-details__column"} style={{marginTop: '1em', ...fadeInAnimation}}>
			<p className="home-details__para">
				<strong>Divine Play</strong> represents ever-changing Maya, (Power of Illusion) manifested by the
				Creator as <strong>Divine Play</strong> – what the Vedic tradition refers to as
				God’s <strong>Leela</strong> or God’s Sport.
			</p>
			<p className="home-details__para">
				It will show you what <strong>Archetypes</strong> and <strong>Dualities</strong> you
				are attached to and bound by and it will speak to your unconscious and conscious mind. It will help you
				to know what is
				motivating you, what is holding you back and what within you needs to come into consciousness. It will
				reveal
				your past and present psyche and it will help you to make decisions as well as choose happier outcomes
				for you.
			</p>

			<p className="home-details__para">It will appear to know you as if it is intimately related to you – In
				fact, it is a reflection of YOU.
			</p>

			<p className="home-details__para"><strong> Divine Play</strong> has been designed for personal
				consultation. But, it can be helpful for couples to understand one another better and it is especially
				good for psychotherapists and their clients in helping to gain more insight into the
				subconscious and unconscious levels motivating the individual.
			</p>

			<p className="home-details__para">All that is required to participate, is openness, honesty and
				courage, to allow the continual process of life and its changes, its re-routing, its beginnings and
				endings…your
				eternal journey to unfold.</p>
		</animated.div>
	)

}


