import React, {useEffect, useState} from 'react';
import {animated, useSpring, useTransition} from "react-spring";
import "./DualityFlip.scss";
import {useStoreActions, useStoreState} from "easy-peasy";
import DualityCardArray from "../../../Helpers/DualityCard";
import flip from "../../../Assets/flip.png"

import {DualityCardDetails} from "../Duality/ShowDualityCard/ShowDualityCard";


const DualityFlip = () => {

	const {play: {dualityCardFlipped, dualityCardSelected, elementCardSelectedIndex, dualityFlippedFeelAdded, mode, chakraColorCardSelected}} = useStoreState((state => state))

	const {play: {updateHideButton}} = useStoreActions((actions => actions))

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		config: {duration: 1000},
	});
	const fadeInTransition = useTransition(dualityCardFlipped, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});

	const [rotateIndex, setRotateIndex] = useState(mode === 3 ? 0 : 1);
	const [clickedCard, setClickedCard] = useState(-1);
	const [clickedCardIndex, setClickedCardIndex] = useState(-1);

	const [counter, setCounter] = useState([]);

	useEffect(() => {

		if (counter.length === 3 && mode === 3) {
			updateHideButton(false);
			setCounter([]);

		} else if (counter.length === 1 && mode === 2) {
			updateHideButton(false);
			setCounter([]);
		}


	}, [counter, updateHideButton, setCounter, mode]);

	return (
		<div className="dual-flip">
			<div className="dual-flip__details">
				<animated.div className="dual__element-card"
							  style={{...fadeIn, backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}>
					{
						mode === 3 ? dualityCardSelected.map((element, index) => {
								return <DualityCard
									key={element.id}
									element={element}
									rotateIndex={rotateIndex}
									setRotateIndex={setRotateIndex}
									setClickedCardIndex={setClickedCardIndex}
									clickedCardIndex={clickedCardIndex}
									index={index}
									setCounter={setCounter} counter={counter}

									clickedCard={clickedCard} setClickedCard={setClickedCard}
								/>
							}
							) :
							[...chakraColorCardSelected, ...dualityCardSelected].map((element, index) => {
								return <DualityCard
									key={element.id}
									element={element}
									rotateIndex={rotateIndex}
									setRotateIndex={setRotateIndex}
									setCounter={setCounter} counter={counter}
									clickedCardIndex={clickedCardIndex}
																		setClickedCardIndex={setClickedCardIndex}

									index={index}
									clickedCard={clickedCard} setClickedCard={setClickedCard}
								/>
							})
					}

				</animated.div>
			</div>

			{!dualityFlippedFeelAdded &&
			fadeInTransition.map(({item, props: animation, key}) =>
				!item ? <animated.div className="dual-flip__description" key={key} style={animation}>
						<p className="dual-flip__title">Turn Over the Cards</p>
						<p className="dual-flip__subtitle">This is a critical part of Divine Play. The first images are only part of the whole picture
							of what you are identified with. When the images are turned over, the cards will reveal what you are pushing away from you
							or what is less consciously available to you. Your willingness to identify and integrate both set of images will bring
							you increased awareness of you, HEAL you and help you to move on.</p>
						<p className="dual-flip__subtitle">Turn over the cards by clicking on the flip icon below the card.</p>
					</animated.div>
					:
					<animated.div className="dual-flip__description"
								  key={key}
								  style={{...animation}}>
						<DualityFlipFeel/>
					</animated.div>
			)
			}

			{/*{dualityFlippedFeelAdded &&  <DualityCardDetails element={clickedCard}/>}*/}
			{
				dualityFlippedFeelAdded && <animated.div className="dual-flip__description">
					<DualityCardDetails element={clickedCard}/>
				</animated.div>
			}

		</div>
	);
}


export default DualityFlip;
const DualityCard = ({
						 setRotateIndex, element, index, rotateIndex, setClickedCard,
						 setCounter, counter, setClickedCardIndex, clickedCardIndex
					 }) => {

	const {play: {dualityFlippedFeelAdded, mode}} = useStoreState((state => state));

	const {play: {updateHidePlayFooter}} = useStoreActions((actions => actions));

	const nextImage = () => {
		if ((mode === 3) || (mode === 2 && index === 1)) {
			if ([37, 38, 39, 40, 41, 42, 43, 44, 45].includes(element.id)) {
				return DualityCardArray.filter((ele => ele.id === 46))[0];
			} else if (element.id % 2 === 0) {
				return DualityCardArray.filter((ele => ele.id === element.id - 1))[0];
			} else {
				return DualityCardArray.filter((ele => ele.id === element.id + 1))[0];
			}
		} else if (mode === 2 && index === 0) {
			return element;
		}
	}
	const [rotate, setRotate] = useState(false);

	const rotateAnim = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) scale(1) rotateY(0deg)",
			opacity: 1,
		},
		to: {
			transform: `translate3d(0px, 0px, 0) scale(${index === clickedCardIndex ? 1.2 : 1}) rotateY(${rotate ? -180 : 0}deg)`,
			opacity: 1
		},
		config: {duration: 1000},
	});

	const fadeInTransition = useTransition(((mode === 3 && rotateIndex === index) || (mode === 2 && index === 1)), null, {
		from: {
			transform: "translate3d(0px, -100px, 0px)",
			opacity: 0,
		},
		enter: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: 1,
		},
		leave: {
			transform: "translate3d(20px, 0px, 0px)",
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	});


	return (
		<div onClick={() => {
			if (mode === 3 && dualityFlippedFeelAdded) {
				setClickedCard(nextImage(element.id));
				setClickedCardIndex(index);
				!counter.includes(index) && setCounter([...counter, index]);

			} else if (mode === 2 && dualityFlippedFeelAdded) {
				!counter.includes(index) && setCounter([...counter, index]);
				setClickedCardIndex(index);

				(index === 1) && setClickedCard(nextImage(element.id))
			}
		}}>
			<animated.div className={`dual-flip__container  dual-flip-${(mode === 2 && index === 1) ? 3 : index + 1}`}
						  key={element.id}
						  style={rotateAnim}
			>
				<div className="dual-flip__card "
					 style={{
						 backgroundImage: `url(${element.image})`,
					 }}/>
				<div className="dual-flip__card"
					 style={{
						 backgroundImage: `url(${nextImage(element.id).image})`,
						 transform: `rotateY(180deg)`
					 }}
				/>
			</animated.div>

			{(!rotate && !dualityFlippedFeelAdded) && fadeInTransition.map(({item, props: animation, key}) =>
				item && <animated.img src={flip}
									  key={key}
									  alt="Click to Flip" style={animation}
									  onClick={() => {
										  setRotate(true);
										  setRotateIndex(index + 1);
										  if (mode === 3 && index === 2) {
											  updateHidePlayFooter(false);
											  // updateDualityFlippedFeelCheck(true);
										  } else if (mode === 2 && index === 1) {
											  updateHidePlayFooter(false);
											  // updateDualityFlippedFeelCheck(true);
										  }
									  }}
									  className={`dual-flip__icon dual-flip__${(mode === 2 && index === 1) ? 3 : index + 1}`}/>
			)
			}
		</div>
	)
};

const DualityFlipFeel = React.memo(() => {
	const {play: {dualityFlippedFeel}} = useStoreState((state => state));
	const {play: {updateDualityFlippedFeel}} = useStoreActions((actions => actions));

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},],
		delay: 1000,
		config: {duration: 1000},
	});

	return (
		<animated.div className="w-100 h-100">
			<p className="dual-flip__title">The Interrelated Opposites</p>
			<p className="dual-flip__subtitle">The Interrelated Opposite on the underside of your chosen Duality Card may be more confronting to you
				than its uppermost side or make you feel uncomfortable. You may be less ready to consciously accept what is being said. You might feel
				that
				the image means nothing to you at all. But try to stay open. By merely looking at the image your subconscious mind will absorb its
				message. Have trust in this. Keep looking at it until you gain some insight or at least feel more comfortable. Reading the card
				description will also help you access what this image personally means to you.</p>
			<p className="dual-flip__subtitle"
			   style={{marginBottom: "0"}}> Look at the images and listen to what they are personally saying to you. Reflect on any feelings
				arising in
				you and take note of them.
			</p>

			<div className="dual-flip__input-container">

				<animated.textarea name="question"
								   style={transitWidth}
								   className="dual-flip__input"
								   placeholder="Start typing..."
								   value={dualityFlippedFeel}
								   maxLength={130}
								   onChange={
									   (event) => {
										   updateDualityFlippedFeel(event.target.value)
									   }}
				/>
				<animated.p className="dual-flip__input-counter "
							style={{...transitWidth, width: "auto"}}>{dualityFlippedFeel.length}/130
				</animated.p>
			</div>
		</animated.div>

	)
});

