import React, {useEffect, useRef, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {animated, useSpring, useTransition} from "react-spring";
import PlayHeader from "../PlayHeader/PlayHeader";
import "../ChakraColor/ChakraColor.scss"
import parse from 'html-react-parser';

function ChakraColorTimeline(props) {
	const {play: { shadowFinalCards, mode, elementCardSelectedIndex,
		chakraTimelineOver, chakraTimelineActive,
		chakraColorCardSelected}} = useStoreState((state => state));

	const {play: {updateTimeline, disableTimeline}} = useStoreActions((state => state));


	const fadeOut = useSpring({
		from: {
			opacity: 0
		}, to: {
			opacity: (chakraTimelineActive && !chakraTimelineOver) ? 1 : chakraTimelineOver ? 0 : 0,
		},
		config: {duration: 1000},
	});

	useEffect(() => {
		if (chakraTimelineOver) {
			const timer = setTimeout(() => {
				updateTimeline({id: 2, value: false});
				disableTimeline({id: 2, value: false});
			}, 1000)
			return () => {
				clearTimeout(timer);
			}
		}

	}, [chakraTimelineOver, updateTimeline])


	return (
		<>
			<PlayHeader/>
			<animated.div className="chakra" style={fadeOut}>
				<div className="chakra__element-details">
					<animated.div className="chakra__element-card"
								  style={{backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}>
						<animated.div className={`chakra-flip__container`}>
							<div className="chakra-flip__card"
								 style={{
									 backgroundImage: `url(${chakraColorCardSelected[0].image})`,
								 }}/>
						</animated.div>
					</animated.div>
				</div>
				<ChakraDescription/>
			</animated.div>
		</>
	);
}


const ChakraDescription = ({...props}) => {


	const descRef = useRef();
	const presRef = useRef();
	const [coords, setCoords] = useState({});

	const {play: {chakraColorCardSelected}} = useStoreState((state => state))

	useEffect(() => {
		if (presRef.current && descRef.current) {
			const {x: prescX, y: prescY} = presRef.current.getBoundingClientRect();
			const {x: descX} = descRef.current.getBoundingClientRect();
			setCoords({
				...{
					prescX, prescY, descX
				}
			})
		}
	}, []);

	const [nav, setNav] = useState(false);


	const fadeIn = useTransition(nav, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	})





	return (
		<div className="chakra-element__description">
			<animated.div style={{gridColumn: "1/2", gridRow: "1/2"}}>
				<div>
					<p className="chakra__title w-75">{`
									The ${chakraColorCardSelected[0].name} Chakra Card - Represents The ${chakraColorCardSelected[0].represents} Chakra`}</p>
					<div className="chakra__nav-container" style={{margin: "2em 0"}}>
						<p className="chakra__sub-text cursor-pointer"
						   ref={descRef}
						   onClick={() => {
							   setNav(false)
						   }}>Description</p>
						<p className="chakra__sub-text cursor-pointer"
						   ref={presRef}

						   onClick={() => {
							   setNav(true)
						   }}>Prescription for Healing</p>


						{coords && <div className="chakra__nav-slide" style={{
							top: `${coords.prescY + 10}px`,
							left: !nav ? `${coords.descX}px` : `${coords.prescX}px`,

						}}/>}
					</div>
					<div className="chakra__nav">
						{
							fadeIn.map(({item, props: animation, key}) =>
								!item ? <animated.div className="chakra__nav-content" key={key} style={animation}>
										{chakraColorCardSelected[0].description.map((element, index) => <p
											className="chakra__description" key={index}>{parse(element)}</p>)}
									</animated.div>
									: <animated.div className="chakra__nav-content" key={key} style={animation}>
										{chakraColorCardSelected[0].healing.map((element, index) => <p
											className="chakra__prescription" key={index}>{parse(element)}</p>)}
									</animated.div>
							)
						}
					</div>
				</div>
			</animated.div>

		</div>
	)
}


export default ChakraColorTimeline;
