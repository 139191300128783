import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {BrowserRouter} from "react-router-dom";
import {createStore, StoreProvider} from "easy-peasy";

import model from './Models/index';

const store = createStore(model);





ReactDOM.render(
	<React.StrictMode>
		<BrowserRouter>
			<StoreProvider store={store}>
				<App/>
			</StoreProvider>
		</BrowserRouter>
	</React.StrictMode>,
	document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//:TODO
// - open in new tab for nav buttons
// - Add home button
// - add scroll bar which stays
// - areLeaving needs space
// - Choosing an element card - These energies will be revealed ... element_card
// - I have entered my question to Take to the next step (one card and three card)
// - One card - Enter your question - left side content is twice
// - You picked water instead of your pick water
// - for example is written thrice in shadow section
// - One card method after picking the cards remove the first screen - just have the second one.
// - text vanishing in duality
// - Front of the wholeness card content typed was not seen in journal ****
// - Add chakra card besides shadow ball to the left
// - Another reading button along with download card in energies



//:TODO - Qualities of air has fire written ***
// - top bar floating ****
// - Read privacy policy for issues ***




// - add counter for typing of max length

// - Show counter for inputs


// - 37 to 45 all back cards void (From Om to cosmic dance)
// - Double checking of all the content and their connection with the cards
