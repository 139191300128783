import React, {useEffect, useState} from 'react';
import './Duality.scss';
import CardDeck from "../../UI/CardDeck/CardDeck";
import {animated, useSpring, useTransition} from "react-spring";
import {useStoreActions, useStoreState} from "easy-peasy";
import DualityThreeCard from "./DualityThreeCard/DualityThreeCard";
import PlayHeader from "../PlayHeader/PlayHeader";
import DualityShuffle from "./DualityShuffle/DualityShuffle";
import ShowDualityCard from "./ShowDualityCard/ShowDualityCard";
import DualityFlip from "../DualityFlip/DualityFlip";

const Duality = (props) => {


	const [moreInfo, setMoreInfo] = useState(false);
	const [moreInfoClicked, setMoreInfoClicked] = useState(false);
	const [threeCardActive, setthreeCardActive] = useState(false);

	const {play: {updateHideButton}} = useStoreActions((actions => actions));
	const {play: {dualityThreeCard, dualityShuffleScreen, showSelectedDualityCard, dualityFlipCardScreen, mode}} = useStoreState((state => state));

	const fadeInContainer = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: dualityThreeCard ? 0 : 1
		},
		config: {duration: 1000}
	});

	useEffect(() => {
		if (dualityThreeCard) {
			const timer = setTimeout(() => {
				setthreeCardActive(true);
			}, 1000)

			return (() => {
				clearTimeout(timer);
			})
		}
	}, [dualityThreeCard, setthreeCardActive])


	const fadeIn = useTransition(moreInfo, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});

	const onClickButton = () => {

		setMoreInfoClicked(true);
		if (!moreInfo) {
			setMoreInfo(true);
			updateHideButton(false);
		} else {
			setMoreInfo(false);
			updateHideButton(true);
		}
	}

	return (

		<div className="duality__container">
			<PlayHeader/>

			{
				(!dualityShuffleScreen && mode === 3) &&

				<>
					{
						!threeCardActive ?
							<animated.div className="duality" style={fadeInContainer}>
								<div className="duality__deck">
									<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5}
											  disableAnimation={moreInfoClicked}/>
								</div>
								<div className="duality__details">
									<p className="main__title">The Duality and Wholeness Cards </p>

									<div className="duality__text-main">
										{
											fadeIn.map(({item, props: animation, key}) =>
												!item ?

													<animated.div className="duality__text-container" key={key} style={animation}>
														<p className="duality__description">There are 18 double-sided Duality cards and 9 Wholeness
															cards
															double-sided with the Void from which to choose from. These are shuffled together. No card
															is
															better than another card. All contain interrelated opposite energies reflective of our
															reality in
															duality. For example, in our reality we have love and its
															opposite, hate. We have fear and its opposite, courage.
														</p>

														<p className="duality__description">
															Wholeness Cards generally reflect a point in life where there is a completion or a
															culmination.
															Duality is seemingly absent. But nothing is permanent and all energies change and so it is
															important that we take note of the card’s opposite energy, The Void, which is a
															transitional
															period from completeness to the birth of
															something new.</p>
													</animated.div> :
													<animated.div className="duality__text-container" key={key} style={animation}>
														<p className="duality__description">
															Wholeness Cards generally reflect a point in life where there is a completion or a
															culmination.
															Duality is seemingly absent. But nothing is permanent and all energies change and so it is
															important that we take note of the card’s opposite energy, The Void, which is a
															transitional
															period from completeness to the birth of something new.</p>
													</animated.div>
											)
										}
									</div>
									<div className="w-20">
										<button className="btn btn-outline" onClick={onClickButton}>{moreInfo ? "BACK" : "NEXT"}</button>
									</div>
								</div>
							</animated.div>
							: <DualityThreeCard animation={{padding: "0"}}/>
					}
				</>
			}

			{(!dualityShuffleScreen && mode === 2) &&
			<> {
				!dualityThreeCard &&
				<animated.div className="duality" style={fadeInContainer}>
					<div className="duality__deck">
						<CardDeck width={"13rem"} height={"18rem"}
								  radius={"1.3em"} offset={20}
								  number={5} disableAnimation={true}/>
					</div>
					<div className="duality__details">
						<p className="main__title">The Duality and Wholeness Cards </p>

						<div className="duality__text-main">
							{
								fadeIn.map(({item, props: animation, key}) =>
									!item ?

										<animated.div className="duality__text-container" key={key} style={animation}>
											<p className="duality__description">There are 18 double-sided Duality cards and 9 Wholeness cards
												double-sided with the Void from which to choose from. These are shuffled together. No card is
												better
												than another card. All contain interrelated opposite energies reflective of our reality in
												duality.
												For example, in our reality we have love and its opposite, hate. We have fear
												and its opposite, courage.
											</p>

											<p className="duality__description">
												Wholeness Cards generally reflect a point in life where there is a completion or a culmination.
												Duality is seemingly absent. But nothing is permanent and all energies change and so it is
												important that we take note of the card’s opposite energy, The Void, which is a
												transitional period from completeness to the birth of something
												new.</p>
										</animated.div> :
										<animated.div className="duality__text-container" key={key} style={animation}>
											<p className="duality__description">
												Wholeness Cards generally reflect a point in life where there is a completion or a culmination.
												Duality is seemingly absent. But nothing is permanent and all energies change and so it is
												important that we take note of the card’s opposite energy, The Void, which is a
												transitional period from completeness to the birth of something new.</p>
										</animated.div>
								)
							}
						</div>
						<div className="w-20">
							<button className="btn btn-outline" onClick={onClickButton}>{moreInfo ? "BACK" : "NEXT"}</button>
						</div>
					</div>
				</animated.div>
			}
			</>
			}
			{(dualityShuffleScreen && !showSelectedDualityCard) && <DualityShuffle/>}
			{(showSelectedDualityCard && !dualityFlipCardScreen) && <ShowDualityCard/>}
			{(dualityFlipCardScreen) && <DualityFlip/>}


		</div>
	);
}


export default React.memo(Duality);
