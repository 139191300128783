import React from 'react';
import "./Disclaimer.scss";

const Miscellaneous = ({selection}) => {

	return (
		(selection === 1) ? <Disclaimer/> : (selection === 2) ? <Privacy/> : <Copyrights/>
	);
}

const Disclaimer = () => {
	return (
		<div className="disclaimer">
			<div className="disclaimer__para">
				<p className="disclaimer__title">Disclaimer</p>
				<p className="disclaimer__description"><strong> Divine Play</strong> is suitable for age 18 years and over.
					Those under the age of 18 years using Divine Play should do so under parental/therapist supervision and guidance.</p>
				<p className="disclaimer__description"><strong>Divine Play</strong> is not a game. It is a psychological and spiritual therapeutic
					insight tool.</p>
				<p className="disclaimer__description"> The writer, illustrator, web application/digital publisher, Mynavati does not take any
					responsibility for reactions, or lack of reactions to this work. Readers and users of Divine Play do so at their own risk. It
					is recommended that readers and users with current mental problems or a history of mental problems or who are undergoing severe
					emotional stress should seek the advice of a qualified physician or therapist before and during engaging in Divine Play.
				</p>
			</div>
		</div>
	)
}

const Copyrights = () => {
	return (
		<div className="disclaimer">
			<div className="disclaimer__para">
				<p className="disclaimer__title">Copyrights</p>

				<p className="disclaimer__description">
					Original idea and author © <strong>Mynavati 2003 </strong> <br/>
					Illustrations © <strong> Craig McQueenie 2003 </strong> <br/>
					Website Divine Play © Mynavati 2020 <br/>
					All rights reserved</p>

				<p className="disclaimer__description">
					No part of this website may be reproduced, stored in a retrieval system or transmitted in any form or by any means – Electronic,
					mechanical, photocopying, recording or otherwise without the prior permission of the publisher, Mynavati.</p>
			</div>
		</div>
	)
}

const Privacy = () => {
	return (
		<div className="disclaimer">
			<div className="disclaimer__para" style={{marginBottom: "3em"}}>
				<p className="disclaimer__title">Privacy Policy</p>

				<p className="disclaimer__description"> This website, www.mynavati.com/divineplay, does not have cookies of its own and does not use
					any other technique by which to gather information about individual visitors.

				</p>
				<p className="disclaimer__description">When you use Divine Play, you may enter some personal information in the ‘question’ and
					‘journal’ field. This is sent to the next web page so it can be displayed as part of your reading.But it is not stored by
					www.mynavati.com/divineplay in anyway (which means that if you want to keep your reading, you need to make a note of it or
					download the pdf provided).</p>
				<p className="disclaimer__description">
					We do not store your data. We do not use cookies to collect data. We physically cannot. We have nowhere to store it.
					Nor does www.mynavati.com/divineplay have access to any gathered information about specific users via another web service.
				</p>
			</div>
		</div>
	)
}


export default Miscellaneous;
