import React, {useEffect} from 'react';
import './About.scss';
import CardDeck from "../UI/CardDeck/CardDeck";
import {useStoreActions} from "easy-peasy";


const knowledge = [
	{
		id: 0,
		name: "Dedication and Acknowledgment",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/1%20Dedication%20_%20Acknowledgement.pdf?alt=media&token=21b805cf-3df1-4aa0-84a0-d00ea7b3976b"
	}, {
		id: 1,
		name: "Seed of Divine Play and The Essence of Divine Play",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/2%20%20Seed%20of%20Divine%20Play%20and%20The%20Essence%20of%20Divine%20Play%20for%20About%20Section%20-%20Backend%20Proofed.pdf?alt=media&token=c8fb0e88-f39e-4e43-ab25-7abea1a6abe0"

	}, {
		id: 2,
		name: "The Shadow Ball card",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/3%20The%20Shadow%20Ball%20Card.pdf?alt=media&token=520a67e8-d47d-4459-b3e9-35db9aa94326"

	}, {
		id: 3,
		name: "The Elements",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/4%20The%20Elements.pdf?alt=media&token=6de39ffd-d87e-4ad1-bd2b-afdb4900ce0f"

	},
	{
		id: 9,
		name: "The Chakra Colour Cards",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/5%20The%20Chakra%20Colour%20Cards.pdf?alt=media&token=ebd3b373-b1a1-4d5a-8fc9-6839e514414b"
	}, {
		id: 4,
		name: "The Masculine and Feminine Dualities",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/6%20The%20Masculine%20and%20Feminine%20Dualities%20new%20E%20(1).pdf?alt=media&token=250a79a7-59fd-4d9b-87eb-5ae212c2a25e"
	}, {
		id: 5,
		name: "The Inspiration and Opposition Dualities",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/7%20The%20Inspiration%20and%20Opposition%20Dualities.pdf?alt=media&token=d8675796-3158-4c0e-85c7-c056de258c0f"
	}, {
		id: 6,
		name: "The Way and Transformation Dualities",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/8%20The%20Way%20and%20Transformation%20Dualities.pdf?alt=media&token=9af6d3b9-5a87-48ce-abda-1376f6b9c413"
	}, {
		id: 7,
		name: "Wholeness and The Void",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/9%20Wholeness%20and%20The%20Void.pdf?alt=media&token=7eeabc8a-4b6a-4a7b-9e3c-4e72b92e05e8"
	}, {
		id: 8,
		name: "The Positive Karma Card",
		link: "https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/10%20Positive%20Karma%20Cards.pdf?alt=media&token=2317b224-1d77-43e3-9e19-9acfbdae8dc8"
	}
]


const About = (props) => {

	const {play: {resetPlay}} = useStoreActions((actions => actions));

	useEffect(() => {
		resetPlay();
	}, [resetPlay])


	return (
		<div className="about__container">
			<div className="about__heal">
				{
					knowledge.map(({id, name, link}) => {
						return <a rel="noopener noreferrer"
								  href={link}
								  key={id}
								  target="_blank">
							<CardDeck width={"6rem"} height={"9rem"} number={5} radius={"0.7em"} key={id}/>
							<p className="about__label">{name}</p>
						</a>
					})
				}
			</div>

			<div className="about__description">
				<h1 className="about__title">Knowledge Decks</h1>
				<p className="about__subtitle">Click on the deck on the left to read more about Divine Play</p>
			</div>
		</div>
	);
}

export default About;
