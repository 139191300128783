import React, {useEffect} from 'react';
import './Reading.scss';
import CardDeck from "../UI/CardDeck/CardDeck";
import {useStoreActions} from "easy-peasy";
import Toast from "../UI/Toast/Toast";




const Reading = (props) => {

	const {play: {resetPlay}} = useStoreActions((actions => actions));

	useEffect(() => {
		resetPlay();
	}, [resetPlay]);

	return (
		<div className="reading__container">
			<div className="reading__heal">

				<div className="">
					<a
						rel="noopener noreferrer"
						href="https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/Energies%20of%20the%20Day%20-%20%20How%20To%20Work%20With%20Your%20Reading%20Section.pdf?alt=media&token=70ff3370-2f92-4bb3-b443-69709ec55532"
						target="_blank"><CardDeck width={"10rem"} height={"13.5rem"} number={5}/></a>
					<p className="reading__label">Energies of the Day</p>
				</div>

				<div className="">
					<a
						rel="noopener noreferrer"
						href="https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/Using%20One%20Card%20to%20Heal%20-%20%20How%20To%20Work%20With%20Your%20Reading%20Section.pdf?alt=media&token=e6f5061c-d658-4ef9-9f7f-eec9f70ba8aa"
						target="_blank"><CardDeck width={"10rem"} height={"13.5rem"} number={5}/></a>
					<p className="reading__label">Using One Card to heal</p>
				</div>

				<div className="">
					<a
						rel="noopener noreferrer"
						href="https://firebasestorage.googleapis.com/v0/b/divine-play.appspot.com/o/Using%20Three%20Cards%20to%20Heal%20-%20How%20To%20Work%20With%20Your%20Reading%20Section.pdf?alt=media&token=704ac82f-f3e8-498c-93b3-0374210f5c56"
						target="_blank"><CardDeck width={"10rem"} height={"13.5rem"} number={5}/></a>
					<p className="reading__label">Using Three Cards to heal</p>
				</div>
			</div>
			<div className="reading__description">
				<h1 className="reading__title">How to work with your reading</h1>
				<p className="reading__subtitle">The How to play section will guide you, as you work through your
					personal Divine Play reading.</p>
				<p className="reading__text">Select the Divine Play method you have chosen by clicking on the deck of
					cards.</p>
			</div>

		</div>
	);
}

export default Reading;
