import React from 'react';
import './Footer.scss'
import {useStoreState} from "easy-peasy";
import {NavLink, useLocation} from "react-router-dom";

const Header = (props) => {

	const {detailsAnimation, cardAnimation} = useStoreState(state => state.home)
	const location = useLocation();

	return (
		((cardAnimation && detailsAnimation) || location.pathname !== '/') &&
		<footer className="footer card-animation">
			<div className="footer__share">
				<a className="footer__text cursor-pointer"
				   href="https://www.addtoany.com/share#url=https%3A%2F%2Fdivine-play.web.app"
				   target="_blank"
				   rel="noopener noreferrer">SHARE</a>
				<NavLink className="footer__text cursor-pointer" to={"/disclaimer"}>DISCLAIMER</NavLink>
				<NavLink className="footer__text cursor-pointer" to={"/privacy"}>PRIVACY POLICY</NavLink>
			</div>

			<NavLink className="footer__text" to={"/copyrights"}>COPYRIGHTS. 2020 | ALL RIGHTS RESERVED TO MYNAVATI.COM</NavLink>
		</footer>

	);
}

export default Header;
