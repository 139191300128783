import React from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import './Question.scss';
import {animated, useSpring, useTransition} from "react-spring";
import PlayHeader from "../PlayHeader/PlayHeader";

const Question = ({...props}) => {
	const {play: {questionAdded, question, mode}} = useStoreState((state => state));
	const {play: {updateQuestion}} = useStoreActions((actions => actions));


	const fadeIn = useTransition(questionAdded, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		delay: 1000,
		config: {duration: 800}
	})


	const transitRight = useSpring({
		from: {
			transform: "translate3d(-30px, 0, 0)"
		},
		to: {
			transform: "translate3d(0px, 0, 0)"
		},
		delay: 600,
		config: {duration: 500}
	})

	const transitLeft = useSpring({
		from: {
			transform: "translate3d(30px, 0, 0)"
		},
		to: {
			transform: "translate3d(0px, 0, 0)"
		},
		delay: 600,
		config: {duration: 500}
	})


	return (
		<>
			<PlayHeader/>
			{
				fadeIn.map(({item, props: animation, key}) => {
					return <animated.div className="question__container"
										 style={animation}
										 key={key}>
						{mode === 3 && <animated.div className="question__example-container"
													 style={transitRight}>
							<p className="question__example-title">Examples</p>
							<p className="question__example">
								<em> Do not say </em> Will I get married next week? Rather, ask, whether you
								are ready to be married and if so, what do you need to embrace, and what do you need to
								resolve in order to marry?</p>
							<p className="question__example">
								<em> Do not say </em> Will I get a raise next week. Rather, ask what you need to embrace and what you need to let go
								of to earn more money.</p>
						</animated.div>}

						{
							mode === 2 && <animated.div className="question__example-container" style={transitRight}>
								<p className="question__example-title">Examples</p>
								<p className="question__description" style={{margin: "1em 0 2em"}}>Be careful to not choose a question that is closed
									or
									seeking a prediction. Make your question open ended and about your present energies and
									feelings.
									What you think you need, or do not need. What you want or do not want. What you think
									your
									problem is. Be open to what <strong>Divine Play</strong> will show you about yourself
									and your life.
								</p>
								<p className="question__description">What is underlying your feelings and your
									needs?</p>
								<p className="question__description">What is the basis for your fears?</p>
								<p className="question__description">What do you really want?</p>
								<p className="question__description">What do you really need?</p>
								<p className="question__description">What do you need to make conscious?</p>

							</animated.div>
						}

						<animated.div className="question__sub-container"
									  style={transitLeft}
						>
							<p className="question__title">Enter Your Question</p>

							{mode === 3 && <p className="question__description">Be careful to not choose a question that is closed or
								seeking a prediction. Make your question open ended and about your present energies and
								feelings.
								What you think you need, or do not need. What you want or do not want. What you think
								your
								problem is. Be open to what <strong>Divine Play</strong> will show you about yourself
								and your life.
							</p>}

							{
								mode === 2 && <p className="question__description">
									The one-card method is helpful if you have a specific question or if you want to choose daily, weekly or monthly
									card. Your question may be emotional, physical, spiritual or psychological. The card will give insight into your
									question and concern.
								</p>
							}

							<Textarea updateQuestion={updateQuestion} question={question}/>
						</animated.div>
					</animated.div>
				})
			}
		</>
	);
}

export default React.memo(Question);

const Textarea = ({question, updateQuestion, ...props}) => {

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},
		],
		delay: 600,
		config: {duration: 1000}
	});


	return (
		<div className="question__input-container">
			<animated.textarea name="question" className="question__input"
							   placeholder="Start typing..."
							   style={transitWidth}
							   value={question}
							   onChange={(event) => {
								   updateQuestion(event.target.value);
							   }}
							   maxLength={280}/>
		<animated.p className="question__input-counter "
					style={{...transitWidth, width: "auto"}}>{question.length}/280</animated.p>
		</div>
	)
}
