import {action} from "easy-peasy";

import TheCosmicJoke from "../Assets/Duality/TheCosmicJoke.png";

import Courage from "../Assets/Duality/Courage.png"
import TheCosmicDance from "../Assets/Duality/TheCosmicDance.png"
import fire from '../Assets/Elements/Fire.png'
import red from '../Assets/Colors/red.png'
import ManAsHumanBeing from "../Assets/Duality/ManAsHumanBeing.png"
import MasuculinePsychicModeOfExpression from "../Assets/Duality/MasuclinePsychicModeOfExpression.png"
import TheAnimus from "../Assets/Duality/TheAnimus.png"
import LettingGoOfAddiction from "../Assets/Positivity/LettingGoOfAddiction.png"

//
// import Courage from "../Assets/Duality/Courage.png"
// import TheCosmicDance from "../Assets/Duality/TheCosmicDance.png"
// import fire from '../Assets/Elements/Fire.png'
// import red from '../Assets/Colors/red.png'
// import ManAsHumanBeing from "../Assets/Duality/ManAsHumanBeing.png"
// import MasuculinePsychicModeOfExpression from "../Assets/Duality/MasuclinePsychicModeOfExpression.png"
// import TheAnimus from "../Assets/Duality/TheAnimus.png"
// import LettingGoOfAddiction from "../Assets/Positivity/LettingGoOfAddiction.png"

export default {

	hidePlayFooter: false,
	hideButton: false,
	showChakraImage: false,
	mode: 0,
	modeSelected: false,
	subMode: 0,
	subModeSelected: false,
	question: "",
	questionAdded: false,
	elementCardSelected: false,
	elementFeel: "",
	elementFeelAdded: false,

	elementCardSelectedIndex: [],
	elementAnimationOver: false,
	elementProcessOver: false,


	chakraColorShuffleStart: false,
	chakraColorSelectCompleted: false,
	chakraFeelAdded: false,
	chakraProcessOver: false,
	chakraFeel: "",
	chakraColorCardSelected: [],
	chakraAnimationOver: false,

	dualityThreeCard: false,
	dualityThreeCardAnimationOver: false,
	dualityShuffleScreen: false,
	dualityShuffleStart: false,
	dualityCardSelected: [],
	dualityCardSelectCompleted: false,
	showSelectedDualityCard: false,
	dualityFeelCard1: "",
	dualityFeelCard2: "",
	dualityFeelCard3: "",
	dualityFeelAdded: false,
	dualityFlipCardScreen: false,
	dualityCardFlipped: false,
	dualityFlippedFeel: "",
	dualityFlippedFeelAdded: false,
	dualityProcessOver: false,

	shadowExplore: false,
	shadowShuffleStart: false,
	shadowCardSelected: false,
	shadowProcessAnimationOver: false,
	shadowFinalCards: [],
	shadowProcessOver: false,

	positivityShuffleStart: false,
	positivityCardSelected: [
	],
	positivityCardSelectComplete: false,
	positivityProcessOver: false,
	positivityProcessAnimationOver: false,

	conclusionAnimationOver: false,
	conclusionOver: false,

	elementTimelineActive: false,
	chakraTimelineActive: false,
	dualityTimelineActive: false,

	elementTimelineOver: false,
	chakraTimelineOver: false,
	dualityTimelineOver: false,


	// hidePlayFooter: false,
	// hideButton: false,
	// showChakraImage: false,
	// mode: 3,
	// modeSelected: true,
	// subMode: 0,
	// subModeSelected: true,
	// question: "Sjjsjsjsjsj",
	// questionAdded: true,
	// elementCardSelected: true,
	// elementFeel: "element feel",
	// elementFeelAdded: true,
	//
	// elementCardSelectedIndex: [
	// 	{
	// 		id: 0,
	// 		name: "Fire",
	// 		image: fire,
	// 		quality1: "The Fire Element as your base, indicates that the energies related to you and your situation will be played out through the Force of Spirit. Healing will be fast. Insights will be spontaneous.",
	// 		quality2: "The lesson—Be open to passion, purification, creativity and intuition, without becoming annihilated and burned out.",
	// 	}
	// ],
	// elementAnimationOver: true,
	// elementProcessOver: true,
	//
	//
	// chakraColorShuffleStart: true,
	// chakraColorSelectCompleted: true,
	// chakraFeelAdded: true,
	// chakraProcessOver: true,
	// chakraFeel: "smsmsmmsms",
	//
	//
	// chakraColorCardSelected: [
	// 	{
	// 		id: 0,
	// 		name: "Red",
	// 		represents: "Root",
	// 		image: red,
	// 		description: [
	// 			"Red represents the Root Chakra that is located between the anus and the genitals.This Chakra is related to your physical body and its survival.",
	// 			"If you choose the red Chakra Colour Card you are currently concerned with your physical and instinctual needs and survival. This card shows that you need to be grounded. You require more Life Force Energy.",
	// 			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to create a sense of security and safety in your life.",
	// 			"Choosing the red Chakra Colour Card indicates that your healing may stir in you past experiences of birth and early childhood and any fears and traumas experienced at that time."
	// 		],
	// 		healing: [
	// 			"Absorb the colour red as much as you can",
	// 			"by looking at the colour,",
	// 			"by eating and drinking red foods,",
	// 			"by wearing red clothes,",
	// 			"by sleeping on red sheets",
	// 			"and so forth.",
	// 			"Paint a small red nest",
	// 			"and imagine putting yourself in the centre."
	// 		],
	// 	}
	// ],
	// chakraAnimationOver: true,
	//
	// dualityThreeCard: true,
	// dualityThreeCardAnimationOver: true,
	// dualityShuffleScreen: true,
	// dualityShuffleStart: true,
	// dualityCardSelected: [
	// 	{
	// 		id: 43,
	// 		name: "The Cosmic Joke",
	// 		subTitle: "Wholeness and the Void",
	// 		image: TheCosmicJoke,
	// 		introduction: [
	// 			"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
	// 			"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
	// 			"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
	// 			"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
	// 		],
	// 		description: [
	// 			"<em>The image is of a clown’s face appearing through the starry night sky. The clown is laughing and the finger is pointing at you.</em>",
	// 			"Congratulations—you’ve woken up. I was wondering when you would realise how funny this all is. When did you begin to realise that it’s not at all as serious as you thought? I mean, it’s really funny, isn’t it? It’s really funny that you are here at all—that you are even playing this game. Ha!",
	// 			"I’ve been waiting and watching you to see when you would get the punch line. I have been waiting to see your surprise when you realised what it was all about, when you saw through the trickery, the fantasy and found the truth. And all this time, you thought that it was something different. You thought it was all so relevant–isn’t that so funny? And what about the timing—wasn’t I superb? You could not have worked out such perfect timing even if you had forever to plan it. Doesn’t it amaze you just how bizarrely perfect it all is?",
	// 			"Now you see clearly the madness and all the running around for nothing. All that searching and seeking for something that does not even exist—what a gag! Yes, you have been running around in circles…  Now you know that you don’t have to take it all so seriously, you can relax and let go. Phew! What a relief! You know now that you do not need to take it all so personally because it’s THE COSMIC JOKE!",
	// 		],
	// 		equaliser: [
	// 			"Think of the times when you have felt set-up. Who do you think the cosmic joker really is?",
	// 			"Remember the last time you felt a serious situation was very funny. What did you let go of in order to feel this?",
	// 			"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 			"Fait Accompli, Tickled Pink, Divine Craziness, Belly Laugh, Universally Funny, Trickster, Set Up, Get a Custard Pie Thrown at You, Perfect Timing, The Finger Is Pointing at You, Clowning Around, One-Liner, Secret Joke, Mimic, Ludicrous, Die Laughing, Ha Ha",
	// 			"No Laughing Matter, Taking Things Seriously, Solemn, Grave, Keep a Straight Face, It’s Not Funny, Deathly Joke, Die Laughing, Why Are You Laughing? Humourless, You’re No Fun to Be with, All Work and No Play Makes Jack a Dull Boy, Dead Pan",
	// 			"How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?"
	// 		],
	// 		completion: ["Nothing to display"]
	// 	},
	// 	// {
	// 	// 	id: 17,
	// 	// 	name: "Courage",
	// 	// 	subTitle: "The Inspiration and Opposition Dualities",
	// 	// 	image: Courage,
	// 	// 	introduction: [
	// 	// 		"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
	// 	// 		"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
	// 	// 		"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
	// 	// 	description: [
	// 	// 		"The image is of a figure walking over a path of hot coals. The act demands courage and fearlessness. There is the chance of being burned and hurt, but there is also the potential to pass through unharmed if there is enough mindfulness, trust and skill.",
	// 	// 		"To be able to walk on fire, but not be overwhelmed, to be ignited and inspired and yet stay sane, is to be able to go beyond mere instinctual survival and basic humanness. This is the action of the heart where the higher mind triumphs and the Divine Spirit overcomes the instinctual, reflexive or fearful body. This is the essence of the hero and the just and fair warrior whose actions of heart and soul and their results are more than the possible luck or disaster of the fool.",
	// 	// 		"What courage do you need to walk on hot coals? Do you need faith? When you have faith and trust, it does not matter whoever else considers you foolish or brave. When you have faith and trust, you can consciously take the first step through the fire, unconcerned as to whether you will live or die. When you have faith and trust you can walk into the unknown and take your bravest and most fearless step yet."],
	// 	// 	equaliser: [
	// 	// 		"How much do you compromise your truth in order to be liked and respected, or to keep the status quo?",
	// 	// 		"Think of someone you admire and respect for their courage and bravery. Try to identify what it is about them that you consider to be so inspiring. Find these qualities in yourself.",
	// 	// 		"Honour the courage in you that has brought you this far.",
	// 	// 		"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 	// 		"Shocked, Paralysed with Fear, Scared, Phobia, Terrorised, Coward, Yellow Belly, Wimp, Numbed, Spineless, Lily Livered, Incapacitated with Fear, Play Dead, Tremble and Shake, Gutless",
	// 	// 		"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>"],
	// 	// 	completion: [
	// 	// 		"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
	// 	// 		"<strong> Courage and Fear</strong>",
	// 	// 		"What can be more inspiring than the hero and the heroic act? Would you not like to be a hero, honoured and acknowledged for your bravery and wise actions, your courageous and warrior-like qualities in the face of adversity?",
	// 	// 		"The concept of courage gives us hope for the future. If human beings can courageously act in an inspired way, it shows hope and promise for this sometimes tentative and disturbing world.",
	// 	// 		"It shows that we are capable of facing, overcoming and surviving the most tremendous obstacles.",
	// 	// 		"It suggests our Divinity, our ability to become greater than what we think we are. For, an act of true courage does not merely involve looking after oneself. Honour is involved, the fight to save the other, the cause, the truth— that takes us beyond a merely instinctual and selfish response.",
	// 	// 		"And so we become attached to this valiant notion, this unshakeable proof that we can be Divine. But here we meet its opposing and ever-present companion, its counterpart of fear. In the very play of the hero, in the honouring of courageous acts, we dis-empower its complement of fear."]
	// 	// },
	// 	// {
	// 	// 	id: 45,
	// 	// 	name: "The Cosmic Dance",
	// 	// 	subTitle: "Wholeness and the Void",
	// 	// 	image: TheCosmicDance,
	// 	// 	introduction: [
	// 	// 		"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
	// 	// 		"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
	// 	// 		"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
	// 	// 		"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
	// 	// 	],
	// 	// 	description: [
	// 	// 		"<em>The image is of a golden feminine figure moving joyously in dance in the spaciousness of the sky.</em>",
	// 	// 		"<em>I am you who dances in ecstatic abandonment with the perfect momentum of Creation. My partner is the Cosmos and I dance in complete harmony with all. As I move I connect to and interrelate with every atom and universal cell and vibrate according to perfect Divine Timing. I disintegrate and manifest, am affected and transformed in utter rapport with everything and everyone that was, is and is yet to come into form.  As I twirl and whirl, I am mindless, but I am at one with the innate Divine Intelligence of all.</em>",
	// 	// 		"I am a key to your increasing loosening and detachment from that which has to go. One day you will meet me in freedom and you will recognise how the Dance began. My Divine movement is your transformation. Mindfully centre yourself, focus, move yourself and let go…"
	// 	// 	],
	// 	// 	equaliser: [
	// 	// 		"<em>Dance in abandonment and in awareness.</em>",
	// 	// 		"<em>In meditation, visualise every particle of Creation dancing in a colourful array. Imagine yourself in the centre in unity with all.</em>",
	// 	// 		"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 	// 		"<em>The Dance of Shiva, Nataraj, Momentum, Sun Dance, Sacred Dance, Ballet, Twirl, Sufi Whirling, Earth Dance, The Four Directions Dance, Veil Dancing, Dance with Abandonment, Belly Dance, Waltz, Solo Dance, Fire Dance, Sway, Sky Dancer</em>",
	// 	// 		"<em>Stationary, Legless, I can’t Dance, People are Watching Me, Self-Conscious, Inhibited, Not Allowed to Dance, Tiptoe, Crawl, Immobile, Prowl, Shuffle, Meander, I Need a Choreographer, Ungrounded</em>",
	// 	// 		"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
	// 	// 	],
	// 	// 	completion: ["Nothing to display", "", "", ""]
	// 	// },
	// ],
	// dualityCardSelectCompleted: true,
	// showSelectedDualityCard: true,
	// dualityFeelCard1: "ss",
	// dualityFeelCard2: "",
	// dualityFeelCard3: "",
	// dualityFeelAdded: true,
	// dualityFlipCardScreen: true,
	// dualityCardFlipped: true,
	// dualityFlippedFeel: "",
	// dualityFlippedFeelAdded: true,
	// dualityProcessOver: true,
	//
	// shadowExplore: false,
	// shadowShuffleStart: false,
	// shadowCardSelected: false,
	// shadowProcessAnimationOver: false,
	// shadowFinalCards: [
	// 	// {
	// 	// 	id: 43,
	// 	// 	name: "The Cosmic Joke",
	// 	// 	subTitle: "Wholeness and the Void",
	// 	// 	image: TheCosmicJoke,
	// 	// 	introduction: [
	// 	// 		"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
	// 	// 		"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
	// 	// 		"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
	// 	// 		"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
	// 	// 	],
	// 	// 	description: [
	// 	// 		"<em>The image is of a clown’s face appearing through the starry night sky. The clown is laughing and the finger is pointing at you.</em>",
	// 	// 		"Congratulations—you’ve woken up. I was wondering when you would realise how funny this all is. When did you begin to realise that it’s not at all as serious as you thought? I mean, it’s really funny, isn’t it? It’s really funny that you are here at all—that you are even playing this game. Ha!",
	// 	// 		"I’ve been waiting and watching you to see when you would get the punch line. I have been waiting to see your surprise when you realised what it was all about, when you saw through the trickery, the fantasy and found the truth. And all this time, you thought that it was something different. You thought it was all so relevant–isn’t that so funny? And what about the timing—wasn’t I superb? You could not have worked out such perfect timing even if you had forever to plan it. Doesn’t it amaze you just how bizarrely perfect it all is?",
	// 	// 		"Now you see clearly the madness and all the running around for nothing. All that searching and seeking for something that does not even exist—what a gag! Yes, you have been running around in circles…  Now you know that you don’t have to take it all so seriously, you can relax and let go. Phew! What a relief! You know now that you do not need to take it all so personally because it’s THE COSMIC JOKE!",
	// 	// 	],
	// 	// 	equaliser: [
	// 	// 		"Think of the times when you have felt set-up. Who do you think the cosmic joker really is?",
	// 	// 		"Remember the last time you felt a serious situation was very funny. What did you let go of in order to feel this?",
	// 	// 		"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 	// 		"Fait Accompli, Tickled Pink, Divine Craziness, Belly Laugh, Universally Funny, Trickster, Set Up, Get a Custard Pie Thrown at You, Perfect Timing, The Finger Is Pointing at You, Clowning Around, One-Liner, Secret Joke, Mimic, Ludicrous, Die Laughing, Ha Ha",
	// 	// 		"No Laughing Matter, Taking Things Seriously, Solemn, Grave, Keep a Straight Face, It’s Not Funny, Deathly Joke, Die Laughing, Why Are You Laughing? Humourless, You’re No Fun to Be with, All Work and No Play Makes Jack a Dull Boy, Dead Pan",
	// 	// 		"How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?"
	// 	// 	],
	// 	// 	completion: ["Nothing to display"]
	// 	// },
	// 	// {
	// 	// 	id: 17,
	// 	// 	name: "Courage",
	// 	// 	subTitle: "The Inspiration and Opposition Dualities",
	// 	// 	image: Courage,
	// 	// 	introduction: [
	// 	// 		"That which opposes you has the effect of stifling you, suffocating you and even of deadening you. It can make you respond at the most base level. When you are fearful and mean and caught in your ignorance and pain, how can you not relate to others from such a defensive and antagonistic stance? Your pain confines and imprisons you from opening to the inspiration within you.",
	// 	// 		"Do you wish to be freer, be able to relax and feel peace and joy? To share from an inner feeling of safety and  ease? Can you open your mind and heart enough to allow even a glimmer of hope to come through?",
	// 	// 		"You can be moved and lifted from the depths of your own darkness by that which can inspire you. And, it is these glimmering sparks from deep within your heart that can stir you from your deadened madness into the most sublime action and thought. It is in your heart that the true heroic deed is born where generosity, wisdom and freedom lie; it is the opening of your heart which can burn and disintegrate the barriers of your ignorance, selfishness and fear, and free you from your living corpse, that is crippled by its shadowy nightmares, releasing you into the beauty of your true, open and happy nature."],
	// 	// 	description: [
	// 	// 		"The image is of a figure walking over a path of hot coals. The act demands courage and fearlessness. There is the chance of being burned and hurt, but there is also the potential to pass through unharmed if there is enough mindfulness, trust and skill.",
	// 	// 		"To be able to walk on fire, but not be overwhelmed, to be ignited and inspired and yet stay sane, is to be able to go beyond mere instinctual survival and basic humanness. This is the action of the heart where the higher mind triumphs and the Divine Spirit overcomes the instinctual, reflexive or fearful body. This is the essence of the hero and the just and fair warrior whose actions of heart and soul and their results are more than the possible luck or disaster of the fool.",
	// 	// 		"What courage do you need to walk on hot coals? Do you need faith? When you have faith and trust, it does not matter whoever else considers you foolish or brave. When you have faith and trust, you can consciously take the first step through the fire, unconcerned as to whether you will live or die. When you have faith and trust you can walk into the unknown and take your bravest and most fearless step yet."],
	// 	// 	equaliser: [
	// 	// 		"How much do you compromise your truth in order to be liked and respected, or to keep the status quo?",
	// 	// 		"Think of someone you admire and respect for their courage and bravery. Try to identify what it is about them that you consider to be so inspiring. Find these qualities in yourself.",
	// 	// 		"Honour the courage in you that has brought you this far.",
	// 	// 		"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 	// 		"Shocked, Paralysed with Fear, Scared, Phobia, Terrorised, Coward, Yellow Belly, Wimp, Numbed, Spineless, Lily Livered, Incapacitated with Fear, Play Dead, Tremble and Shake, Gutless",
	// 	// 		"<strong>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</strong>"],
	// 	// 	completion: [
	// 	// 		"* Please read the Completion after viewing and contemplating both sides of your Duality Card.",
	// 	// 		"<strong> Courage and Fear</strong>",
	// 	// 		"What can be more inspiring than the hero and the heroic act? Would you not like to be a hero, honoured and acknowledged for your bravery and wise actions, your courageous and warrior-like qualities in the face of adversity?",
	// 	// 		"The concept of courage gives us hope for the future. If human beings can courageously act in an inspired way, it shows hope and promise for this sometimes tentative and disturbing world.",
	// 	// 		"It shows that we are capable of facing, overcoming and surviving the most tremendous obstacles.",
	// 	// 		"It suggests our Divinity, our ability to become greater than what we think we are. For, an act of true courage does not merely involve looking after oneself. Honour is involved, the fight to save the other, the cause, the truth— that takes us beyond a merely instinctual and selfish response.",
	// 	// 		"And so we become attached to this valiant notion, this unshakeable proof that we can be Divine. But here we meet its opposing and ever-present companion, its counterpart of fear. In the very play of the hero, in the honouring of courageous acts, we dis-empower its complement of fear."]
	// 	// },
	// 	// {
	// 	// 	id: 45,
	// 	// 	name: "The Cosmic Dance",
	// 	// 	subTitle: "Wholeness and the Void",
	// 	// 	image: TheCosmicDance,
	// 	// 	introduction: [
	// 	// 		"Ah, those moments my heart remembers, when for a span of time, I felt whole and complete. Those moments, when I somehow found myself surrendering the duality and splits of this world; found myself experiencing no need, no stirring, no yearning to become one— I am One. And there is nothing but peace, pure peace.",
	// 	// 		"But somehow in the moment of awareness of this wonderful equilibrium, that completeness so entire, my being longs for the experience of further disintegration; to fall into oblivion where there is the darkness of a pregnant Void. This momentum seems a fraction of a second and an eternity, even as I am catapulted and spin and whirl around through its darkness of creation. And, I emerge again into separation in new and in different psychic realms.",
	// 	// 		"And, where did I fall into this unfathomable psychic sea? It is the deep core that is mysterious, unimaginable and unknown, yet from which all arises and comes into form. This place has no formal answers my mind can understand. But I know that it contains all knowledge, melted and consumed into a creative nothing and it drinks of itself in a timeless zone.",
	// 	// 		"From this unformed point my being rises once more, new, searching and seeking to be whole. And, it enters once more the cycle of eternal emerging, living, dying and being reborn. All that is certain is that I am no more the one who existed before."
	// 	// 	],
	// 	// 	description: [
	// 	// 		"<em>The image is of a golden feminine figure moving joyously in dance in the spaciousness of the sky.</em>",
	// 	// 		"<em>I am you who dances in ecstatic abandonment with the perfect momentum of Creation. My partner is the Cosmos and I dance in complete harmony with all. As I move I connect to and interrelate with every atom and universal cell and vibrate according to perfect Divine Timing. I disintegrate and manifest, am affected and transformed in utter rapport with everything and everyone that was, is and is yet to come into form.  As I twirl and whirl, I am mindless, but I am at one with the innate Divine Intelligence of all.</em>",
	// 	// 		"I am a key to your increasing loosening and detachment from that which has to go. One day you will meet me in freedom and you will recognise how the Dance began. My Divine movement is your transformation. Mindfully centre yourself, focus, move yourself and let go…"
	// 	// 	],
	// 	// 	equaliser: [
	// 	// 		"<em>Dance in abandonment and in awareness.</em>",
	// 	// 		"<em>In meditation, visualise every particle of Creation dancing in a colourful array. Imagine yourself in the centre in unity with all.</em>",
	// 	// 		"<strong>Choose any of the words below that appeal to you. Let it/them communicate with you and mediate healing for you.</strong>",
	// 	// 		"<em>The Dance of Shiva, Nataraj, Momentum, Sun Dance, Sacred Dance, Ballet, Twirl, Sufi Whirling, Earth Dance, The Four Directions Dance, Veil Dancing, Dance with Abandonment, Belly Dance, Waltz, Solo Dance, Fire Dance, Sway, Sky Dancer</em>",
	// 	// 		"<em>Stationary, Legless, I can’t Dance, People are Watching Me, Self-Conscious, Inhibited, Not Allowed to Dance, Tiptoe, Crawl, Immobile, Prowl, Shuffle, Meander, I Need a Choreographer, Ungrounded</em>",
	// 	// 		"<strong><em>How do the words you have chosen interrelate with you? How do they merge with you and balance you? After equalising who is the new being you are creating?</em></strong>"
	// 	// 	],
	// 	// 	completion: ["Nothing to display", "", "", ""]
	// 	// },
	// ],
	// shadowProcessOver: false,
	//
	// positivityShuffleStart: false,
	// positivityCardSelected: [
	// 	// {
	// 	// 	id: 16,
	// 	// 	image: LettingGoOfAddiction,
	// 	// 	name: "Letting Go of Addiction"
	// 	// }
	// ],
	// positivityCardSelectComplete: false,
	// positivityProcessOver: false,
	// positivityProcessAnimationOver: false,
	//
	// conclusionAnimationOver: false,
	// conclusionOver: false,


	updateMode: action((state, id) => {
		state.mode = id;
	}),
	updateModeSelected: action((state, value) => {
		state.modeSelected = value;
	}),

	updateSubMode: action((state, id) => {
		state.subMode = id;
	}),
	updateSubModeSelected: action((state, value) => {
		state.subModeSelected = value;
	}),

	updateQuestion: action((state, value) => {
		state.question = value
	}),
	updateQuestionAdded: action((state, value) => {
		state.questionAdded = value
	}),
	updateElementCardSelectedIndex: action((state, value) => {
		state.elementCardSelectedIndex.push(value)
	}),
	updateElementSelected: action((state, value) => {
		state.elementCardSelected = value
	}),
	updateElementFeel: action((state, value) => {
		state.elementFeel = value
	}),
	updateElementFeelAdded: action((state, value) => {
		state.elementFeelAdded = value
	}),
	updateElementAnimationOver: action((state, value) => {
		state.elementAnimationOver = value
	}),
	updateElementProcessOver: action((state, value) => {
		state.elementProcessOver = value
	}),
	updateHidePlayFooter: action((state, value) => {
		state.hidePlayFooter = value
	}),
	updateChakraColorShuffleStart: action((state, value) => {
		state.chakraColorShuffleStart = value
	}),
	updateChakraColorCardSelected: action((state, value) => {
		state.chakraColorCardSelected.push(value)
	}),
	updateChakraColorSelectCompleted: action((state, value) => {
		state.chakraColorSelectCompleted = value
	}),
	updateChakraFeel: action((state, value) => {
		state.chakraFeel = value
	}),
	updateChakraFeelAdded: action((state, value) => {
		state.chakraFeelAdded = value
	}),
	updateShowChakraImage: action((state, value) => {
		state.showChakraImage = value
	}),
	updateChakraAnimationOver: action((state, value) => {
		state.chakraAnimationOver = value;
	}),

	updateChakraProcessOver: action((state, value) => {
		state.chakraProcessOver = value;
	}),
	updateHideButton: action((state, value) => {
		state.hideButton = value;
	}),
	updateDualityThreeCard: action((state, value) => {
		state.dualityThreeCard = value;
	}),
	updateDualityThreeCardAnimationOver: action((state, value) => {
		state.dualityThreeCardAnimationOver = value;
	}),
	updateDualityShuffleScreen: action((state, value) => {
		state.dualityShuffleScreen = value;
	}),
	updateDualityShuffleStart: action((state, value) => {
		state.dualityShuffleStart = value;
	}),

	updateDualityCardSelected: action((state, value) => {
		state.dualityCardSelected.push(value);
	}),

	updateDualityCardSelectedArray: action((state, value) => {
		state.dualityCardSelected = [...value];
	}),

	updateDualityCardSelectCompleted: action((state, value) => {
		state.dualityCardSelectCompleted = value;
	}),

	updateShowSelectedDualityCard: action((state, value) => {
		state.showSelectedDualityCard = value;
	}),

	updateDualityFeel: action((state, value) => {
		if (value.id === 1) {
			state.dualityFeelCard2 = value.text;
		} else if (value.id === 2) {
			state.dualityFeelCard3 = value.text;
		} else {
			state.dualityFeelCard1 = value.text;
		}
	}),
	updateDualityFeelAdded: action((state, value) => {
		state.dualityFeelAdded = value;
	}),

	updateDualityFlipCardScreen: action((state, value) => {
		state.dualityFlipCardScreen = value;
	}),

	updateDualityCardFlipped: action((state, value) => {
		state.dualityCardFlipped = value;
	}),

	updateDualityFlippedFeelCheck: action((state, value) => {
		state.dualityFlippedFeelCheck = value;
	}),

	updateDualityFlippedFeel: action((state, value) => {
		state.dualityFlippedFeel = value;
	}),

	updateDualityFlippedFeelAdded: action((state, value) => {
		state.dualityFlippedFeelAdded = value;
	}),

	updateDualityProcessOver: action((state, value) => {
		state.dualityProcessOver = value;
	}),

	updateShadowExplore: action((state, value) => {
		state.shadowExplore = value;
	}),

	updateShadowShuffleStart: action((state, value) => {
		state.shadowShuffleStart = value;
	}),

	updateShadowCardSelected: action((state, value) => {
		state.shadowCardSelected = value;
	}),

	updateShadowFinalCards: action((state, value) => {
		state.shadowFinalCards = [...value];
	}),

	updateShadowAnimationProcessOver: action((state, value) => {
		state.shadowProcessAnimationOver = value;
	}),

	updateShadowProcessOver: action((state, value) => {
		state.shadowProcessOver = value;
	}),

	updatePositivityShuffleStart: action((state, value) => {
		state.positivityShuffleStart = value;
	}),

	updatePositivityCardSelected: action((state, value) => {
		state.positivityCardSelected.push(value)
	}),

	updatePositivityCardSelectComplete: action((state, value) => {
		state.positivityCardSelectComplete = value;
	}),

	updatePositivityAnimationProcessOver: action((state, value) => {
		state.positivityProcessAnimationOver = value;
	}),

	updatePositivityProcessOver: action((state, value) => {
		state.positivityProcessOver = value;
	}),

	updateConclusionAnimationOver: action((state, value) => {
		state.conclusionAnimationOver = value;
	}),

	updateConclusionOver: action((state, value) => {
		state.conclusionOver = value;
	}),

	updateTimeline: action((state, {id, value}) => {
		switch (id) {
			case 1:
				return state.elementTimelineActive = value;
			case 2:
				return state.chakraTimelineActive = value;
			case 3:
				return state.dualityTimelineActive = value;
		}

	}),


	disableTimeline: action((state, {id, value}) => {
		switch (id) {
			case 1:
				return state.elementTimelineOver = value;
			case 2:
				return state.chakraTimelineOver = value;
			case 3:
				return state.dualityTimelineOver = value;
		}
	}),

	resetPlay: action((state) => {
		state.mode = 0;
		state.modeSelected = false;

		state.subMode = 0;
		state.subModeSelected = false;

		state.question = "";
		state.questionAdded = false;

		state.elementCardSelectedIndex = [];
		state.elementCardSelected = false;
		state.elementFeel = "";
		state.elementAnimationOver = false;
		state.elementFeelAdded = false;
		state.elementProcessOver = false;

		state.hidePlayFooter = false;
		state.hideButton = false;

		state.chakraColorShuffleStart = false;
		state.updateHidePlayFooter = false;
		state.chakraColorCardSelected = [];
		state.chakraAnimationOver = false;
		state.chakraColorSelectCompleted = false;
		state.chakraFeel = "";
		state.chakraFeelAdded = false;
		state.chakraProcessOver = false;

		state.showChakraImage = false;

		state.dualityThreeCard = false;
		state.dualityThreeCardAnimationOver = false;
		state.dualityShuffleScreen = false;
		state.dualityShuffleStart = false;
		state.dualityCardSelected = [];
		state.dualityCardSelectCompleted = false;
		state.showSelectedDualityCard = false;
		state.dualityFeelCard1 = "";
		state.dualityFeelCard2 = "";
		state.dualityFeelCard3 = "";
		state.dualityFeelAdded = false;
		state.dualityFlipCardScreen = false;
		state.dualityCardFlipped = false;
		state.dualityFlippedFeelCheck = false;
		state.dualityFlippedFeel = "";
		state.dualityFlippedFeelAdded = false;
		state.dualityProcessOver = false;

		state.shadowExplore = false;
		state.shadowShuffleStart = false;
		state.shadowCardSelected = false;
		state.shadowFinalCards = [];
		state.shadowProcessOver = false;
		state.shadowProcessAnimationOver = false;


		state.positivityShuffleStart = false;
		state.positivityCardSelected = [];
		state.positivityCardSelectComplete = false;
		state.positivityProcessOver = false;
		state.positivityProcessAnimationOver = false;

		state.conclusionAnimationOver = false;
		state.conclusionOver = false;


		state.elementTimelineActive = false;
		state.chakraTimelineActive = false;
		state.dualityTimelineActive = false;


		state.elementTimelineOver = false;
		state.chakraTimelineOver = false;
		state.dualityTimelineOver = false;
	})
};

