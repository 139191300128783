import React, {useEffect, useState} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import {animated, useSpring, useTransition} from "react-spring";
import "../Shadow/ShowShadowCard/ShowShadowCard.scss"

import DualityCardArray from "../../../Helpers/DualityCard";
import flip from "../../../Assets/flip.png"

import {DualityCardDetails} from "../Duality/ShowDualityCard/ShowDualityCard";

function DualityTimeline(props) {

	const {play: {elementCardSelectedIndex, dualityTimelineOver, dualityTimelineActive, shadowFinalCards, mode}} = useStoreState((state => state));
	const {play: {updateTimeline, disableTimeline}} = useStoreActions((state => state));

	const fadeOut = useSpring({
		from: {
			opacity: 0
		}, to: {
			opacity: (dualityTimelineActive && !dualityTimelineOver) ? 1 : dualityTimelineOver ? 0 : 0,
			// opacity: 1,
		},
		config: {duration: 1000},
	});

	useEffect(() => {
		if (dualityTimelineOver) {
			const timer = setTimeout(() => {
				updateTimeline({id: 3, value: false});
				disableTimeline({id: 3, value: false});
			}, 1000)
			return () => {
				clearTimeout(timer);
			}
		}

	}, [dualityTimelineOver, updateTimeline])

	const {play: {dualityCardFlipped, dualityCardSelected, chakraColorCardSelected}} = useStoreState((state => state))
	const {play: {updateHideButton}} = useStoreActions((actions => actions))

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		config: {duration: 1000},
	});
	const fadeInTransition = useTransition(dualityCardFlipped, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});

	const [rotateIndex, setRotateIndex] = useState(mode === 3 ? 0 : 1);
	const [clickedCard, setClickedCard] = useState(-1);

	const [counter, setCounter] = useState([]);

	useEffect(() => {

		if (counter.length === 3 && mode === 3) {
			updateHideButton(false);
			setCounter([]);

		} else if (counter.length === 1 && mode === 2) {
			updateHideButton(false);
			setCounter([]);
		}


	}, [counter, updateHideButton, setCounter, mode]);

	return (
		<animated.div className="dual-flip" style={fadeOut}>
			<div className="dual-flip__details">
				<animated.div className="dual__element-card"
							  style={{...fadeIn, backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}>
					{
						mode === 3 ? dualityCardSelected.map((element, index) => {
								return <DualityCard
									key={element.id}
									element={element}
									rotateIndex={rotateIndex}
									setRotateIndex={setRotateIndex}
									index={index}

									setCounter={setCounter} counter={counter}
									mode={mode}
									clickedCard={clickedCard} setClickedCard={setClickedCard}
								/>
							}
							) :
							[...chakraColorCardSelected, ...dualityCardSelected].map((element, index) => {
								return <DualityCard
									key={element.id}
									element={element}
									rotateIndex={rotateIndex}
									setRotateIndex={setRotateIndex}
									setCounter={setCounter} counter={counter}
									index={index}
									mode={mode}
									clickedCard={clickedCard} setClickedCard={setClickedCard}
								/>
							})
					}

				</animated.div>
			</div>
			<animated.div className="dual-flip__description">
				<DualityCardDetails element={clickedCard}/>
			</animated.div>
		</animated.div>
	);
}

const nextImage = (id, mode, index, element) => {

	if ((mode === 3) || (mode === 2 && index === 1)) {
		if (id % 2 === 0) {
			return DualityCardArray.filter((ele => ele.id === id - 1))[0];
		} else {
			return DualityCardArray.filter((ele => ele.id === id + 1))[0];
		}
	} else {
		return element;
	}
}

export default DualityTimeline;
const DualityCard = React.memo(({clickedCard, setRotateIndex, element, index, rotateIndex, setClickedCard, setCounter, counter, ...props}) => {


	const {play: {dualityFlippedFeelAdded, mode}} = useStoreState((state => state));

	const {play: {updateHidePlayFooter}} = useStoreActions((actions => actions));



	const [rotate, setRotate] = useState(false);

	const rotateAnim = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) scale(1) rotateY(0deg)",
			opacity: 1,
		},
		to: {
			transform: `translate3d(0px, 0px, 0) scale(${((mode === 3 || (mode === 2 && index !== 0)) && clickedCard.id) === nextImage(element.id, mode, index, element).id ? 1.2 : 1}) rotateY(${rotate ? -180 : 0}deg)`,
			opacity: 1
		},
		config: {duration: 1000},
	});


	const fadeInTransition = useTransition(((mode === 3 && rotateIndex === index) || (mode === 2 && index === 1)), null, {
		from: {
			transform: "translate3d(0px, -100px, 0px)",
			opacity: 0,
		},
		enter: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: 1,
		},
		leave: {
			transform: "translate3d(20px, 0px, 0px)",
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	});


	return (
		<div>
			<animated.div className={`dual-flip__container  dual-flip-${(mode === 2 && index === 1) ? 3 : index + 1}`}
						  key={element.id}
						  style={rotateAnim}
						  onClick={(e) => {
							  if (mode === 3) {
								  rotate ? setClickedCard(nextImage(element.id, mode, index, element)) : setClickedCard(element)
								  !counter.includes(index) && setCounter([...counter, index]);

							  } else if (mode === 2) {
								  !counter.includes(index) && setCounter([...counter, index]);
								  (index === 1) && rotate ? setClickedCard(nextImage(element.id, mode, index, element)) : setClickedCard(element)
							  }
						  }}
			>
				<div className="dual-flip__card "
					 style={{
						 backgroundImage: `url(${element.image})`,
					 }}/>
				<div className="dual-flip__card"
					 style={{
						 backgroundImage: `url(${nextImage(element.id, mode, index, element).image})`,
						 transform: `rotateY(180deg)`
					 }}
				/>
			</animated.div>

			{(!rotate) && fadeInTransition.map(({item, props: animation, key}) =>
				item && <animated.img src={flip}
									  key={key}
									  alt="Click to Flip" style={animation}
									  onClick={() => {
										  setClickedCard(nextImage(element.id, mode, index, element))
										  setRotate(true);
										  setRotateIndex(index + 1);
									  }}
									  className={`dual-flip__icon dual-flip__${(mode === 2 && index === 1) ? 3 : index + 1}`}/>
			)
			}
		</div>
	)
});

