import React, {useEffect, useState} from 'react';
import CardDeck from "../../../UI/CardDeck/CardDeck";
import "./DualityShuffle.scss";
import {useStoreActions, useStoreState} from "easy-peasy";
import {animated, useTransition} from "react-spring";
import Shuffle from "../../../Animation/Shuffle/Shuffle";
import DualityCard from "../../../../Helpers/DualityCard";
import getShuffledArray from "../../../../Helpers/RandomizeArray";


let OddCards = DualityCard.filter((element, index) => index % 2 === 0);


OddCards = [...getShuffledArray(OddCards)];
OddCards = [...getShuffledArray(OddCards)];
OddCards = [...getShuffledArray(OddCards)];






const nextImage = (id) => {

	if (id === 45) {
		return DualityCard.filter((ele => ele.id === 45))[0];
	} else if (id % 2 === 0) {
		return DualityCard.filter((ele => ele.id === id - 1))[0];
	} else {
		return DualityCard.filter((ele => ele.id === id + 1))[0];
	}
}


const DualityShuffle = () => {

	const {play: {dualityShuffleStart, dualityCardSelectCompleted, dualityCardSelected, mode, question}} = useStoreState((state => state));
	const {play: {updateDualityCardSelected, updateShowSelectedDualityCard, updateHidePlayFooter, updateDualityCardSelectedArray}} = useStoreActions((state => state))


	const fadeInContainer = useTransition(dualityShuffleStart, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 1000}
	});
	const [shuffleComplete, setShuffleComplete] = useState(false);
	const [shuffleStart, setShuffleStart] = useState(false);

	useEffect(() => {
		if ((mode === 3 && dualityCardSelected.length === 3) || (mode === 2 && dualityCardSelected.length === 1)) {
			updateHidePlayFooter(false);
		}
	}, [dualityCardSelected, updateHidePlayFooter])


	useEffect(() => {
		if (dualityShuffleStart) {
			const timer = setTimeout(() => {
				setShuffleStart(true);
			}, 1000)
			return () => {
				clearTimeout(timer);
			}
		}
	}, [dualityShuffleStart])

	useEffect(() => {
		if (dualityCardSelectCompleted) {
			const arr = dualityCardSelected.map((element) => {
				const number = Math.round(Math.random());
				if (number) {
					return element;
				} else {
					return nextImage(element.id);
				}
			});

			updateDualityCardSelectedArray(arr);
			const timer = setTimeout(() => {
				updateShowSelectedDualityCard(true);
			}, 2000)
			return () => {
				clearTimeout(timer);
			}
		}
	}, [dualityCardSelectCompleted, updateDualityCardSelectedArray, updateShowSelectedDualityCard]);


	return (
		<>
			{
				fadeInContainer.map(({item, props: animation, key}) =>
					!item && <animated.div className="duality-shuffle" style={animation} key={key}>
						<div className="duality-shuffle__deck">
							<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5} disableAnimation={true}/>
						</div>

						<div className="duality-shuffle__details">
							<p className="duality-shuffle__title">Think of your question.</p>
							<p className="duality-shuffle__title duality-shuffle__title-1">{question}</p>
							<p className="duality-shuffle__title">Chant aloud or internally, Om or Amen 21 times, or another chant you prefer. This
								will
								clear
								and settle your mind. Focus intently on your question before and as you shuffle.</p>
							<p className="duality-shuffle__title">Shuffle (The cards will automatically shuffle 21 times when you click the shuffle
								button)</p>
						</div>
					</animated.div>
				)
			}
			{shuffleStart && <Shuffle items={OddCards} cardLimit={mode === 3 ? 3 : 1} shuffleCount={2}
									  setShuffleComplete={setShuffleComplete}
									  title={mode === 3 ? "Pick Three Cards" : "Pick One Card"}
									  subtitle={"by clicking on the card"}
									  alignment={"100px"}
									  counter={true}
									  pushCard={updateDualityCardSelected}
									  animationRewind={dualityCardSelectCompleted}/>}
		</>
	)
}
export default DualityShuffle;

