import React, {useEffect, useState} from 'react';
import './Shuffle.scss';
import {animated, useSpring, useSprings} from 'react-spring'
import cardBack from "../../../Assets/cardBack.png"
import CountUp from "react-countup";

//Shuffle Animation Declaration

// const [shuffleComplete, setShuffleComplete] = useState(false);

//
// {!shuffleComplete ?
// 				<Shuffle items={items} cardLimit={3} shuffleCount={1} setShuffleComplete={setShuffleComplete}/> :
// 				<p>Shuffle Complete</p>
// 			}

// export const shuffleAnimationObject = (items, item, index) => {
// 	return {
// 		from: {
// 			zIndex: items.length - index,
// 			transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
// 		}, to: [
// 			{
// 				transform: 'translate3d(300px,0,0) scale(1) rotateX(0deg)',
// 				zIndex: index ? -index : 0
// 			}
// 			, {
// 				transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
// 				zIndex: index ? -index : 0
// 			}
// 		],
// 		delay: delay(index),
// 		config: {duration: 400}
// 	}
// }

export const shuffleAnimationObject = (items, item, index) => {
	return {
		from: {
			zIndex: items.length - index,
			transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
		}, to: [
			{
				transform: 'translate3d(300px,0,0) scale(1) rotateX(0deg)',
				zIndex: index ? -index : 0
			}
			, {
				transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
				zIndex: index ? -index : 0
			}
		],
		delay: delay(index),
		config: {duration: 500}
	}
}


const delay = (id) => Number(`${id}000` - (500 * id));

const Shuffle = ({items, shuffleCount, animationRewind, pushCard, title, subtitle, skipSelect, alignment,counter, ...props}) => {
	const [resetAnimation, setReset] = useState(true);
	const [animationComplete, setAnimationComplete] = useState(false);
	const [cardLimit, setCardLimit] = useState(props.cardLimit);

	useEffect(() => {
		let count = 0;
		let timer1 = setInterval(() => {
			if (count !== shuffleCount - 1) {
				setReset((value) => !value);
				count = count + 1;
			} else {
				// skipSelect && props.setShuffleComplete(true);
				setAnimationComplete(true);
			}
		}, 500 * (5 - 1))
		return () => {
			clearInterval(timer1)
		}
	}, [])


	useEffect(() => {
		if (animationComplete && skipSelect) {
			props.setShuffleComplete(true);
		}
	}, [animationComplete, skipSelect])


	useEffect(() => {
		if (!cardLimit) {
			props.setShuffleComplete(true);
		}
	}, [cardLimit, props.setShuffleComplete])


	const container = useSpring({
		from: {
			transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
			position: "absolute",
			top: "100%"

		},
		to: {
			// transform: animationComplete && items.length > 17 ? `translate3d(${100}px,0,0) scale(1) rotateX(0deg)` : `translate3d(0px,0,0) scale(1) rotateX(0deg)`,
			left: animationComplete ? alignment : "100px"
		},

		config: {duration: 1000}
	});

	return (
		<>
			<div className="shuffle">
				<div className="">
					{animationComplete && <p className="shuffle__title card-animation">{title}</p>}
					{animationComplete && <p className="shuffle__subtitle card-animation">{subtitle}</p>}
				</div>

				<animated.div className="shuffle__container"
							  style={{
								  ...container,
								  gridTemplateColumns: `repeat(${11 + items.length}, 0.3rem)` //O.3rem is offset for columns if you have to change it you will have to change it corresponding css
							  }}>
					{animationComplete ?
						<CardPicker cardLimit={cardLimit} setCardLimit={setCardLimit} items={items}
									pushCard={pushCard}
									skipSelect={skipSelect}
									animationRewind={animationRewind}/>
						:
						resetAnimation ?
							<CardShuffle complete={animationComplete} items={items}/> :
							<CardShuffle2 complete={animationComplete} items={items}/>


					}
				</animated.div>

				{counter && <ShuffleCounter animationComplete={animationComplete}/>}

			</div>

		</>
	)
}
const CardPicker = ({items, skipSelect, ...props}) => {

	const springs = useSprings(items.length, items.map((item, index) => {
		return {
			from: {
				zIndex: items.length - index,
				transform: `translate3d(0px,0,0) scale(1) rotateX(0deg)`,

			},
			to: [{
				// transform: props.animationRewind ? `translate3d(${(items.length - index)}px,0,0) scale(1) rotateX(0deg)` : `translate3d(${(items.length - index) * 30}px,0,0) scale(1) rotateX(0deg)`,
				transform: props.animationRewind && !skipSelect ? `translate3d(${(items.length - index) * 5}px,0,0) scale(1) rotateX(0deg)` : !skipSelect ? `translate3d(${(items.length - index) * 30}px,0,0) scale(1) rotateX(0deg)` : `translate3d(${(items.length - index) * 5},0,0) scale(1) rotateX(0deg)`,
			}, {opacity: !props.animationRewind ? 1 : 0}
			],
			xyz: [(items.length - index) * 50, -40, 0],
			delay: 400,
			config: {duration: 800}
		}
	}));


	return (

		springs.map((animation, index) => (
			<IndividualCard
				cardLimit={props.cardLimit}
				setCardLimit={props.setCardLimit}
				key={index}
				index={index}
				items={items}
				pushCard={props.pushCard}
				uniqueKey={index}
				animationRewind={props.animationRewind}
				animation={{
					...animation,
					backgroundImage: `url(${cardBack})`,
					gridColumn: !skipSelect && `${index + 1}/${15 + index}`
				}}
				customClassName={`shuffle__card`}
			/>
		))

	)
}
const CardShuffle = ({items, ...props}) => {
	const springs = useSprings(6, items.slice(0, 6).map((item, index) => shuffleAnimationObject(items, item, index)));
	return (
		springs.map((animation, index) => (
			<IndividualCard
				key={index}
				items={items}
				uniqueKey={index}
				animation={{
					...animation,
					backgroundImage: `url(${cardBack})`,
					gridColumn: `${index + 1}/${15 + index}`
				}}
				customClassName={`shuffle__card`}
			/>


		))
	)
}
const CardShuffle2 = ({items, ...props}) => {
	const springs = useSprings(6, items.slice(0, 6).map((item, index) => shuffleAnimationObject(items, item, index)));
	return (
		springs.map((animation, index) => (

			<IndividualCard
				items={items}
				key={index}
				uniqueKey={index}
				animation={{
					...animation,
					backgroundImage: `url(${cardBack})`,
					gridColumn: `${index + 1}/${15 + index}`
				}}
				customClassName={`shuffle__card`}
			/>
		))
	)
}


const IndividualCard = ({items, skipSelect, ...props}) => {
	const [clicked, setClicked] = useState(false);
	return (
		<animated.div
			onClick={() => {
				if (props.cardLimit && !clicked) {
					setClicked((value) => !value);
					props.pushCard(items[props.index]);
					props.setCardLimit((limit) => limit - 1);
				}
			}}

			key={props.uniqueKey}
			style={clicked && !props.animationRewind ? {
					...props.animation,
					marginTop: '-2em'
				}
				: props.animationRewind ? {

						...props.animation,
						marginTop: '0em'
					} :
					{
						...props.animation
					}
			}
			className={props.customClassName}>
		</animated.div>
	)
}


const ShuffleCounter = ({animationComplete, ...props}) => {


	const fadeOut = useSpring({
		from: {
			opacity: 0,
			display: "block"
		}, to: {
			opacity: animationComplete ? 0 : 1,
			display:animationComplete ? "none" : "block"
			// opacity: 1,
		},
		config: {duration: 1000},
	});

	return (

		<animated.div className="shuffle__counter" style={fadeOut}>
			<CountUp
				start={0}
				end={22}
				duration={(500 * (6)) * 4 / 1000}
				decimals={0}
				className={"shuffle__counter-title"}
				delay={0}

			>
				{({countUpRef, start}) => (
					<p className="shuffle__counter-title">Shuffle Counter : <span ref={countUpRef}/></p>


				)}
			</CountUp>
		</animated.div>

	);
}


export default React.memo(Shuffle);
