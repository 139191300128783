import React, {useEffect, useState} from 'react';
import './Toast.scss';
// import PropTypes from 'prop-types';

const Toast = props => {
	const {toastList, position, autoDelete, dismissTime} = props;
	const [list, setList] = useState(toastList);

	useEffect(() => {
		setList([...toastList]);

		// eslint-disable-next-line
	}, [toastList]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (autoDelete && toastList.length && list.length) {
				console.log("here");
				deleteToast(toastList[0].id);
			}
		}, dismissTime);

		return () => {
			clearInterval(interval);
		}

		// eslint-disable-next-line
	}, [toastList, autoDelete, dismissTime, list]);

	const deleteToast = id => {
		const listItemIndex = list.findIndex(e => e.id === id);
		const toastListItem = toastList.findIndex(e => e.id === id);
		list.splice(listItemIndex, 1);
		toastList.splice(toastListItem, 1);
		setList([...list]);
	}

	return (
		<>
			<div className={`notification-container top-left`}>
				{
					list.map((toast, i) =>
						<div
							key={i}
							className={`notification toast top-left`}
							style={{backgroundColor: "#F8E71C"}}>
							<p className="notification-title">Journal has been downloaded. Check your downloads folder to view it</p>
							<button onClick={() => deleteToast(toast.id)}>X</button>
						</div>
					)
				}
			</div>
		</>
	);
}

// Toast.propTypes = {
//     toastList: PropTypes.array.isRequired,
//     position: PropTypes.string,
//     autoDelete: PropTypes.bool,
//     dismissTime: PropTypes.number
// }

export default Toast;
