import React, {useEffect, useState} from 'react';
import './PlayFooter.scss';
import CardDeck from "../../UI/CardDeck/CardDeck";
import {useStoreActions, useStoreState} from "easy-peasy";
import {animated, useSpring, useTransition} from "react-spring";
import {saveAs} from "file-saver";

const shadow = "rgba(0, 0, 0, 0.3) 2px 2px 3px 0.05em";
const PlayFooter = ({disabled, ...props}) => {
	const [disableButton, setDisableButton] = useState(false);
	const [bulletActive, setBulletActive] = useState(0);


	const {
		play: {
			question, questionAdded, elementCardSelectedIndex, elementCardSelected, elementFeelAdded,
			elementProcessOver, hidePlayFooter, chakraColorSelectCompleted, chakraColorCardSelected,
			chakraFeelAdded, chakraProcessOver, hideButton, dualityThreeCard, dualityShuffleScreen,
			dualityShuffleStart, dualityCardSelectCompleted, dualityCardSelected, dualityFeelAdded, showSelectedDualityCard,
			dualityFlipCardScreen, dualityCardFlipped, dualityFlippedFeelAdded, dualityProcessOver, shadowExplore,
			shadowShuffleStart, shadowProcessOver, positivityShuffleStart, positivityCardSelectComplete,
			positivityCardSelected, positivityProcessOver, conclusionOver, subMode, mode, elementFeel,
			elementAnimationOver, chakraFeel, dualityFlippedFeel, dualityFlippedFeelCheck
		}
	} = useStoreState((state => state));

	const {
		play: {
			updateQuestionAdded, updateElementSelected, updateElementFeelAdded, updateElementAnimationOver,
			updateElementProcessOver, updateHidePlayFooter, updateChakraColorShuffleStart,
			updateChakraColorSelectCompleted, updateChakraFeelAdded, updateChakraProcessOver, updateChakraAnimationOver,
			updateHideButton, updateDualityThreeCard, updateDualityShuffleScreen,
			updateDualityShuffleStart, updateDualityCardSelectCompleted, updateDualityFeelAdded,
			updateDualityFlipCardScreen, updateDualityCardFlipped, updateDualityFlippedFeelAdded,
			updateDualityProcessOver, updateShadowExplore, updateShadowShuffleStart, updateShadowProcessOver, updateShadowAnimationProcessOver,
			updatePositivityShuffleStart, updatePositivityCardSelectComplete, updatePositivityProcessOver, updateConclusionOver,
			resetPlay, updateDualityThreeCardAnimationOver, updateDualityFlippedFeelCheck, updatePositivityAnimationProcessOver, updateConclusionAnimationOver,
			updateElementCardSelected, updateTimeline, disableTimeline
		}
	} = useStoreActions((actions => actions));

	const [label, setLabel] = useState("Take to the next step");

	const [percentage, setPercentage] = useState(0);

	const fadeIn = useTransition(hidePlayFooter, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 600,
		order: ['leave', 'enter', 'update'],
		config: {duration: 600}
	});


	const fadeInButtonClose = useTransition(bulletActive, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 1000}
	});

	const updateState = () => {

		if (mode !== 1) {
			if (!questionAdded) {
				updateQuestionAdded(true);
			} else if (elementCardSelectedIndex.length > 0 && !elementCardSelected && !elementFeelAdded) {
				updateElementSelected(true);
			} else if (elementCardSelectedIndex.length > 0 && elementCardSelected && !elementFeelAdded) {
				updateElementFeelAdded(true);
			} else if (elementCardSelected && elementFeelAdded && !elementAnimationOver) {
				updateElementAnimationOver(true);
			} else if (elementFeelAdded && elementProcessOver && chakraColorCardSelected.length === 0) {
				updateHidePlayFooter(true);
				updateChakraColorShuffleStart(true);
			} else if (elementProcessOver && chakraColorCardSelected.length === 1 && !chakraColorSelectCompleted) {
				updateChakraColorSelectCompleted(true);
			} else if (chakraColorSelectCompleted && !chakraFeelAdded) {
				updateChakraFeelAdded(true);
			} else if (chakraFeelAdded && !chakraProcessOver) {
				updateChakraAnimationOver(true);
				updateHideButton(true)
			} else if (chakraProcessOver && !dualityThreeCard) {
				updateDualityThreeCard(true);
				mode === 2 && updateDualityShuffleScreen(true);
			} else if (dualityThreeCard && !dualityShuffleScreen) {
				updateDualityThreeCardAnimationOver(true);
			} else if (dualityShuffleScreen && !dualityShuffleStart) {
				updateDualityShuffleStart(true);
				updateHidePlayFooter(true);
			} else if (dualityShuffleStart && !dualityCardSelectCompleted) {
				updateDualityCardSelectCompleted(true);
				updateDualityFlippedFeelCheck(true);
			} else if (dualityCardSelectCompleted && !dualityFeelAdded) {
				updateDualityFeelAdded(true)
				updateHideButton(true);
			} else if (dualityFeelAdded && !dualityFlipCardScreen) {
				updateDualityFlipCardScreen(true)
				updateHidePlayFooter(true);
			} else if (dualityFlipCardScreen && !dualityCardFlipped) {
				updateDualityCardFlipped(true);
			} else if (dualityCardFlipped && !dualityFlippedFeelAdded) {
				updateDualityFlippedFeelAdded(true);
				updateHideButton(true);
			} else if (dualityFlippedFeelAdded && !dualityProcessOver) {
				updateDualityProcessOver(true);
				updateHideButton(true)

			} else if (dualityProcessOver && !shadowExplore) {
				updateShadowExplore(true);
			} else if (shadowExplore && !shadowShuffleStart) {
				updateShadowShuffleStart(true);
				updateHidePlayFooter(true);
			} else if (shadowShuffleStart && !shadowProcessOver) {
				updateShadowAnimationProcessOver(true);
				updateHideButton(true);
			} else if (shadowProcessOver && !positivityShuffleStart) {
				updatePositivityShuffleStart(true);
				updateHidePlayFooter(true)

			} else if (positivityCardSelected.length === 1 && !positivityCardSelectComplete) {
				updatePositivityCardSelectComplete(true);
			} else if (positivityCardSelectComplete && !positivityProcessOver) {
				updatePositivityAnimationProcessOver(true);
				updateHidePlayFooter(true);
			} else if (positivityProcessOver && !conclusionOver) {
				updateConclusionAnimationOver(true);
			}
		} else {
			if (subMode === 1) {
				if (elementCardSelectedIndex.length > 0 && !elementCardSelected) {
					updateElementSelected(true);
				} else if (elementCardSelected && !elementFeelAdded) {
					updateElementFeelAdded(true);
				} else if (elementFeelAdded) {
					saveAs(elementCardSelectedIndex[0].image, "element-card-of-day.jpg")
					updateElementProcessOver(true);
					resetPlay();
				}
			} else if (subMode === 2) {
				if (chakraColorCardSelected.length === 0) {
					updateHidePlayFooter(true);
					updateChakraColorShuffleStart(true);
				} else if (chakraColorCardSelected.length === 1 && !chakraColorSelectCompleted) {
					updateChakraColorSelectCompleted(true);
				} else if (chakraColorSelectCompleted && !chakraFeelAdded) {
					updateChakraFeelAdded(true);
				} else if (chakraFeelAdded && !chakraProcessOver) {
					updateChakraProcessOver(true);
				} else if (chakraFeelAdded && chakraProcessOver) {
					saveAs(chakraColorCardSelected[0].image, "chakra-color-of-day.jpg")
					resetPlay();
				}
			} else if (subMode === 3) {
				if (!positivityShuffleStart) {
					updatePositivityShuffleStart(true);
					updateHidePlayFooter(true);
				} else if (positivityCardSelected.length === 1 && !positivityCardSelectComplete) {
					updatePositivityCardSelectComplete(true);
				} else if (positivityCardSelectComplete && !positivityProcessOver) {
					updatePositivityProcessOver(true);
				} else if (positivityProcessOver) {
					saveAs(positivityCardSelected[0].image, "karma-card-of-day.jpg")
					resetPlay();
				}
			}
		}
	}


	const fadeInButton = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: hideButton ? 0 : disableButton ? 0.5 : 1
		},
		config: {duration: 500}
	})

	useEffect(() => {
		if (questionAdded && elementCardSelectedIndex.length === 0) {
			updateHideButton(true)
		} else if (questionAdded && elementCardSelectedIndex.length === 1) {
			updateHideButton(false)
		}
	}, [questionAdded, elementCardSelectedIndex])

	useEffect(() => {
		if (mode !== 1) {
			if (questionAdded && !elementProcessOver) {
				setPercentage(15)
			} else if (elementProcessOver && !chakraProcessOver) {
				setPercentage(30)
			} else if (!dualityProcessOver && chakraProcessOver) {
				setPercentage(45)
			} else if (dualityProcessOver && !shadowProcessOver) {
				setPercentage(75)
			} else if (shadowProcessOver && !positivityProcessOver) {
				setPercentage(90)
			} else if (positivityProcessOver) {
				setPercentage(100)
			}
		} else {
			if (subMode === 1) {
				if (elementCardSelectedIndex.length > 0 && !elementCardSelected) {
					setPercentage(15)
				} else if (elementFeelAdded) {
					setPercentage(100)
				}
			} else if (subMode === 2) {
				if (chakraColorCardSelected.length === 1 && !chakraColorSelectCompleted) {
					setPercentage(15)
				} else if (chakraFeelAdded && !chakraProcessOver) {
					setPercentage(100)
				}
			} else if (subMode === 3) {
				if (!positivityShuffleStart) {
					setPercentage(15)
				} else if (positivityCardSelectComplete && !positivityProcessOver) {
					setPercentage(100)
				}
			}
		}

	}, [questionAdded, elementProcessOver, chakraProcessOver, dualityProcessOver,
		shadowProcessOver, positivityProcessOver, elementCardSelectedIndex,
		elementFeelAdded, elementCardSelected, subMode, mode, chakraColorCardSelected,
		chakraColorSelectCompleted, chakraFeelAdded, positivityShuffleStart,
		positivityCardSelectComplete
	])
	useEffect(() => {

		if (mode !== 1) {
			if (!questionAdded) {
				setLabel("Take me to the next step");
			} else if (elementCardSelectedIndex.length === 0 && !elementCardSelected) {
				setLabel("Click to see your card")
			} else if (elementCardSelected && !elementFeelAdded) {
				setLabel("What I see and feel")
			} else if (elementCardSelected && elementFeelAdded && !elementProcessOver) {
				setLabel("Okay got it")
			} else if (elementFeelAdded && elementProcessOver && chakraColorCardSelected.length === 0) {
				setLabel("Shuffle")
			} else if (elementFeelAdded && elementProcessOver && chakraColorCardSelected.length === 1 && !chakraColorSelectCompleted) {
				setLabel("Click to see your card")
			} else if (chakraColorCardSelected.length === 1 && chakraColorSelectCompleted && !chakraFeelAdded) {
				setLabel("What you see and feel");
			} else if (chakraFeelAdded && !dualityShuffleScreen) {
				setLabel("Okay got it");
			} else if (dualityShuffleScreen && dualityCardSelected.length === 0) {
				setLabel("Shuffle");
			} else if (((mode === 3 && dualityCardSelected.length === 3) || (mode === 2 && dualityCardSelected.length === 1))
				&& !showSelectedDualityCard) {
				setLabel("Click to see your card")
			} else if (showSelectedDualityCard && !dualityFeelAdded) {
				setLabel("What I see and feel");
			} else if (dualityFeelAdded && !dualityFlipCardScreen) {
				setLabel("Okay got it");
			} else if (!dualityCardFlipped && dualityFlipCardScreen) {
				setLabel("I have turned the cards");
			} else if (dualityCardFlipped && !dualityFlippedFeelAdded) {
				setLabel("I have entered what I feel");
			} else if (dualityFlipCardScreen && dualityFlippedFeelAdded && !dualityProcessOver) {
				setLabel("Okay got it");
			} else if (dualityProcessOver && !shadowExplore) {
				setLabel("Let’s explore the Shadow Ball");
			} else if (shadowExplore && !shadowShuffleStart) {
				setLabel("Shuffle and reveal");
			} else if (shadowShuffleStart && !shadowProcessOver) {
				setLabel("Okay got it");

			} else if (shadowProcessOver && !positivityShuffleStart) {
				setLabel("Shuffle");
			} else if (!positivityCardSelectComplete && positivityShuffleStart) {
				setLabel("Click here to see your card");
			} else if (!positivityProcessOver && positivityCardSelectComplete) {
				setLabel("Okay got it");
			} else if (positivityProcessOver && !conclusionOver) {
				setLabel("Show Journal");
			}
		} else {
			if (subMode === 1) {
				if (elementCardSelectedIndex.length > 0 && !elementCardSelected) {
					updateHidePlayFooter(false);
					setLabel("Click to see your card")
				} else if (elementCardSelected && !elementFeelAdded) {
					setLabel("Okay got it");
				} else if (elementFeelAdded) {
					setLabel("Download my Card of the Day ")
				}
			} else if (subMode === 2) {
				if (chakraColorCardSelected.length === 0) {
					setLabel("Shuffle")
				} else if (chakraColorCardSelected.length === 1 && !chakraColorSelectCompleted) {
					setLabel("Click to see your card")
				} else if (chakraColorSelectCompleted && !chakraFeelAdded) {
					setLabel("Okay got it");
				} else if (chakraFeelAdded && !chakraProcessOver) {
					setLabel("Okay got it");

				} else if (chakraFeelAdded && chakraProcessOver) {
					setLabel("Download my Card of the Day ")
				}
			} else if (subMode === 3) {
				if (!positivityShuffleStart) {
					setLabel("Shuffle")
				} else if (positivityCardSelected.length === 1 && !positivityCardSelectComplete) {
					setLabel("Click to see your card")
				} else if (positivityCardSelectComplete && !positivityProcessOver) {
					setLabel("Okay got it");
				} else if (positivityProcessOver && !conclusionOver) {
					setLabel("Download my Card of the Day ")
				}
			}
		}
	}, [questionAdded, elementCardSelectedIndex, elementCardSelected,
		elementFeelAdded, elementProcessOver, chakraColorSelectCompleted,
		chakraColorCardSelected, chakraFeelAdded, dualityShuffleScreen,
		dualityCardSelected, dualityFeelAdded, showSelectedDualityCard, dualityFlippedFeelAdded,
		dualityFlipCardScreen, shadowExplore, shadowShuffleStart, shadowProcessOver,
		positivityCardSelectComplete, positivityProcessOver, positivityCardSelected,
		conclusionOver, subMode, mode, chakraProcessOver, dualityProcessOver,
		updateHidePlayFooter, positivityShuffleStart, dualityCardFlipped
	])


	useEffect(() => {
		if (mode !== 1) {
			if (question.length < 5 && !elementCardSelected) {
				setDisableButton(true);
			} else if (question.length > 5 && !elementCardSelected) {
				setDisableButton(false);
			} else if (elementCardSelected && elementFeel.length < 5 && !chakraColorSelectCompleted) {
				setDisableButton(true);
			} else if (elementCardSelected && elementFeel.length > 5 && !chakraColorSelectCompleted) {
				setDisableButton(false);
			} else if (chakraColorSelectCompleted && chakraFeel.length < 5 && !dualityCardFlipped) {
				setDisableButton(true);
			} else if (chakraColorSelectCompleted && chakraFeel.length > 5 && !dualityCardFlipped) {
				setDisableButton(false);
			} else if (dualityCardFlipped && dualityFlippedFeel.length < 5) {
				setDisableButton(true);
			} else if (dualityCardFlipped && dualityFlippedFeel.length > 5) {
				setDisableButton(false);
			}
		}
	}, [questionAdded, setDisableButton, question, elementFeel, elementCardSelected,
		dualityFlippedFeel, chakraFeel, chakraColorSelectCompleted, mode, dualityCardFlipped])


	return (


		!bulletActive ? fadeIn.map(({item, props: animation, key}) =>
			!item &&
			<animated.div className="play-footer" style={animation} key={key}>
				<div className="progress">
					<p className="progress__percentage">{percentage}%</p>
					<div className="progress__container progress__bar-2">
						<div className="progress__progress-bar" style={{width: `${percentage}%`}}/>
					</div>
				</div>

				{!hideButton && <animated.button
					style={fadeInButton}
					disabled={disableButton}
					className="play-footer__btn margin-auto"
					onClick={updateState}>{label}</animated.button>}


				{(mode === 1 && (positivityProcessOver || elementFeelAdded || (chakraFeelAdded && chakraProcessOver))) &&
				<animated.button style={fadeInButton}
								 className="play-footer__another  play-footer__btn margin-auto"
								 onClick={() => {
									 resetPlay();
								 }}>Another Reading ?</animated.button>}

				{(mode !== 1 && !positivityProcessOver) && <div className="timeline">
					<div className={`timeline__card ${elementProcessOver && ' timeline__card-visible'}`}
						 onClick={() => {
							 if (elementProcessOver) {
								 updateTimeline({id: 1, value: true});
								 setBulletActive(1);
								 updateHidePlayFooter(true);
							 }
						 }}
					>
						<CardDeck width={"4.5rem"} height={"4rem"}
								  shadow={shadow}
								  style={{justifySelf: "center"}} radius={"0.5em"} number={1}/>
						<p className="timeline__text">ELEMENT CARD</p>
					</div>
					<div className={`timeline__card ${chakraProcessOver && ' timeline__card-visible'}`}
						 onClick={() => {
							 if (chakraProcessOver) {
								 updateTimeline({id: 2, value: true});
								 setBulletActive(2);
								 updateHidePlayFooter(true);
							 }
						 }}

					>
						<CardDeck width={"3rem"} height={"4rem"} radius={"0.5em"}
								  shadow={shadow}
								  number={1}/>
						<p className="timeline__text">CHAKRA CARD</p>
					</div>
					<div className={`timeline__card ${shadowProcessOver && ' timeline__card-visible'}`}

						 onClick={() => {
							 if (shadowProcessOver) {
								 updateTimeline({id: 3, value: true});
								 setBulletActive(3);
								 updateHidePlayFooter(true);
							 }
						 }}
					>
						<CardDeck width={"3rem"} height={"4rem"}
								  shadow={shadow}
								  radius={"0.5em"} number={1}/>
						<p className="timeline__text">DUALITY/WHOLE CARD</p>
					</div>
					<div className={`timeline__card ${positivityCardSelected.length === 1 && ' timeline__card-visible'}`}>
						<CardDeck width={"3rem"} height={"4rem"}
								  shadow={shadow}
								  radius={"0.5em"} number={1}/>
						<p className="timeline__text">POSITIVE KARMA CARD</p>
					</div>
				</div>}
			</animated.div>
			) :
			<div className="play-footer">
				<div className="timeline">
					{
						fadeInButtonClose.map(({item, props: animation, key}) =>
							item ? <animated.button
									style={animation}
									key={key}
									className="play-footer__btn margin-auto"
									onClick={() => {
										disableTimeline({id: bulletActive, value: true});
										setBulletActive(0);
										updateHidePlayFooter(false);

									}}>Close</animated.button>
								: ""
						)
					}
				</div>
			</div>
	);
}

export default PlayFooter;
