import React, {useEffect} from 'react';
import {useStoreActions, useStoreState} from "easy-peasy";
import '../../Play/Element/Element.scss';
import {animated, useSpring} from "react-spring";
import parse from 'html-react-parser';
import PlayHeader from "../PlayHeader/PlayHeader";


function ElementTimeLine(props) {

	const {play: {elementCardSelectedIndex, elementTimelineOver, elementTimelineActive}} = useStoreState((state => state));

	const {play: {updateTimeline, disableTimeline}} = useStoreActions((state => state));

	const fadeOut = useSpring({
		from: {
			opacity: 0
		}, to: {
			opacity: (elementTimelineActive && !elementTimelineOver) ? 1 : elementTimelineOver ? 0 : 0,
		},
		config: {duration: 1000},
	});

	useEffect(() => {
		if (elementTimelineOver) {
			const timer = setTimeout(() => {
				updateTimeline({id: 1, value: false});
				disableTimeline({id: 1, value: false});
			},1000)

			return () => {
				clearTimeout(timer);
			}
		}

	}, [elementTimelineOver, updateTimeline, disableTimeline])

	return (
		<>
			<PlayHeader/>
			<animated.div className="element" style={fadeOut}>
				<div className="element-flip__root">
					<div className={`element-flip__container`}>
						<div className="element-flip__card"
							 style={{
								 backgroundImage: `url(${elementCardSelectedIndex[0].image})`,
							 }}/>
					</div>
				</div>

				<div className="element-flip__details">
					<div >
						<p className="element__title" style={{marginBottom: "2em"}}>Qualities
							of {elementCardSelectedIndex[0].name}</p>

						<p className="element__description">{parse(elementCardSelectedIndex[0].quality1)}</p>
						<p className="element__description">{parse(elementCardSelectedIndex[0].quality2)}</p>
					</div>
				</div>
			</animated.div>
		</>
	);
}

export default ElementTimeLine;
