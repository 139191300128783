import React, {useEffect, useState} from 'react';
import "./ElementEnergy.scss";

import {useStoreActions, useStoreState} from "easy-peasy";
import elements from '../../../../Helpers/ElementCard';
import getShuffledArray from "../../../../Helpers/RandomizeArray";
import {animated, useSpring, useSprings, useTransition} from "react-spring";
import cardBack from '../../../../Assets/cardBack.png';
import cardBackWide from '../../../../Assets/cardBackWide.png';
import facebook from "../../../../Assets/facebook.png";
import instagram from "../../../../Assets/instagram.png";
import web from "../../../../Assets/web.png";
import youtube from "../../../../Assets/youtube.png";

import PlayHeader from "../../PlayHeader/PlayHeader";

const shuffledElements = [...getShuffledArray(elements)];


const negativePosition = -25;
const positivePosition = 25;


const position = (index, currentIndex) => index === 1 && currentIndex === 1 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` :
	index === 2 && currentIndex === 2 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1) ` :
		index === 3 && currentIndex === 3 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` :
			index === 0 && currentIndex === 0 ? `translate3d(${0}, ${0}, 0) scale(1.1, 1.1)` : `translate3d(${0}, ${0}, 0) scale(1, 1)`

const transformOrigin = (index, currentIndex) => index === 1 && currentIndex === 1 ? `left bottom` :
	index === 2 && currentIndex === 2 ? `right top` :
		index === 3 && currentIndex === 3 ? `left top` :
			index === 0 && currentIndex === 0 ? `right bottom` : `right bottom`

const translateCard = (index) => index === 1 ? `translate3d(${positivePosition}px, ${negativePosition}px, 0) scale(1,1)` :
	index === 2 ? `translate3d(${negativePosition}px, ${positivePosition}px, 0) scale(1, 1)` :
		index === 3 ? `translate3d(${positivePosition}px, ${positivePosition}px, 0) scale(1,1)` :
			index === 0 ? `translate3d(${negativePosition}px, ${negativePosition}px, 0) scale(1,1)` : `translate3d(${0}, ${0}, 0) scale(1,1)`


const ElementEnergy = (props) => {
	const {play: {elementCardSelected}} = useStoreState((state => state));
	const [showFeel, setShowFeel] = useState(false);


	useEffect(() => {
		if (elementCardSelected) {
			const timeout = setTimeout(() => {
				setShowFeel(true);
			}, 1100);
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [elementCardSelected])

	return (
		<>
			<PlayHeader/>
			{!showFeel ? <ElementSelect/> : <ElementQuestion/>}
		</>
	);


}


const ElementSelect = React.memo(({...props}) => {

	const [clicked, setClicked] = useState(false);
	const [clickedIndex, setClickedIndex] = useState(-1);
	const {play: {elementCardSelected}} = useStoreState((state => state));


	const elementAnimation = useSprings(shuffledElements.length, shuffledElements.map((element, index) => {
		return {
			from: {
				transform: translateCard(index),
				backgroundImage: `url(${cardBack})`
			}, to: {
				transform: (clicked && clickedIndex !== -1 && !elementCardSelected) ? position(clickedIndex, index) : `translate3d(${0}, ${0}, 0) scale(1,1)`,
				transformOrigin: clicked && clickedIndex !== -1 ? transformOrigin(clickedIndex, index) : "top bottom"
			},
			config: {duration: 1000}
		}
	}));

	const transitLeft = useSpring({
		from: {
			transform: "translate3d(50px, 0px, 0px)",
			opacity: 0,
		},
		to: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: elementCardSelected ? 0 : 1
		},
		config: {duration: 1000}
	});

	const transitLeftDescription = useSpring({
		from: {
			transform: "translate3d(50px, 0px, 0px)"
		},
		to: {
			transform: elementCardSelected ? "translate3d(0px, -20px, 0)" : "translate3d(0px, 0px, 0px)"
		},
		config: {duration: 1000}
	});


	const {play: {updateElementCardSelectedIndex}} = useStoreActions((actions => actions));


	return (
		<div className="element-energy">
			<div className="element-energy__cards">
				{elementAnimation.map((animation, index) =>
					<animated.div
						className={`element__card  ${(clicked && clickedIndex !== index) && `element__card-disabled`}`}
						style={{
							...animation, backgroundImage: `url${cardBackWide}`
						}}
						key={index}
						onClick={() => {
							if (!clicked) {
								setClicked(true);
								setClickedIndex(index);
								updateElementCardSelectedIndex(shuffledElements[index])
							}
						}}
					/>
				)
				}
			</div>

			<animated.div className="element-energy__details" style={transitLeft}>
				<p className="element-energy__title">Pick an Element Card by clicking on the card</p>
				<animated.p className="element-energy__description" style={transitLeftDescription}>Think of each Element as
					a catalyst – in this case as an
					Alchemical Reactor. Each Element integrates your chosen Dualities and Wholeness Card energies in
					its own particular way. These energies will be revealed to you as you choose your
					element cards.
				</animated.p>
				<animated.p className="element-energy__description" style={transitLeftDescription}>Be as spontaneous, open
					and willing as you can be. Let the Card
					choose you.
				</animated.p>
			</animated.div>
		</div>
	)
})


const ElementQuestion = React.memo((props) => {
	const {play: {elementCardSelectedIndex, elementFeelAdded}} = useStoreState((state => state));

	const [flipped, set] = useState(true);

	const transitLeft = useSpring({
		from: {
			transform: "translate3d(-200px, 0px, 0px)",
			opacity: 0,
		},
		to: [{
			transform: "translate3d(0px, 0px, 0)",
			opacity: 1,
		},
		],
		config: {duration: 1000},
	});
	const rotate = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
		},
		to: [{
			transform: "translate3d(0px, 0px, 0) rotateY(-180deg)"
		},
		],
		config: {duration: 3000},
		delay: 1000,
	});

	const fadeIn = useTransition(elementFeelAdded, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		config: {duration: 800}
	})

	useEffect(() => {
		const timeout = setTimeout(() => {
			set(false)
		}, 1200)

		return (() => {
			clearTimeout(timeout);
		})
	}, [])


	return (
		<div className="element-energy">
			<animated.div className="element-energy-flip__root" style={transitLeft}>
				<animated.div className={`element-flip__container`} style={rotate}>
					<div className="element-energy-flip__card"
						 style={{
							 backgroundImage: `url(${cardBack})`,
						 }}/>
					<div className="element-energy-flip__card"
						 style={{
							 backgroundImage: `url(${elementCardSelectedIndex[0].image})`,
							 transform: `rotateY(180deg)`
						 }}/>
				</animated.div>
			</animated.div>
			<animated.div className="element-energy-flip__details">
				{
					!flipped && fadeIn.map(({item, props: animation, key}) =>
						!item ?
							<animated.div className="element-energy-flip__sub-details" style={animation} key={key}>
								<p className="element-energy__title" style={{marginBottom: "2em"}}>Qualities
									of {elementCardSelectedIndex[0].name}</p>

								<p className="element-energy__description">{elementCardSelectedIndex[0].quality1}</p>
								<p className="element-energy__description">{elementCardSelectedIndex[0].quality2}</p>
							</animated.div>
							: <animated.div className="element-energy-flip__sub-details" style={animation} key={key}>
								<p className="element-energy__title" style={{marginBottom: "2em"}}>This concludes your reading</p>
								<p className="element-energy__description">Click below to download your card of the day.</p>
								<p className="element-energy__description">If you would like to know more about Mynavati, her meditations, books,
									inspirations
									and wisdom, please click on the links below</p>

								<div className="journal__icons">
									<a href=" https://www.facebook.com/Mynavatis-Inspirations-647306162453634" target="_blank"
									   rel="noopener noreferrer">
										<img src={facebook} alt="facebook" className="journal__icon"/>
									</a>
									<a href="https://www.instagram.com/mynavatis_inspirations/" target="_blank"
									   rel="noopener noreferrer">
										<img src={instagram} alt="instagram" className="journal__icon"/>
									</a>
									<a href="https://www.youtube.com/c/meditationsbymynavati" target="_blank"
									   rel="noopener noreferrer"
									>
										<img src={youtube} alt="youtube" className="journal__icon"/>
									</a>
									<a href="https://mynavati.com" target="_blank"
									   rel="noopener noreferrer">
										<img src={web} alt="web" className="journal__icon"/>
									</a>
								</div>
							</animated.div>
					)

				}
			</animated.div>

		</div>
	)
});


export default React.memo(ElementEnergy);


