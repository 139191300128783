import React, {useEffect, useRef, useState} from 'react';
import "./ShowDualityCard.scss";
import {animated, useSpring, useTransition} from "react-spring";
import {useStoreActions, useStoreState} from "easy-peasy";
import cardBack from '../../../../Assets/cardBack.png';
import parse from 'html-react-parser';


const labels = [
	"The first card (to the left-hand side) is the psycho-spiritual energies that you have recently left or are leaving behind.",
	"The second card (in the middle) is the energies you are identified with at this time unconsciously or consciously and what is making you feel, think and react as you do.",
	"The third card (to the right-hand side) is your final outcome to your question, heralding the possibilities and choices of your HEALing."
]


const ShowDualityCard = (props) => {
	const {play: {dualityFeelAdded, dualityCardSelected, elementCardSelectedIndex, mode, chakraColorCardSelected}} = useStoreState((state => state))
	const {play: {updateDualityFeel, updateHideButton}} = useStoreActions((actions => actions))
	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		config: {duration: 2000},
	});


	const [clickedCard, setClickedCard] = useState(-1);

	const [counter, setCounter] = useState([]);

	const fadeInTransition = useTransition(dualityFeelAdded, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	})

	const [navText1, setNavText1] = useState("");
	const [navText2, setNavText2] = useState("");
	const [navText3, setNavText3] = useState("");

	useEffect(() => {
		if (dualityFeelAdded && mode !== 2) {
			updateDualityFeel({text: navText1, id: 0});
			updateDualityFeel({text: navText2, id: 1});
			updateDualityFeel({text: navText3, id: 2});
		}
	}, [dualityFeelAdded]);

	const [reveal, setReveal] = useState(false);

	useEffect(() => {
		const timer = setTimeout(() => {
			setReveal(true);
		}, 2000)
		return () => {
			clearTimeout(timer)
		}
	}, []);

	const fadeInCard = useSpring({
		opacity: reveal ? 1 : 0,
		config: {duration: 2000},
	});

	useEffect(() => {
		if (counter.length === 3 && mode === 3) {
			updateHideButton(false);
			setCounter([]);

		} else if (counter.length === 1 && mode === 2) {
			updateHideButton(false);
			setCounter([]);
		}


	}, [counter, updateHideButton, setCounter]);

	return (
		<div className="dual">
			<div className="dual__details">
				<animated.div className="dual__element-card"
							  style={{...fadeIn, backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}>

					{
						reveal && <animated.div style={{...fadeInCard}}>
							{

								mode === 3 ? dualityCardSelected.map((element, index) => {
										return <DualityCard
											key={index}
											element={element}
											setCounter={setCounter} counter={counter}
											index={index}
											clickedCard={clickedCard} setClickedCard={setClickedCard}
										/>
									}
									) :
									[...chakraColorCardSelected, ...dualityCardSelected].map((element, index) => {
										return <DualityCard
											key={element.id}
											element={element}
											setCounter={setCounter} counter={counter}
											index={index}
											clickedCard={clickedCard} setClickedCard={setClickedCard}
										/>
									})
							}
						</animated.div>

					}
				</animated.div>

				{(reveal && clickedCard === -1 && mode === 3 && !dualityFeelAdded) && <>
					<p className="dual__label dual__label-past card-animation">Past</p>
					<p className="dual__label dual__label-present card-animation">Present</p>
					<p className="dual__label dual__label-future ">Future</p>
				</>
				}
			</div>
			{
				fadeInTransition.map(({item, props: animation, key}) =>
					!item ? <DualityFeel navText1={navText1} navText2={navText2} navText3={navText3}
										 key={key}
										 setNavText1={setNavText1} setNavText2={setNavText2} setNavText3={setNavText3}
						/>
						: <animated.div className="dual__description" key={key} style={animation}>
							<DualityCardDetails element={clickedCard} key={key} animation={animation}/>
						</animated.div>
				)
			}

		</div>
	)
}


const DualityFeel = React.memo(({navText1, setNavText1, navText2, setNavText2, navText3, setNavText3, animation, ...props}) => {
	const [navSelected, setNavSelected] = useState(0);
	const fadeInDescription = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		delay: 4000,
		config: {duration: 1000},
	});
	const {play: {mode}} = useStoreState((state => state))
	const {play: {updateHideButton}} = useStoreActions((actions => actions))

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},
		],
		delay: 3000,
		config: {duration: 1000},
	});

	const updateText = (value) => {
		if (navSelected === 1) {
			setNavText2(value);
		} else if (navSelected === 2) {
			setNavText3(value);
		} else {
			setNavText1(value);
		}
	}

	const [moreInfo, setMoreInfo] = useState(false);

	const [coords, setCoords] = useState(false);

	const nav1 = useRef();
	const nav2 = useRef();
	const nav3 = useRef();

	useEffect(() => {
		updateHideButton(true);
		if (nav1.current && nav2.current && nav3.current) {

			const {x: nav1X, y: nav1Y} = nav1.current.getBoundingClientRect();
			const {x: nav2X} = nav2.current.getBoundingClientRect();
			const {x: nav3X} = nav3.current.getBoundingClientRect();

			setCoords({
				...{
					nav1X, nav1Y, nav2X, nav3X
				}
			})
		}
	}, []);

	const fadeIn = useTransition(navSelected, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	})
	const onClickButton = () => {
		if (!moreInfo) {
			setMoreInfo(true);
			updateHideButton(false);
		} else {
			setMoreInfo(false);
			updateHideButton(true);
		}
	}


	return (
		<>
			{
				mode === 3 &&
				<animated.div className="dual__description" style={{...fadeInDescription}}>
					<p className="dual__title">The cards you picked</p>
					<p className="dual__subtitle" style={{margin: "0.3em 0 0.4em 0"}}>Look at the images and listen to what they are personally saying to
						you. Reflect on any feelings
						arising in you and take note of them. Consider each card in relationship to their timings and with one another.
						Reflect on each card in connection with your <strong>Element</strong> and <strong>Chakra</strong> cards.
					</p>
					<p className="dual__subtitle" style={{margin: "0 0 0.8em 0"}}>
						<strong>Click on the tabs below to journal your thoughts.</strong>
					</p>

					<div className="dual__nav-container">
						<p className="dual__nav"
						   ref={nav1}
						   onClick={() => {
							   setNavSelected(0);
						   }}>Card 1 - Past</p>
						<p className="dual__nav"
						   ref={nav2}
						   onClick={() => {
							   setNavSelected(1)
						   }}
						>Card 2 - Present</p>
						<p className="dual__nav"
						   ref={nav3}
						   onClick={() => {
							   setNavSelected(2)
							   updateHideButton(false);
						   }}>Card 3 - Future</p>

						{coords && <div className="chakra__nav-slide" style={{
							top: coords.nav1Y ? `${coords.nav1Y + 20}px` : "20px",
							left: navSelected === 0 ? `${coords.nav1X}px` :
								navSelected === 1 ? `${coords.nav2X}px` :
									`${coords.nav3X}px`,
						}}/>}
					</div>

					<div className="dual__nav-description-container">
						{
							fadeIn.map(({item, props: animation, key}) =>
								<animated.p className="dual__subtitle dual__nav-description"
											key={key}
											style={{
												...animation,
												margin: 0
											}}>{labels[item]}</animated.p>
							)}
					</div>

					<div className="dual__input-container">

						<animated.textarea name="question"
										   style={{...transitWidth}}
										   className="dual__input"
										   placeholder="Start typing..."
										   maxLength={100}
										   value={navSelected === 0 ? navText1 : navSelected === 1 ? navText2 : navText3}
										   onChange={
											   (event) => {
												   updateText(event.target.value)
											   }}
						/>
						<animated.p className="dual__input-counter "
									style={{...transitWidth, width: "auto"}}>
							{navSelected === 0 ? navText1.length : navSelected === 1 ? navText2.length : navText3.length}/100
						</animated.p>
					</div>
				</animated.div>
			}


			{mode === 2 &&
			<div className="dual__description-1" style={animation}>
				<p className="dual__title">The card you picked</p>
				<DualityText moreInfo={moreInfo}/>
				{<div className="w-20">
					<button className="btn btn-outline" onClick={onClickButton}>{moreInfo ? "BACK" : "NEXT"}</button>
				</div>}
			</div>
			}

		</>
	)
})

export const DualityCardDetails = React.memo(({element, ...props}) => {

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		config: {duration: 1000},
		delay: 1000
	});


	return (
		element !== -1 ? <DualityCardDescription element={element} fadeIn={fadeIn}/>
			:
			<animated.p className="dual__title margin-auto  card__animation" style={{...fadeIn, textAlign: "center"}}>
				Click on the Card to reveal its meaning</animated.p>
	)

});


const DualityCardDescription = ({element}) => {

	const [activeArray, setActiveArray] = useState([]);
	const [nav, setNav] = useState(0);
	const [navChange, setNavChange] = useState(false);

	const [italic, setItalic] = useState(true);

	const nav1 = useRef();
	const nav2 = useRef();
	const nav3 = useRef();
	const nav4 = useRef();

	// useEffect(() => {
	// 	if (element !== -1) {
	// 		setActiveArray(element.introduction);
	// 		setActiveArray(element.introduction);
	// 		setNavChange(true);
	// 	}
	// }, [element]);

	const fadeInContainer = useTransition(nav, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 700,
		order: ['leave', 'enter', 'update'],
		config: {duration: 700},
	})

	const [clicked, setClicked] = useState(false);

	const [coords, setCoords] = useState(false);
	const [elem, setElem] = useState({name: element.name, id: element.id, subTitle: element.subTitle});

	useEffect(() => {
		if (nav1.current && nav2.current && nav3.current) {
			const {x: nav1X, y: nav1Y} = nav1.current.getBoundingClientRect();
			const {x: nav2X} = nav2.current.getBoundingClientRect();
			const {x: nav3X} = nav3.current.getBoundingClientRect();
			const {x: nav4X} = nav4.current.getBoundingClientRect();
			setCoords({
				...{
					nav1X, nav1Y, nav2X, nav3X, nav4X
				}
			})
		}
	}, [element, elem]);

	useEffect(() => {
		setNavChange(true)
		const timer = setTimeout(() => {
			setNavChange(true)
			setClicked(true);
			setNavChange(false)
			setElem({...{name: element.name, id: element.id, subTitle: element.subTitle}})
			setActiveArray([...element.introduction])
		}, 1000)

		return () => {
			clearTimeout(timer);
		}

	}, [element])

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: navChange && clicked ? 0 : 1
		},
		config: {duration: 1000},
		delay: 0
	});

	useEffect(() => {


		if (element !== -1) {
			setNav(0);
		}
	}, [element]);


	const onClickNav = (id, arrayElement) => {
		setNav(id)
		setClicked(true);
		setTimeout(() => {
			setActiveArray([...arrayElement]);
			if (id === 0) {
				setItalic(true)
			} else {
				setItalic(false)

			}
		}, 700);
	};

	return (
		element && <animated.div style={fadeIn}>
			<p className="dual__nav ">{elem.subTitle}</p>
			<animated.p className="dual__card-title dual__animation">{elem.name} ( No. {elem.id} )</animated.p>

			<div className="dual__nav-container ">
				<p className="dual__nav"
				   ref={nav1}
				   onClick={() => {
					   onClickNav(0, [...element.introduction])
				   }}>Introduction</p>
				<p className="dual__nav"
				   ref={nav2}
				   onClick={() => {
					   onClickNav(1, [...element.description])
				   }}

				>Description</p>
				<p className="dual__nav"
				   ref={nav3}

				   onClick={() => {
					   onClickNav(2, [...element.equaliser])
				   }}>Equaliser</p>
				<p className="dual__nav"
				   ref={nav4}
				   onClick={() => {
					   onClickNav(3, [...element.completion])
				   }}>Completion</p>


				{coords && <div className="chakra__nav-slide" style={{
					top: `${coords.nav1Y + 20}px`,
					left: nav === 0 ? `${coords.nav1X}px` :
						nav === 1 ? `${coords.nav2X}px` :
							nav === 2 ? `${coords.nav3X}px` :
								`${coords.nav4X}px`,
				}}/>}
			</div>


			<div className="dual__nav-description-container dual__card-content">

				{
					fadeInContainer.map(({item, props: animation, key}) =>
						<animated.div style={{...animation}} key={key} className="dual__animation">
							{
								activeArray.map((element, index) =>
									<p className="dual__subtitle dual__nav-description  "
									   key={index}
									   style={{
										   fontStyle: italic ? "italic" : "normal"
									   }}>{parse(element)}
									</p>
								)
							}

						</animated.div>
					)}
			</div>


		</animated.div>

	)
}

const DualityText = ({moreInfo}) => {
	const fadeIn = useTransition(moreInfo, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 500,
		order: ['leave', 'enter', 'update'],
		config: {duration: 500},
	});

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},
		],
		delay: 1000,
		config: {duration: 1000},
	});

	const {play: {dualityFeelCard1}} = useStoreState((state => state))

	const {play: {updateDualityFeel}} = useStoreActions((actions => actions))


	return (

		<div className="dual__text-main">
			{
				fadeIn.map(({item, props: animation, key}) =>
					item ?
						<animated.div className="shadow__text-container" style={animation} key={key}>
							<p className="dual__subtitle">Instead, if you are feeling stuck and you choose the Crown Chakra card, it is more likely
								you are experiencing being spiritually stuck and the way through will be increasing understanding and awareness.</p>

							<p className="dual__subtitle">Now, if the Fire Element is chosen, this will have a different effect than the Water Element
								with either of these Chakra Cards. Fire is passionate and quick and so therefore the insights are spontaneous. Water
								is emotional and releasing and requires more feeling rather than thinking </p>
							<div className="dual__input-container">
								<animated.textarea name="question"
												   style={{...transitWidth}}
												   className="dual__input"
												   placeholder="Start typing..."
												   value={dualityFeelCard1}
												   maxLength={100}
												   onChange={
													   (event) => {
													   		console.log("here", event.target.value);
														   updateDualityFeel({text: event.target.value, id: 0})
													   }}
								/>
								<animated.p className="dual__input-counter "
											style={{...transitWidth, width: "auto"}}>
									{dualityFeelCard1.length}/100
								</animated.p>
							</div>
						</animated.div> :
						<animated.div className="shadow__text-container" style={animation} key={key}>
							<p className="dual__subtitle">Look at the image presented to you and listen to what it is personally saying to you.
								Reflect on any feelings arising in you or physical symptoms arising in you and take note of them. Reflect on your
								card
								also in
								connection with your Element and Chakra cards. </p>

							<p className="dual__subtitle">For example, choosing the Root Chakra card is very different to choosing the Crown
								Chakra
								card. If your question is about feeling stuck and you choose the Root Chakra Card, this will indicate the need to
								connect with your
								physical body and its related spiritual energies… to look at underlying fears or safety, especially
								physically. </p>
						</animated.div>
				)
			}
		</div>
	)
}

const DualityCard = ({clickedCard, setClickedCard, element, index, counter, setCounter, ...props}) => {
	const {play: {dualityFeelAdded, mode}} = useStoreState((state => state))

	// const {play: {updateHideButton}} = useStoreActions((actions => actions))

	const rotate = useSpring({
		from: {
			transform: "translate3d(0px, 0px, 0px) scale(1) rotateY(0deg)",
			opacity: 1,
		},
		to: {
			transform: `translate3d(0px, 0px, 0) scale(${clickedCard.id === element.id ? 1.2 : 1}) rotateY(-180deg)`,
			opacity: 1
		},
		config: {duration: clickedCard.id === element.id ? 500 : 800},
		delay: clickedCard !== -1 ? 0 : 800
	});


	const onClickButton = () => {
		if (mode === 3 && dualityFeelAdded) {
			!counter.includes(index) && setCounter([...counter, index]);
			setClickedCard(element);

		} else if (mode === 2 && dualityFeelAdded) {
			!counter.includes(index) && setCounter([...counter, index]);
			(dualityFeelAdded && index === 1) && setClickedCard(element)
		}
	}

	return (
		<div onClick={onClickButton}>
			<animated.div className={`dual-flip__container  dual-flip-${(mode === 2 && index === 1) ? 3 : index + 1}`}
						  key={element.id}
						  style={rotate}>
				<div className="dual-flip__card "
					 style={{
						 backgroundImage: `url(${cardBack})`,
					 }}/>
				<div className="dual-flip__card"
					 style={{
						 backgroundImage: `url(${element.image})`,
						 transform: `rotateY(180deg)`
					 }}/>
			</animated.div>
		</div>

	)
};

export default React.memo(ShowDualityCard);


