import React from 'react';
import './Choose.scss';
import CardDeck from "../../UI/CardDeck/CardDeck";
import {useStoreActions, useStoreState} from "easy-peasy";
import {animated, useSpring, useTransition} from "react-spring";
import energyBack from "../../../Assets/Back/Energies-back.svg";
import oneCardBack from "../../../Assets/Back/one-card-back.svg"
import threeCardBack from "../../../Assets/Back/three-card-back.svg"

const chooseObject = [
	{
		id: 1,
		title: "Energies of the day",
		description: "Connect and interact with your daily energies. Choose a Positive Karma Card, a Chakra Colour Card or an Element Card to gain a deeper insight into what you need to be aware of about you today.",
		cardBack: energyBack
	},
	{
		id: 2,
		title: "Using One Card to Heal",
		description: "Choose one Duality or Wholeness Card to help reflect what you are ready to embrace, to make conscious and to let go of, in order to HEAL. This is helpful for particular questions that require immediate reflection and insight.",
		cardBack: oneCardBack
	}, {
		id: 3,
		title: "Using Three Cards to Heal",
		description: "The three-card method gives insight into your past, present and possible future energies and choices, using three Duality and Wholeness Cards, an Element Card, Chakra Colour Card and Positive Karma Card. Use this method for an in-depth insight into what you are ready to embrace and make conscious of, what you are ready to HEAL.",
		cardBack: threeCardBack
	},
]

const EnergyObject = [
	{
		id: 1,
		title: "Element Card",
		description: "Connect with the energies of your chosen Element card to help you understand yourself better today.  Think of your Element as an Alchemical Reactor that is your energy base and which affects your mind, body and emotions as you go through the experiences of the day.",
	},
	{
		id: 2,
		title: "Chakra Colour Card",
		description: "This card identifies the psycho-spiritual energy level of you that is predominant today and is a potent doorway to this level’s emotions, feelings and spirituality. ",
	}, {
		id: 3,
		title: "Positive Karma Card",
		description: "Experience the positivity of good action and intention and their affirming consequences, by connecting to your Positive Karma Card of the day. Be guided to how to focus your thoughts positively and benefit from their affirming consequences.",
	},
]

const Choose = (props) => {

// const fadeOut

	const buttonTransition = useSpring({
		from: {
			transform: "translate3d(0, -30px, 0)"
		}, to: {
			transform: "translate3d(0, 0, 0)"
		},
		config: {duration: 1500}
	});


	const {play: {mode, subMode}} = useStoreState(state => state);
	const {play: {updateMode}} = useStoreActions(actions => actions);
	const {play: {updateModeSelected}} = useStoreActions(actions => actions)

	const containerSlideTransition = useTransition(mode, null, {
			from: {
				transform: mode ? "translate3d(100px, 0, 0)" : "translate3d(0px, 0, 0)",
				opacity: 0
			},
			enter: {
				transform: "translate3d(0px, 0, 0)",
				opacity: 1
			},
			leave: {
				transform: mode ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
				opacity: 0
			},
			config: {duration: 1000}
		}
	);

	const getTitle = (mode) => {
		switch (mode) {
			case 0:
				return "What would you like to do today ?"
			case 1:
				return "Which energies would you like to connect with?"
			case 2:
				return "One card method selected"
			case 3:
				return "Three card method selected"
			default:
				return "Divine Play selected"
		}
	}

	const getSubTitle = (subMode) => {
		switch (subMode) {
			case 0:
				return "What would you like to do today ?"
			case 1:
				return "Element card selected"
			case 2:
				return "Chakra card selected"
			case 3:
				return "Positive Karma Card selected"
			default:
				return "Divine Play selected"
		}
	}

	// const history = useHistory();


	return (
		<div className="choose__container">
			{/*<div className="">*/}
			<p className="choose__heading">Play / Reading</p>
			<h1 className="choose__title">{subMode ? getSubTitle(subMode) : getTitle(mode)}</h1>
			{/*</div>*/}

			{
				containerSlideTransition.map(({item, props: animation, key}) =>
					!item ?
						<animated.div className="choose__heal-container" key={key} style={animation}>
							{chooseObject.map(({id, title, description, cardBack}) =>
								<div className="choose__item" key={id}>
									<CardDeck width={"11rem"} height={"15rem"} number={4} class="choose__deck" cardBack={cardBack}/>
									<p className=" choose__item-title">{title}</p>
									<p className="choose__description">{description}</p>
									<animated.button className="btn choose__btn" type="button"
													 style={buttonTransition}
													 onClick={() => {
														 updateMode(id)
													 }}
									>{title}</animated.button>
								</div>)
							}
						</animated.div>
						: item !== 1 ? <ChooseWarning animation={animation} key={key} updateModeSelected={updateModeSelected}/>
						: <EnergySelected buttonTransition={buttonTransition} key={key}/>
				)

			}
		</div>

	);
}

const ChooseWarning = ({animation, updateModeSelected, ...props}) => {
	return (
		<animated.div className="choose__refresh-container" style={animation}>
			<p className="choose__refresh">DO NOT REFRESH</p>
			<p className="choose__warning">Once Divine Play begins, please don’t refresh the page or hit
				the back button. Continue through the process to the end. Give yourself enough time to
				experience Divine Play in its entirety.</p>

			<div className="w-20">
				<button className="btn" onClick={() => {
					updateModeSelected(true);
					// history.push(`/play/question`)
				}}>Okay
				</button>
			</div>
		</animated.div>
	)
}

const EnergySelected = ({buttonTransition}) => {

	const {play: {subMode}} = useStoreState(state => state);
	const {play: {updateSubMode, updateSubModeSelected, updateHidePlayFooter, updateHideButton}} = useStoreActions(actions => actions);

	const containerSlideTransition = useTransition(subMode, null, {
			from: {
				transform: subMode ? "translate3d(100px, 0, 0)" : "translate3d(0px, 0, 0)",
				opacity: 0
			},
			enter: {
				transform: "translate3d(0px, 0, 0)",
				opacity: 1
			},
			leave: {
				transform: subMode ? "translate3d(0px, 0, 0)" : "translate3d(-100px, 0, 0)",
				opacity: 0
			},
			trail: 1000,
			order: ['leave', 'enter', 'update'],
			config: {duration: 500}
		}
	);


	return (
		containerSlideTransition.map(({item, props: animation, key}) =>
			!item ? <animated.div className="choose__heal-container" style={animation} key={key}>
					{EnergyObject.map(({id, title, description}, index) =>
						<div className="choose__item" key={id}>
							<CardDeck width={index ? "11rem" : "13rem"} height={"15rem"} number={4} disableAnimation={true} class="choose__deck"/>
							<p className=" choose__item-title">{title}</p>
							<p className="choose__description">{description}</p>
							<animated.button className="btn choose__btn" type="button"
											 style={buttonTransition}
											 onClick={() => {
												 if (id === 1) {
													 updateHidePlayFooter(true);
												 } else if (id === 3) {
													 updateHideButton(true);
												 }
												 updateSubMode(id)
											 }}
							>{title}</animated.button>
						</div>)
					}
				</animated.div> :
				<ChooseWarning animation={animation} key={key}
							   updateModeSelected={updateSubModeSelected}/>
		))
}

export default Choose;
