import red from '../Assets/Colors/red.png'
import blue from '../Assets/Colors/blue.png'
import green from '../Assets/Colors/green.png'
import indigo from '../Assets/Colors/indigo.png'
import orange from '../Assets/Colors/orange.png'
import violet from '../Assets/Colors/violet.png'
import yellow from '../Assets/Colors/yellow.png'



export default [
	{
		id: 0,
		name: "Red",
		represents: "Root",
		image: red,
		description: [
			"Red represents the Root Chakra that is located between the anus and the genitals.This Chakra is related to your physical body and its survival.",
			"If you choose the red Chakra Colour Card you are currently concerned with your physical and instinctual needs and survival. This card shows that you need to be grounded. You require more Life Force Energy.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to create a sense of security and safety in your life.",
			"Choosing the red Chakra Colour Card indicates that your healing may stir in you past experiences of birth and early childhood and any fears and traumas experienced at that time."
		],
		healing: [
			"Absorb the colour red as much as you can",
			"by looking at the colour,",
			"by eating and drinking red foods,",
			"by wearing red clothes,",
			"by sleeping on red sheets",
			"and so forth.",
			"Paint a small red nest",
			"and imagine putting yourself in the centre."
		],
	},
	{
		id: 1,
		name: "Orange",
		represents: "Sacral",
		image: orange,
		description: [
			"Orange represents the Sacral Chakra that is located in the lower abdomen between the navel and the genitals. This Chakra is related to your emotional and sexual balance, self-esteem and creativity.",
			"If you choose the orange Chakra Colour Card you are currently concerned with your need to be stimulated, accepted and fulfilled in a creative and interactive way. This card shows that you need to have more self-acceptance. You require a sense of belonging.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to create self-esteem in your life.",
			"Choosing the orange Chakra Colour Card indicates that your healing may stir up in you past experiences of your adolescence and any fears and traumas at that time."
		],
		healing: [
			"Absorb the colour orange as much as you can",
			"by looking at the colour,",
			"by eating and drinking orange foods,",
			"by wearing orange clothes,",
			"by sleeping on orange sheets",
			"and so forth.",
			"Paint an orange Medicine Wheel",
			"(a round orange circle)",
			"Inside the circle,",
			"depicting what you wish to have in your life.",
			"Know that the orange circle will create an energetic boundary of protection.",
			"In the same way, place everything you do not wish to have in your life",
			"outside the circle.",
		],
	},
	{
		id: 2,
		name: "Yellow",
		represents: "Solar-Plexus",
		image: yellow,

		description: [
			"Yellow represents the Solar-Plexus Chakra that is located between the navel and the base of the sternum. This Chakra is related to your self-confidence, individual power and ambitions.",
			"If you choose the yellow Chakra Colour Card you are currently concerned with your emotional needs and desires. This card shows that you need to trust your gut feelings and claim your own power. You require courage.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to claim your self-worthiness, dignity and honour.",
			"Choosing the yellow Chakra Colour Card indicates that your healing may stir in you experiences of your teens or early adulthood, or fears and traumas to do with past issues of power and the meeting of your emotional needs."
		],
		healing: [
			"Absorb the colour yellow as much as you can,",
			"by looking at the colour,",
			"by eating and drinking yellow foods,",
			"by wearing yellow clothes,",
			"by sleeping on yellow sheets",
			"and so forth.",
			"Each day affirm,",
			"I am a unique spark of God. I respect and honour my divinity and power."
		],
	},
	{
		id: 3,
		name: "Green",
		represents: "Heart",
		image: green,

		description: [
			"Green represents the Heart Chakra that is located in the centre of the chest. This Chakra is related to your capacity to love and forgive.",
			"If you choose the green Chakra Colour Card you are currently concerned with the balance of the loving expression of your heart and you need compassion. You require acceptance.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to let go of feelings of possessiveness, jealousy and grudges, either from you or directed at you, in your personal relationships.",
			"Choosing the green Chakra Colour Card indicates that your healing may stir in you past relationship experiences of conditional and unconditional love and any fears and traumas related to these."
		],

		healing: [
			"Absorb the colour green as much as you can",
			"by looking at the colour,",
			"by eating and drinking green foods,",
			"by wearing green clothes,",
			"by sleeping on green sheets",
			"and so forth.",
			"Carry a piece of green jade with you,",
			"and place one near you or under your pillow while sleeping."
		],
	},
	{
		id: 4,
		name: "Blue",
		represents: "Throat",
		image: blue,
		description: [
			"Blue represents the Throat Chakra that is located centrally at the base of the neck. This Chakra is related to your expression and communication on all levels of your being.",
			"If you choose the blue Chakra Colour Card you are currently concerned with discovering your truth and inspiration on all levels, and you need to be receptive. You require more openness.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need be open to the messages and symbols you can receive from your dreams, visions and awareness.",
			"Choosing the blue Chakra Colour Card indicates that your healing may stir in you past experiences held in your unconscious and subconscious mind and any fears and traumas that need to be felt and expressed appropriately."
		],
		healing: [
			"Absorb the colour blue as much as you can",
			"by looking at the colour,",
			"by eating and drinking blue foods,",
			"by wearing blue clothes,",
			"by sleeping on blue sheets",
			"and so forth.",
			"Dance and Sing,",
			"each day",
			"in expression, honour and celebration",
			"to the sound of You"
		],
	},
	{
		id: 5,
		name: "Indigo",
		represents: "Third Eye",
		image: indigo,

		description: [
			"Indigo represents the Third Eye Chakra that is located above and between the eyebrows. This Chakra is related to your perception on all levels of your being.",
			"If you choose the indigo Chakra Colour Card you are currently concerned with your intuitive and psychic awareness. This card shows that you need to use your wisdom. You require more insight.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to widen your vision.",
			"Choosing the indigo Chakra Colour Card indicates that your healing may stir in you past experiences to do with previous lives and give you deeper insight into your Karma."
		],
		healing: [
			"Absorb the colour indigo as much as you can",
			"by looking at the colour,",
			"by eating and drinking indigo foods,",
			"by wearing indigo clothes,",
			"by sleeping on indigo sheets",
			"and so forth.",
			"Spend 10 minutes to 30 minutes a day",
			"being quiet, open and meditative."
		],
	},
	{
		id: 6,
		name: "Violet",
		represents: "Crown",
		image: violet,

		description: [
			"Violet represents the Crown Chakra that is located at the top/crown of the head. This chakra is related to your Spirituality.",
			"If you choose the violet Chakra Colour Card you are currently concerned with your relationship to the Divine, to God. This card shows that you need to go beyond your ego-self. You require more selflessness.",
			"In order for you to integrate the Dualities and Wholeness energies you have chosen you need to attune yourself to a Higher Power and pray.",
			"Choosing the violet Chakra Colour Card indicates that your healing may stir in you past experiences and feelings to do with religion, Gurus and the power of good and evil."
		],
		healing: [
			"Absorb the colour violet as much as you can,",
			"by looking at the colour,",
			"by eating and drinking violet foods,",
			"by wearing violet clothes,",
			"by sleeping on violet sheets,",
			"And so forth.",
			"be open to mysticism in your life.",
			"Create an altar/ritual table",
			"and connect for some time",
			"to your sacred space each day",
			"in prayer and reverence."
		],
	}
]
