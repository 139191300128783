import React from 'react';
import "./PositivityDescription.scss";
// <p className="pos__title"></p>
// <p className="pos__description"></p>

const Sound = () => {
	return (
		<>
			<p className="pos__description">For at least the next 21 days chant 21 times in the morning and throughout the day: </p>
			<p className="pos__title">OM </p>
			<p className="pos__description">Pronounced as:</p>
			<p className="pos__title">Ohm </p>
			<p className="pos__description">Om is the primordial sound of the Cosmos. Everything that has manifested has come from this sound.
				Chanting Om connects you to the Essence of your Being.</p>
		</>


	)
}
const Compassion = () => {
	return (
		<>
			<p className="pos__description">For at least the next 21 days chant 108 times:</p>
			<p className="pos__title">Om Mani Padme Hum</p>
			<p className="pos__description">Pronounced as:</p>
			<p className="pos__description">Ohm Mah Nee Pey May Hum Hrih</p>
			<p className="pos__description">Meaning essentially:</p>
			<p className="pos__title">May the Jewel of the Lotus (of Compassion) Descend into Your Heart.</p>

			<p className="pos__description">Chanting this six-syllable mantra, purifies the six realms of suffering of the: Gods’ Realm, Warring Gods’
				Realm,
				Human Realm, Animal Realm, Hungry Ghost Realm and the Hell Realm.</p>
		</>
	)
}
const Meditation = () => {
	return (
		<>
			<p className="pos__title">So Ham Meditation</p>
			<p className="pos__description">For at least the next 21 days do the So Ham Meditation, as often as you can.</p>
			<p className="pos__description">Relax your body and mind and breathing.Then with concentration on the breath,
				breathe in “So.” As you breathe in “So,” be aware of breathing God into your entire being. Imagine God, if you can, as pure white
				luminous Light entering your every cell.As you breathe out “Ham” be aware of breathing out “you” as Divine pure Light.</p>
			<p className="pos__title">
				<em>“So Ham,” (God and I),</em> <br/>
				<em>“So Ham,” (God and I),</em> <br/>
				<em>“So Ham,” </em><br/>
				<em>God and I are One. </em><br/>
			</p>
		</>
	)
}
const Affirmation = () => {
	return (
		<>
			<p className="pos__description">For at least the next 21 days, using all the names you have been known by, affirm:</p>
			<p className="pos__description">
				<em> I (all the names) affirm that I am completely held,</em> <br/>
				<em>loved and protected by God ,</em> <br/>
				<em>in all aspects of my life and being.</em> <br/>
			</p>
		</>
	)
}
const ChantingForFreedomFromFearAndBondage = () => {
	return (
		<>
			<p className="pos__description">For at least the next 21 days chant 108 times a day:</p>
			<p className="pos__title">Om Namah Shivayah</p>
			<p className="pos__description"><em>Pronounced as: </em></p>
			<p className="pos__title">Ohm Nam Ah Shee Vigh Yah</p>
			<p className="pos__description"><em>Meaning essentially:</em></p>
			<p className="pos__title">Chant the Name of Shiva.</p>
		</>
	)
}
const Silence = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days, as often as you remember,<br/>
				connect with the deep silence of your heart and being.<br/>
				Do the three kinds of Silence the Ancients practised:<br/>
				Silence of the tongue—<br/>
				Silence of the mind—<br/>
				Supreme Silence<br/>
				(control of speech and control of the mind).<br/>
				Allow your mind to be silent by not following your thoughts.<br/>
				Speak only when necessary and as Sai Baba says:<br/>
			</p>
			<p className="pos__description">
				<em>…Practise low speech<br/>
					and minimum sound.<br/>
					Talk low,<br/>
					talk little,<br/>
					talk in whispers,<br/>
					sweet and true.<br/> </em></p>
		</>
	)
}
const Movement = () => {
	return (
		<>
			<p className="pos__description">For the next 21 days dance to the sound of you.</p>
			<p className="pos__description">
				Each day, in silence or to music that appeals to your heart,<br/>
				connect with your being<br/>
				and move to the sound, to the pulse,<br/>
				to the flow of your being’s desire to respond.<br/>
			</p>
		</>
	)
}
const Expression = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days, for at least 30 minutes a day,<br/>
				express your feelings,<br/>
				your pain, your happiness, your sadness,<br/>
				whatever emotions and feelings arise<br/>
				in poetry, letters, paintings, drawings,<br/>
				or any creative way you feel drawn to.<br/>

			</p>
			<p className="pos__description">
				At the end of the 21 days, honour your release<br/>
				and burn what you have made in<br/>
				<strong> A Letting Go Ceremony,</strong><br/>
				offering up all that you have been able to make conscious and releasing it<br/>
				to Divinity, to the Light, to your idea of God,<br/>
				in gratitude and devotion.<br/>
			</p>
		</>
	)
}
const Mandala = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days create, little by little a circular Mandala, through drawing,<br/>
				collaging, sculpting, or in any way you feel drawn to.<br/>
				Inside the circle place symbols, words, poems, natural objects, people,<br/>
				and all the gifts of the world <br/>
				that you can and feel you wish to <br/>
				welcome and honour into your life as active healers.<br/>
				Make a strong boundary to honour your ability<br/>
				to protect That which is healing and goodness for you.<br/>
			</p>
		</>
	)
}
const GivingAndReceiving = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days <br/>
				be conscious of sharing yourself with others in a positive way. <br/>
				At the same time, be open to receiving from others. <br/>
				Do this mindfully and with as much conscious awareness as possible. <br/>
				Know that in everything you do you are not the doer. <br/>
				Let go the idea of receiving any merit or gratitude. <br/>
				Also, let go the idea that you are not good enough to receive. <br/>
				Just do and be. <br/>
			</p>
		</>
	)
}
const Gratitude = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days, <br/>
				near the end of your day, <br/>
				spend at least 30 minutes <br/>
				listing all the things that you <br/>
				feel grateful for that have happened during the day. <br/>
				If memories also come from the past and which you feel gratitude for, <br/>
				remember and honour these also in your list. <br/>
			</p>
		</>
	)
}
const KarmaOfKeepingGoodCompany = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days be aware of the company you keep <br/>
				and whether or not it is good for you and for your soul. <br/>
				Is it with people who are like-minded and on the same path as you? <br/>
				Seek out those who are uplifting, positive, generous-hearted <br/>
				and those you feel comfortable with. <br/>
				Seek the company of God. <br/>

			</p>
		</>
	)
}
const Surrender = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days, when you awaken, <br/>
				consciously surrender all your actions, happenings, conclusions, <br/>
				happiness and sadness of your coming day to God. <br/>
				Remind yourself throughout the day, that God is the doer, <br/>
				and surrender yourself as an instrument of God’s Will. <br/>
			</p>
		</>
	)
}
const Smile = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days, at every opportunity you have— <br/>
				Smile, from your heart and not merely from your face. <br/>
				When you can, smile to other people, smile to yourself, <br/>
				to everything, animate and inanimate. <br/>
			</p>
		</>
	)
}
const Prayer = () => {
	return (
		<>
			<p className="pos__description">

				For the next 21 days pray with all your heart <br/>
				for the benefit and happiness of all beings <br/>
				including yourself. <br/>
				You can, if you wish say the following Tibetan Buddhist Prayer: <br/>
			</p>
			<p className="pos__description">
				<em> By the power and the truth of this practice, <br/>
					may all beings have happiness <br/>
					and the causes of happiness. <br/>
					May all be free from sorrow and the causes of sorrow. <br/>
					May all never be separated from the sacred happiness which is sorrowless. <br/>
					And may all live in equanimity, <br/>
					without too much attachment and too much aversion, <br/>
					and live believing in the equality of all that lives. <br/> </em>
			</p>
		</>
	)
}
const LettingGoOfAddiction = () => {
	return (
		<>
			<p className="pos__description">
				Choose an addiction you have, and for the next 21 days <br/>
				consciously try not to follow your usual pattern <br/>
				of giving into the desire. <br/>
				Your addiction may be emotional, sensational, physical, mental or spiritual. <br/>
			</p>

		</>
	)
}
const SelfWorth = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days affirm to yourself <br/>
				that you are good enough, <br/>
				beautiful enough, <br/>
				and worth enough, <br/>
				on every level of your being, <br/>
				to have any miracle you wish for. <br/>
			</p>

		</>
	)
}
const AcceptingYourDarkness = () => {
	return (
		<>
			<p className="pos__description">
				For the next 21 days <br/>
				make a conscious effort <br/>
				to become aware <br/>
				to accept your darkness <br/>
				as well as your light. <br/>
				Accept without judgement, <br/>
				your mistakes, blunders, <br/>
				wrong words, deeds and actions <br/>
				and the dark times <br/>
				you have found yourself in. <br/>
				Accept that all of these <br/>
				are as much a part <br/>
				of your necessary growing <br/>
				as their opposites. <br/>
				Finally, Surrender what you <br/>
				find to God. <br/>
			</p>
		</>
	)
}


const PositivityDescription = ({id, ...props}) => {
	return <Content id={id}/>
}


const Content = ({id}) => {
	// eslint-disable-next-line default-case
	switch (id) {
		case 1:
			return <Sound/>
		case 2:
			return <Compassion/>
		case 3:
			return <Meditation/>
		case 4:
			return <Affirmation/>;
		case 5:
			return <ChantingForFreedomFromFearAndBondage/>;
		case 6:
			return <Silence/>;
		case 7:
			return <Movement/>;
		case 8:
			return <Expression/>;
		case 9:
			return <Mandala/>;
		case 10:
			return <GivingAndReceiving/>;
		case 11:
			return <Gratitude/>;
		case 12:
			return <KarmaOfKeepingGoodCompany/>;
		case 13:
			return <Surrender/>;
		case 14:
			return <Smile/>;
		case 15:
			return <Prayer/>;
		case 16:
			return <LettingGoOfAddiction/>;
		case 17:
			return <SelfWorth/>;
		case 18:
			return <AcceptingYourDarkness/>;
	}
}


export default PositivityDescription;

