import React from 'react';
import {animated, useSprings} from "react-spring";
import cardBack from '../../../Assets/cardBack.png';
import './CardDeck.scss';


const CardDeck = ({width, height, number, radius, shadow, offset, disableAnimation, ...props}) => {


	const cardDeckAnimation = useSprings(number, [...Array(number)].map((element, index) => {
			return {
				from: {
					transform: `translate3d(${index ? -0.9 : 0}em,0,0) scale(1) rotateX(0deg)`
				},
				to: {
					transform: `translate3d(0em,0,0) scale(1) rotateX(0deg)`
				},
				reset: true,
				delay: index ? index * 100 : 2000,
				config: {duration: 1500}
			}
		})
	);


	return (
		<div className="card-deck__container"
			 style={{width: width, height: height, gridTemplateColumns: `repeat(${15 + number}, calc(0.1em * 1fr))`}}>
			{
				cardDeckAnimation.map((animation, index) => {
					return <animated.img className="card-deck__card"
										 key={index}
										 src={cardBack}

										 style={
											 !disableAnimation ? {
												 ...animation,
												 gridColumn: `${index + 1}/${(offset ? offset : 15) + index}`,
												 // backgroundImage: `url(${cardBack})`,
												 borderRadius: radius ? radius : "1em",
												 boxShadow: shadow ? shadow : "5px 3px 6px 0.1em rgba(0, 0, 0, .3)",
												 zIndex: 5 - index,
											 } : {
												 gridColumn: `${index + 1}/${(offset ? offset : 15) + index}`,
												 // backgroundImage: `url(${cardBack})`,
												 borderRadius: radius ? radius : "1em",
												 boxShadow: shadow ? shadow : "5px 3px 6px 0.1em rgba(0, 0, 0, .3)",
												 zIndex: 5 - index,
											 }
										 }/>
				})
			}
		</div>
	);
}

export default CardDeck;
