import React, {useEffect, useRef, useState} from 'react';
import "./ChakraColor.scss";
import {animated, useSpring, useTransition} from "react-spring";
import chakraColor from "../../../Helpers/ChakraColorCard"
import getShuffledArray from "../../../Helpers/RandomizeArray";
import {useStoreActions, useStoreState} from "easy-peasy";
import Shuffle from "../../Animation/Shuffle/Shuffle";
import cardBack from '../../../Assets/cardBack.png';
import PlayHeader from "../PlayHeader/PlayHeader";
import CardDeck from "../../UI/CardDeck/CardDeck";
import parse from 'html-react-parser';

let shuffledElements = [...getShuffledArray(chakraColor)];

shuffledElements = [...getShuffledArray(shuffledElements)];

const ChakraColor = (props) => {

	const {play: {chakraColorShuffleStart, chakraColorSelectCompleted, hidePlayFooter, chakraAnimationOver}} = useStoreState((state => state))
	const {play: {updateChakraColorCardSelected, updateHidePlayFooter, updateChakraProcessOver}} = useStoreActions((state => state))

	const [shuffleStart, setShuffleStart] = useState(false);
	const [shuffleComplete, setShuffleComplete] = useState(false);
	const [shuffleOver, setShuffleOver] = useState(false);

	const transitLeft = useTransition(chakraColorShuffleStart, null, {
		from: {
			transform: "translate3d(0px, -50px, 0px)",
			opacity: 0,
		},
		enter: {

			transform: "translate3d(0px, 0px, 0px)",
			opacity: 1,
		},
		leave: {
			transform: "translate3d(0px, 0px, 0px)",
			opacity: 0,
		},
		config: {duration: 600},
	});

	useEffect(() => {
		const timeout = setTimeout(() => {
			if (chakraColorShuffleStart) {
				setShuffleStart(true);
			}
		}, 2000);

		return () => {
			clearTimeout(timeout);
		}
	}, [setShuffleStart, updateHidePlayFooter, chakraColorShuffleStart])


	useEffect(() => {
		if (shuffleComplete) {
			updateHidePlayFooter(false);
		}
	}, [shuffleComplete, updateHidePlayFooter])

	useEffect(() => {
		if (chakraColorSelectCompleted) {
			const timeout = setTimeout(() => {
				setShuffleOver(true)
			}, 2000)
			return () => {
				clearTimeout(timeout);
			}
		}
	}, [chakraColorSelectCompleted])


	useEffect(() => {
		if (chakraAnimationOver) {
			const timer = setTimeout(() => {
				updateChakraProcessOver(true)
			}, 1100)
			return () => {
				clearTimeout(timer);
			}
		}
	}, [chakraAnimationOver])

	return (
		<>
			<PlayHeader/>
			{
				!shuffleStart ?

					transitLeft.map(({item, props: animation, key}) => {
						return !item && <div className="chakra" key={key}>
							<animated.div className="chakra__details" style={animation}>
								<CardDeck width={"13rem"} height={"18rem"} radius={"1.3em"} offset={20} number={5} disableAnimation={false}/>
							</animated.div>
							<animated.div className="chakra__description chakra__overflow" style={animation}>
								<p className="main__title">Chakra Colour Cards</p>
								<p className="main__description">Think of each Chakra as a potent doorway to your
									current emotions and feelings. Each Chakra integrates your chosen Dualities and Wholeness Card energies
									in its own particular way. Used in conjunction with the Element card it can highlight what level
									you need to be aware of on a body, mind and heart level.</p>
								<p className="main__description">For example, Choosing the Root Chakra card is very
									different to choosing the Crown Chakra card. If your question is about feeling stuck and you
									choose the Root Chakra card, this will indicate the need to connect with your physical body and its
									related spiritual energies… to look at underlying fears or safety, especially physically. Instead,
									if you are feeling stuck and you choose the Crown Chakra card, it is more likely you are
									experiencing being spiritually stuck and the way through will be increasing understanding and
									awareness. Now, if the Fire Element is chosen, this will have a different effect than the Water Element
									with either of these Chakra Cards. Fire is passionate and quick and so therefore the insights are
									spontaneous. Water is emotional and releasing and requires more feeling rather than
									thinking. </p>
								<p className="main__description" style={{margin: 0}}>Reflect on your question as calmly
									and clearly as possible with an attitude of openness. Be aware that all the possible potentials
									of your answer is hidden to you and will now hopefully be revealed.</p>
							</animated.div>
						</div>
					})
					:
					(shuffleStart && !shuffleOver) ?
						<Shuffle items={shuffledElements} cardLimit={1} shuffleCount={2}
								 title={"Pick a Chakra Color Card Card"}
								 subtitle={"by clicking on the card"}
								 alignment={"380px"}
								 setShuffleComplete={setShuffleComplete}
								 pushCard={updateChakraColorCardSelected}
								 animationRewind={chakraColorSelectCompleted}/>
						: shuffleOver ?
						<ChakraColorFeel updateHideFooter={updateHidePlayFooter} hidePlayFooter={hidePlayFooter}/> : ""
			}
		</>
	);
}


const ChakraColorFeel = React.memo(({updateHideFooter, hidePlayFooter, ...props}) => {

	const {play: {chakraColorCardSelected, elementCardSelectedIndex, chakraAnimationOver}} = useStoreState((state => state))

	const fadeIn = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: chakraAnimationOver ? 0 : 1
		},
		config: {duration: 2000},
	});

	const rotate = useTransition(true, null, {
		from: {
			transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
			opacity: 0,
		},
		enter: [
			{
				transform: "translate3d(0px, 0px, 0px) rotateY(0deg)",
				opacity: 1,
			},
			{
				transform: "translate3d(0px, 0px, 0) rotateY(-180deg)",
				opacity: 1,
			},
		],
		trail: 1000,
		leave: {
			transform: "translate3d(0px, 0px, 0) rotateY(-180deg)",
			opacity: 1,
		},
		config: {duration: 2000},
		delay: 2000,
	});

	useEffect(() => {
		const timer = setTimeout(() => {
			updateHideFooter(false);
			return () => {
				clearTimeout(timer)
			}
		}, 4000)
	})

	return (
		<div className="chakra">
			<div className="chakra__element-details">
				<animated.div className="chakra__element-card"
							  style={{...fadeIn, backgroundImage: `url(${elementCardSelectedIndex[0].image})`}}>

					{rotate.map(({item, props: animation, key}) =>
						item && <animated.div className={`chakra-flip__container`} style={animation} key={key}>
							<div className="chakra-flip__card"
								 style={{
									 backgroundImage: `url(${cardBack})`,
								 }}/>
							<div className="chakra-flip__card"
								 style={{
									 backgroundImage: `url(${chakraColorCardSelected[0].image})`,
									 transform: `rotateY(180deg)`
								 }}/>
						</animated.div>
					)}
				</animated.div>
			</div>
			<ChakraDescription/>
		</div>
	)
})

const ChakraDescription = ({...props}) => {


	const descRef = useRef();
	const presRef = useRef();
	const [coords, setCoords] = useState({});

	//


	const {play: {updateChakraFeel, updateShowChakraImage}} = useStoreActions((actions => actions));

	const {play: {chakraColorCardSelected, chakraFeel, chakraFeelAdded, chakraAnimationOver}} = useStoreState((state => state))

	useEffect(() => {
		if (presRef.current && descRef.current) {
			const {x: prescX, y: prescY} = presRef.current.getBoundingClientRect();
			const {x: descX} = descRef.current.getBoundingClientRect();
			setCoords({
				...{
					prescX, prescY, descX
				}
			})
		}
	}, [chakraFeelAdded]);

	const [nav, setNav] = useState(false);

	const fadeInDescription = useTransition(chakraFeelAdded, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	});

	const fadeIn = useTransition(nav, null, {
		from: {
			opacity: 0
		},
		enter: {
			opacity: 1
		},
		leave: {
			opacity: 0
		},
		trail: 1000,
		order: ['leave', 'enter', 'update'],
		config: {duration: 800},
	})

	const fadeOut = useSpring({
		from: {
			opacity: 1
		},
		to: {
			opacity: chakraAnimationOver ? 0 : 1
		},
		config: {duration: 1000},
	})


	const fadeInRoot = useSpring({
		from: {
			opacity: 0
		},
		to: {
			opacity: 1
		},
		delay: 4000,
		config: {duration: 1000},
	})

	const transitWidth = useSpring({
		from: {
			width: "50%",
			opacity: 0,
		},
		to: [{
			width: "100%",
			opacity: 1,
		},
		],
		delay: 5000,
		config: {duration: 1000},
	});


	return (
		<animated.div className="chakra-element__description" style={fadeInRoot}>
			{

				fadeInDescription.map(({item, props: animation, key}) =>
					!item ?
						<animated.div className="" key={key} style={{gridColumn: "1/2", gridRow: "1/2", ...animation}}>
							<animated.div className="" style={{...animation}}>
								<p className="chakra__title">{`You picked the ${chakraColorCardSelected[0].name} Chakra!`}</p>
								<p className="chakra__description" style={{marginTop: "1em"}}>What do you see and feel? Feel what this Chakra and its
									energy
									means to you.</p>

								<div className="chakra__btn-container w-100">
									<button className="chakra__btn w-20" onClick={() => {
										updateShowChakraImage(true);
									}}>click here
									</button>
									<p className="chakra__sub-text  w-100">Click on the button to see the colour-coded diagram of
										the Chakras.</p>
								</div>

								<p className="chakra__description " style={{marginBottom: "1em"}}>Place your left hand palm on the centre of the Chakra and place
									your right hand palm on top of the back of your left hand. It is helpful to note down your thoughts
									and feelings.</p>
							</animated.div>
							<div className="chakra__input-container">

								<animated.textarea name="question"
												   style={transitWidth}
												   className="chakra__input"
												   placeholder="Start typing..."
												   value={chakraFeel}
												   maxLength={300}
												   onChange={(event) => {
													   updateChakraFeel(event.target.value);
												   }}
								/>
								<animated.p className="question__input-counter "
											style={{...transitWidth, width: "auto"}}>{chakraFeel.length}/300
								</animated.p>
							</div>
						</animated.div>
						:

						<animated.div style={{...animation, gridColumn: "1/2", gridRow: "1/2"}} key={key}>
							<animated.div style={fadeOut}>
								<p className="chakra__title w-75">{`
									The ${chakraColorCardSelected[0].name} Chakra Card - Represents The ${chakraColorCardSelected[0].represents} Chakra`}</p>
								<div className="chakra__nav-container" style={{margin: "2em 0"}}>
									<p className="chakra__sub-text cursor-pointer"
									   ref={descRef}
									   onClick={() => {
										   setNav(false)
									   }}>Description</p>
									<p className="chakra__sub-text cursor-pointer"
									   ref={presRef}

									   onClick={() => {
										   setNav(true)
									   }}>Prescription for Healing</p>


									{coords && <div className="chakra__nav-slide" style={{
										top: `${coords.prescY + 10}px`,
										left: !nav ? `${coords.descX}px` : `${coords.prescX}px`,

									}}/>}
								</div>
								<div className="chakra__nav">
									{
										fadeIn.map(({item, props: animation, key}) =>
											!item ? <animated.div className="chakra__nav-content" key={key} style={animation}>
													{chakraColorCardSelected[0].description.map((element, index) => <p
														className="chakra__description" key={index}>{parse(element)}</p>)}
												</animated.div>
												: <animated.div className="chakra__nav-content" key={key} style={animation}>
													{chakraColorCardSelected[0].healing.map((element, index) => <p
														className="chakra__prescription" key={index}>{parse(element)}</p>)}
												</animated.div>
										)
									}
								</div>
							</animated.div>
						</animated.div>
				)
			}
		</animated.div>
	)
}

export default React.memo(ChakraColor);
